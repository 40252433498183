/**
 * Gases screen
 *
 * Created: 2022/12/31
 * __author__: AnhPV17
 * __copyright__: Copyright KITZ Corporation. 2022 All Rights Reserved
 * __version__: 1.0
 */
import { useState, useEffect, useRef } from 'react';
import { Form, Row, Col } from 'antd';
import '../../styles/pages/FlowRate.scss';
import { useTranslation } from 'react-i18next';
import { getNegativeNumberRule, getDefaultRule } from '../../constants/rules';
import PageContainer from '../../layouts/PageContainer';
import apiClient from '../../services/http-client';
import { TEMPERATURE_UNITS, PRESSURE_CLASSES, PRESSURE_UNITS, GAS_FLOW_UNIT_LIST } from './constants';
import rounding, { EMPTY_STRING } from '../../helpers/utils';
import PageHeading from '../../components/Heading/PageHeading';
import { useCustomForm } from '../../hooks/useCustomForm';
import { CustomInput } from '../../components/CustomInput/CustomInput';
import { BaseForm } from '../../components/BaseForm';
import { BaseFormInputType, IBaseFormOptions } from '../../components/BaseForm/Interface';
import { STATUS_CODE } from '../../constants/statusCode';
import {
  FlowRateGasUnitsID,
  PressureClassesID,
  PressureUnitsID,
  TemperatureUnitsID,
  VelocityUnitsID,
} from '../../constants/unitsID';

interface IGasesDataPost {
  D: number;
  V: number;
  V_unit_in: string;
  P1?: number;
  T1?: number;
  P_unit_in?: string;
  T_unit_in?: string;
  Gg?: number;
  defaultP2?: boolean;
}

interface IGasesUnit {
  pUnit: string;
  pClass: string;
  tUnit: string;
  vUnit: string;
}

// eslint-disable-next-line max-lines-per-function
export const Gases = (): JSX.Element => {
  const { t } = useTranslation();

  const [inputForm] = useCustomForm(Form.useForm());
  const [errorMessage, setErrorMessage] = useState<string>();
  const [msResult, setMsResult] = useState<string>();
  const [ftResult, setFtResult] = useState<string>();
  const currentErrorMsg = useRef<string>(EMPTY_STRING);
  const enableTempAndPress = [
    GAS_FLOW_UNIT_LIST[4].value,
    GAS_FLOW_UNIT_LIST[5].value,
    GAS_FLOW_UNIT_LIST[6].value,
    GAS_FLOW_UNIT_LIST[7].value,
  ];
  const isEnableTempAndPress = useRef<string>(FlowRateGasUnitsID.Nm3h);
  const isEnableGg = useRef<string>(FlowRateGasUnitsID.Nm3h);
  const inputUnit = useRef<IGasesUnit>({
    pUnit: PressureUnitsID.Mpa,
    pClass: PressureClassesID.Gauge,
    tUnit: TemperatureUnitsID.Celsius,
    vUnit: FlowRateGasUnitsID.Nm3h,
  });
  const [setlectedVUnit, setSelectedVUnit] = useState<string>(FlowRateGasUnitsID.Nm3h);
  /**
   * handle form values change
   *
   * Created: 2022/15/12
   *  @author AnhPV17
   */
  const onFormValuesChange = (): void => {
    const validateField = ['dValue', 'vValue'];
    if (!disablePressAndTempInput(isEnableTempAndPress.current)) {
      validateField.push('valueP1');
      validateField.push('flowT1');
    }
    !disableGgInput(isEnableGg.current) && validateField.push('GgAirGravity');

    inputForm
      .validateFields(validateField)
      .then(() => {
        setErrorMessage('');
        currentErrorMsg.current = EMPTY_STRING;
        if (inputForm.getFormEmtyOrError(validateField).isOneFieldEmpty) {
          clearResult();
          return;
        }
        getResult();
      })
      .catch(() => {
        setErrorMessage(inputForm.customError[0]?.msg);
        currentErrorMsg.current = inputForm.customError[0]?.msg;
      });
  };

  const disablePressAndTempInput = (value: string): boolean => {
    return enableTempAndPress.includes(value);
  };

  const clearResult = (): void => {
    setMsResult(EMPTY_STRING);
    setFtResult(EMPTY_STRING);
  };

  const disableGgInput = (value: string): boolean => {
    return ![GAS_FLOW_UNIT_LIST[10].value, GAS_FLOW_UNIT_LIST[11].value, GAS_FLOW_UNIT_LIST[12].value].includes(value);
  };

  useEffect(() => {
    clearResult();
    if (disablePressAndTempInput(setlectedVUnit)) {
      inputForm.setFieldValue('valueP1', EMPTY_STRING);
      inputForm.setFieldValue('flowT1', EMPTY_STRING);
    }

    if (disableGgInput(setlectedVUnit)) {
      inputForm.setFieldValue('GgAirGravity', EMPTY_STRING);
    }
  }, [setlectedVUnit]);

  const setDataPost = (): IGasesDataPost => {
    let dataPost: IGasesDataPost = {
      D: Number(inputForm.getFieldValue('dValue')),
      V: Number(inputForm.getFieldValue('vValue')),
      V_unit_in: inputUnit.current.vUnit,
      defaultP2: false,
    };
    if (
      !disablePressAndTempInput(isEnableTempAndPress.current) &&
      inputForm.getFieldValue('valueP1') !== EMPTY_STRING &&
      inputForm.getFieldValue('flowT1') !== EMPTY_STRING
    ) {
      dataPost = {
        ...dataPost,
        P1: Number(inputForm.getFieldValue('valueP1')),
        T1: Number(inputForm.getFieldValue('flowT1')),
        P_unit_in: inputUnit.current.pClass + inputUnit.current.pUnit,
        T_unit_in: inputUnit.current.tUnit,
      };
    }
    if (!disableGgInput(isEnableGg.current)) {
      dataPost = { ...dataPost, Gg: Number(inputForm.getFieldValue('GgAirGravity')) };
    }
    return dataPost;
  };
  /**
   * Send input to BE and call API
   *
   * Created: 2022/15/12
   *  @author AnhPV17
   */
  // eslint-disable-next-line max-lines-per-function
  async function getResult(): Promise<void> {
    try {
      const dataPost: IGasesDataPost = setDataPost();
      const response = await apiClient.post('/calcVelFromFlowRateGas', dataPost);
      const msgCode = response.data.code;
      if (msgCode === STATUS_CODE.SUCCESS) {
        currentErrorMsg.current = EMPTY_STRING;
        const result = response.data.data;
        setMsResult(rounding(Number(result[VelocityUnitsID.ms])).toString());
        setFtResult(rounding(Number(result[VelocityUnitsID.fts])).toString());
      } else {
        if (currentErrorMsg.current) {
          setErrorMessage(currentErrorMsg.current);
          return;
        }
        const errorCode = 'error code';
        const pressureMSg = response.data.errors.validate['P1']?.[errorCode];
        const temperatureMsg = response.data.errors.validate['T1']?.[errorCode];
        if (pressureMSg === 1511) {
          setErrorMessage(t('CM_0193', { fieldName: `${t('CM_0194')}` }) as string);
        } else if (temperatureMsg === 1512) {
          setErrorMessage(t(temperatureMsg) as string);
        } else {
          setErrorMessage(EMPTY_STRING);
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  }
  // useEffect(() => {
  //   if (
  //     [STEAM_FLOW_UNIT_LIST[10].value, STEAM_FLOW_UNIT_LIST[11].value, STEAM_FLOW_UNIT_LIST[12].value].includes(
  //       vuValue,
  //     )
  //   ) {
  //     setDisablePonderosity(false);
  //   } else {
  //     setDisablePonderosity(true);
  //     setMsResult(msResult);
  //     setFtResult(ftResult);
  //     setErrorMessage('');
  //     setGGChange('');
  //   }
  // }, [vuValue]);

  const formOption: IBaseFormOptions = {
    form: inputForm,
    className: 'fl-rate-container fr-gases',
    onFormValuesChange: onFormValuesChange,
    fields: [
      {
        label: t('CM_0075'),
        className: 'form-item',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'dValue',
            rules: getNegativeNumberRule(t('CM_0191'), undefined, false, 1, true),
            placeholder: t('CM_0129'),
            unit: 'mm',
          },
        ],
      },
      {
        label: t('CM_0017'),
        className: 'form-item',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'vValue',
            rules: getNegativeNumberRule(t('CM_0160'), undefined, false, 5, true),
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            className: 'vvalue-selector',
            name: 'pressureClass',
            value: `${t(GAS_FLOW_UNIT_LIST[0].value)}`,
            options: GAS_FLOW_UNIT_LIST.map((item) => {
              return {
                name: item.name,
                value: item.value,
                label: t(item.name),
              };
            }),
            onChange: (value: string): void => {
              setSelectedVUnit(value);
              inputUnit.current.vUnit = value;
              isEnableTempAndPress.current = value;
              isEnableGg.current = value;
            },
            note: <>{t('CM_0053')}</>,
          },
        ],
      },
      {
        label: t('CM_0003'),
        className: 'form-item',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'valueP1',
            disabled: disablePressAndTempInput(setlectedVUnit),
            rules: getDefaultRule(t(`${t('CM_0194')}`), 4, true),
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            name: 'value1',
            value: PRESSURE_UNITS[0].label,
            options: PRESSURE_UNITS,
            onChange: (value: string): void => {
              inputUnit.current.pUnit = value;
            },
          },
          {
            type: BaseFormInputType.Select,
            name: 'value2',
            value: `${t(PRESSURE_CLASSES[0].name as string)}`,
            options: PRESSURE_CLASSES.map((item) => {
              return {
                name: item.name,
                value: item.value,
                label: t(item.unit),
              };
            }),
            onChange: (value: string): void => {
              inputUnit.current.pClass = value;
            },
          },
        ],
      },
      {
        label: t('CM_0018'),
        className: 'fluid-state t1Value',
        note: <>{t('CM_0180')}</>,
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'flowT1',
            disabled: disablePressAndTempInput(setlectedVUnit),
            rules: getDefaultRule(t('CM_0159'), 3, true),
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            name: 'select1',
            value: `${t(TEMPERATURE_UNITS[0].name as string)}`,
            options: TEMPERATURE_UNITS.map((item) => {
              return {
                name: item.name,
                value: item.value,
                label: t(item.label),
              };
            }),
            onChange: (value: string): void => {
              inputUnit.current.tUnit = value;
            },
          },
        ],
      },
      {
        label: t('CM_0019'),
        className: 'input-text fr-unit result-input',
        inputs: [
          {
            type: BaseFormInputType.Text,
            disabled: disableGgInput(setlectedVUnit),
            name: 'GgAirGravity',
            placeholder: disableGgInput(isEnableGg.current) ? '' : (t('CM_0129') as string),
            rules: getNegativeNumberRule(t('CM_0190'), undefined, false, 2, true),
            unit: `(${t('CM_0020')})`,
          },
        ],
      },
    ],
  };
  const inputContainer = (
    <>
      <div className="fl-rate-container gas">
        <PageHeading className="pipe-diameter-title" borderColor="jade">
          {t('CM_0076')}
        </PageHeading>
        <BaseForm options={formOption}></BaseForm>
        <Form colon={false}>
          <div className="input-container fr-liquid">
            <Form.Item className="result">
              <Row className="result-title">
                <Col>
                  <div className="title">{t('CM_0010')}</div>
                </Col>
                <Col>
                  <div className="error-message">{errorMessage}</div>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item className="result" label={<label className="title-result">{t('CM_0014')}</label>}>
              <Row className="result-row" gutter={[16, 16]}>
                <Col className="col-md">
                  <CustomInput
                    className="input-result"
                    disabled={true}
                    value={!errorMessage ? msResult?.toString() : `${t('CM_0178')}`}
                    isResultField={true}
                  />
                  <p className="unit">m/s</p>
                </Col>

                <Col className="col-md">
                  <CustomInput
                    className="input-result"
                    disabled={true}
                    value={!errorMessage ? ftResult?.toString() : `${t('CM_0178')}`}
                    isResultField={true}
                  />
                  <p className="unit">ft/s</p>
                </Col>
              </Row>
            </Form.Item>
            <p className="notice">{t('CM_0026')}</p>
          </div>
        </Form>
      </div>
    </>
  );
  const children = <>{inputContainer}</>;
  return (
    <>
      <PageContainer>{children}</PageContainer>
    </>
  );
};
