import { ChangeEventHandler, useState, useEffect, useRef } from 'react';
import { Input } from 'antd';
import { Rule } from 'antd/es/form';
import rounding, {
  plusString,
  getDecimalString,
  eString,
  minusString,
  displayResponseData,
  isNumeric,
  bigEString,
  isResponseResultOverLimit,
  roundingString,
  convertOverLimitResponseResult,
  isNumberLengthInvalid,
  DOT_STRING,
  formatExpoNumber,
} from '../../helpers/utils';

interface ICustomInput {
  name?: string;
  className?: string;
  autoComplete?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  bordered?: boolean;
  maxLength?: number;
  defaultValue?: string;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  isResultField?: boolean;
  nameItem?: string;
  rules?: Rule[] | undefined;
  isSpecialFormat?: boolean;
  limitMinValue?: number; // Minimum to display E
  limitMaxValue?: number;
  isIgnoreLimitConvert?: boolean;
  isRoundingString?: boolean;
  isScrollToChart?: boolean;
}

interface IInputValue {
  value: string;
}

// eslint-disable-next-line max-lines-per-function
export const CustomInput = (props: ICustomInput): JSX.Element => {
  const EMPTY_STRING = '';
  const maxMinusExponential = 19;
  const maxPlusExponential = 20;
  const [inputValue, setInputValue] = useState<IInputValue>({ value: '' });
  const isInit = useRef<boolean>(true);

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    isInit.current = false;
    const { value } = e.target;
    const data = { ...inputValue };
    data.value = value;
    setInputValue(data);
  };

  useEffect(() => {
    if (!props.isResultField && props.disabled) {
      setInputValue({ value: EMPTY_STRING });
    }
  }, [props.disabled]);

  const handleOnBlurInput = (): void => {
    const inputStr = inputValue.value.trim();
    // const absInput = Math.abs(Number(inputValue.value));
    // const isNegative = Number(inputValue.value) < 0;
    // if (isNumeric(inputStr) && props.limitMaxValue && Number(absInput) >= props.limitMaxValue) {
    //   const equation = isNegative ? minusString : EMPTY_STRING;
    //   setInputValue({
    //     value: equation + convertOverLimitResponseResult(absInput).replace(eString, bigEString),
    //   });
    //   return;
    // }
    const mobileMode = 440;
    if ((window.innerWidth < mobileMode || window.innerHeight < mobileMode) && props.isScrollToChart) {
      const element = document.getElementById('chart1') as HTMLElement;
      element.scrollIntoView();
    }

    setInputValue({
      value:
        isNumeric(inputStr) && !isNumberLengthInvalid(inputStr)
          ? Number(inputStr).toString().replace(eString, bigEString)
          : inputStr.startsWith(DOT_STRING) && isNumeric(inputStr)
          ? '0' + inputStr
          : inputStr,
    });
  };

  const handleOnFocus = (): void => {
    if (props.disabled) {
      setInputValue({ value: EMPTY_STRING });
      return;
    }

    // if (props.limitMaxValue) {
    //   setInputValue({ value: getDecimalString(inputValue.value) });
    //   return;
    // }
    const isExponentialDisplay = Number(inputValue.value).toString().includes(eString);

    const isOverMinusExponential =
      Number(inputValue.value).toString().includes(minusString) &&
      Number(inputValue.value.slice(inputValue.value.indexOf(minusString) + 1)) > maxMinusExponential;
    const isOverPlusExponential =
      Number(inputValue.value).toString().includes(plusString) &&
      Number(inputValue.value.slice(inputValue.value.indexOf(plusString) + 1)) > maxPlusExponential;
    if (!isExponentialDisplay || isOverMinusExponential || isOverPlusExponential) {
      return;
    }

    // Max e to display in decimal when click: plus: e+20, minus: e-19
    if (Number(inputValue.value).toString().includes(plusString)) {
      setInputValue({ value: getDecimalString(Number(inputValue.value).toFixed(maxPlusExponential)) });
    } else {
      setInputValue({ value: getDecimalString(Number(inputValue.value).toFixed(maxMinusExponential)) });
    }
  };

  const displayOverLimitResponse = (value?: string): string => {
    if (value && value.toUpperCase().includes(bigEString)) {
      return formatExpoNumber(value.toUpperCase());
    }
    return displayResponseData(Number(props.value)).toUpperCase();
  };

  // eslint-disable-next-line complexity, max-lines-per-function
  const displayResultField = (): string | undefined => {
    const maxRoundingNumber = 100;
    if (props.isIgnoreLimitConvert && !props.limitMaxValue) {
      return props.value;
    }

    if (props.limitMaxValue) {
      const inputValue = props.value?.replaceAll(',', '');
      if (Math.abs(Number(inputValue)) >= props.limitMaxValue) {
        return convertOverLimitResponseResult(Number(inputValue)).toUpperCase();
      }
    }
    if (
      props.limitMinValue &&
      Number(props.value) >= props.limitMinValue &&
      Number(props.value) <= maxRoundingNumber
    ) {
      if (props.isRoundingString) {
        return roundingString(Number(props.value));
      }
      return rounding(Number(props.value)).toString();
    }
    if (isNumeric(props.value)) {
      const propsValue = Number(props.value);
      return props.isSpecialFormat
        ? isResponseResultOverLimit(propsValue)
          ? displayOverLimitResponse(props.value)
          : props.value
        : displayResponseData(Number(props.value));
    }
    return props.value;
  };

  const renderTextboxValue = (): string | undefined => {
    if (props.defaultValue && isInit.current) {
      return props.defaultValue;
    }
    if (props.isResultField) {
      return displayResultField();
    }
    if (props.disabled && !props.isResultField) {
      return '';
    }
    return inputValue.value;
  };

  return (
    <Input
      inputMode="tel"
      name={props.name}
      className={`${props.className} ${props.isResultField ? 'result-field' : ''}`}
      autoComplete="off"
      placeholder={props.disabled ? '' : props.placeholder}
      value={renderTextboxValue()}
      disabled={props.disabled}
      bordered={props.bordered}
      maxLength={props.maxLength}
      onChange={(e): void => {
        props.onChange && props.onChange(e);
        handleOnChangeInput(e);
      }}
      onBlur={handleOnBlurInput}
      onFocus={handleOnFocus}
    />
  );
};
