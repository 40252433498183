import { Radio, RadioChangeEvent } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

interface ValueSwitchingProps {
  isExponential: boolean;
  setIsExponential: Dispatch<SetStateAction<boolean>>;
}

function ValueSwitching({ isExponential, setIsExponential }: ValueSwitchingProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="value-switching">
      <Radio.Group
        onChange={(e: RadioChangeEvent): void => {
          setIsExponential(e.target.value);
        }}
        value={isExponential}
      >
        <Radio value={false}>{t('CM_0141')}</Radio>
        <Radio value={true}>{t('CM_0140')}</Radio>
      </Radio.Group>
    </div>
  );
}

export default ValueSwitching;
