import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import './styles/core.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import i18n from './i18n';
import { appendGaTag } from './helpers/lang';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
/* append ga tag */
appendGaTag(i18n.language);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
