import { isNumberLengthInvalid, isNumeric } from './../helpers/utils';
import i18next from '../i18n';

export enum RulePriority {
  isBlank = 6,
  isNumeric = 5,
  isIntegerNumber = 4,
  maxDigits = 3,
  // Less than or equal to 0
  isNegativeNumber = 2,
  // P2 > P1
  isOverPressure = 1,
  isEqual = 0,
  isInRange = 0,
}

export enum errorType {
  isNaN = 'NOT_A_NUMBER',
  isLengthInvalid = 'LENGTH_INVALID',
  isZero = 'IS_SMALLER_OR_EQUAL_TO_0',
}

//index: input order
// eslint-disable-next-line max-lines-per-function
export function getDefaultRule(
  fieldName: string,
  index?: number,
  allowedBlank?: boolean,
): {
  validator: (_: unknown, value: string | undefined) => Promise<void>;
}[] {
  return [
    {
      validator: (_: unknown, value: string | undefined): Promise<void> => {
        if (allowedBlank && !value) {
          return Promise.resolve();
        }
        if (!isNumeric(value) || !value?.toString().trim()) {
          const error = {
            priority: RulePriority.isNumeric,
            msg: i18next.t('CM_0171', { fieldName: fieldName }),
            index: index,
            validateType: errorType.isNaN,
          };
          return Promise.reject(JSON.stringify(error));
        }
        if (isNumberLengthInvalid(value as string)) {
          const error = {
            priority: RulePriority.maxDigits,
            msg: i18next.t('CM_0173', { fieldName: fieldName }),
            index: index,
            validateType: errorType.isLengthInvalid,
          };
          return Promise.reject(JSON.stringify(error));
        }
        return Promise.resolve();
      },
    },
  ];
}

// eslint-disable-next-line max-lines-per-function
export const getNegativeNumberRule = (
  fieldName: string,
  msgCode?: string,
  isNotEqualToZero?: boolean,
  index?: number,
  allowedBlank?: boolean,
  messageBlank?: string,
): {
  validator: (_: unknown, value: string | undefined) => Promise<void>;
}[] => {
  return [
    {
      // eslint-disable-next-line complexity, max-lines-per-function
      validator: (_: unknown, value: string | undefined): Promise<void> => {
        if (allowedBlank && !value) {
          return Promise.resolve();
        }
        if (messageBlank && !value) {
          const error = {
            priority: RulePriority.isBlank,
            msg: i18next.t(messageBlank),
            index: index,
          };
          return Promise.reject(JSON.stringify(error));
        }
        if (!isNumeric(value) || !value?.toString().trim()) {
          const error = {
            priority: RulePriority.isNumeric,
            msg: i18next.t('CM_0171', { fieldName: fieldName }),
            index: index,
            validateType: errorType.isNaN,
          };
          return Promise.reject(JSON.stringify(error));
        }
        if (isNumberLengthInvalid(value as string)) {
          const error = {
            priority: RulePriority.maxDigits,
            msg: i18next.t('CM_0173', { fieldName: fieldName }),
            index: index,
            validateType: errorType.isLengthInvalid,
          };
          return Promise.reject(JSON.stringify(error));
        }
        if ((Number(value) <= 0 && !isNotEqualToZero) || (isNotEqualToZero && Number(value) < 0)) {
          const error = {
            priority: RulePriority.isNegativeNumber,
            msg: i18next.t(msgCode ? msgCode : 'CM_0172', { fieldName: fieldName }),
            index: index,
            validateType: errorType.isZero,
          };
          return Promise.reject(JSON.stringify(error));
        }
        return Promise.resolve();
      },
    },
  ];
};
export const limitMinToDisplayExpo = 0.0000001;
