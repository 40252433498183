/**
 * Liquid Cv Calculation
 *
 * Created: 2022/05/12
 * __author__: VuongLK
 * __copyright__: Copyright KITZ Corporation. 2022 All Rights Reserved
 * __version__: 1.1.0
 */

import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { FLOW_RATE_LIQUID_UNITS, GREAT_PRESSURE, P1, PRESSURE_CLASSES, PRESSURE_UNITS } from '../../constants/units';
import PageHeading from '../../components/Heading/PageHeading';
import { getDefaultRule, getNegativeNumberRule } from '../../constants/rules';
import { STATUS_CODE } from '../../constants/statusCode';
import ResultCV from '../../components/ResultCV/ResultCV';
import { useCustomForm } from '../../hooks/useCustomForm';
import { useRef, useState } from 'react';
import { displayResponseData } from '../../helpers/utils';
import { getSelectUnit } from '../../helpers/common';
import { getResultAPI } from '../../services/http-client';
import { API_PATHS } from '../../constants/apiPaths';
import PageContainer from '../../layouts/PageContainer';
import { BaseForm } from '../../components/BaseForm';
import { BaseFormInputType, IBaseFormOptions } from '../../components/BaseForm/Interface';
import './style.scss';
import { FlowRateLiquidUnitsID, PressureClassesID, PressureUnitsID, VelocityUnitsID } from '../../constants/unitsID';
import { SpecialCodes, ValidateCodes } from '../../constants/errorCode';

// eslint-disable-next-line max-lines-per-function
function LiquidCVCalculate(): JSX.Element {
  const { t } = useTranslation();
  const [inputForm] = useCustomForm(Form.useForm());
  const validateFields = ['q', 'p1', 'p2', 'gf'];

  const [deltaP, setDeltaP] = useState<string>('');
  const [pressureUnit, setPressureUnit] = useState('01');
  const [classesUnit, setClassesUnit] = useState('G');

  const [error, setError] = useState('');
  const [errorCV, setErrorCV] = useState('');
  const [errorOfDownstreamPipe, setErrorOfDownstreamPipe] = useState('');

  const downstreamPipe = useRef('');
  const [valueCV, setValueCV] = useState<string>('');
  const [pressureRatio, setPressureRatio] = useState('');
  const [resDownstreamPipe, setResDownstreamPipe] = useState({ ft_s: '', m_s: '' });

  // eslint-disable-next-line max-lines-per-function, complexity
  async function getResult(): Promise<void> {
    const dataPressRatio = ['p1', 'p2'];

    if (dataPressRatio.every((field) => inputForm.getFieldValue(field) && !inputForm.getFieldError(field)[0])) {
      const dataPostDiffPressure = {
        P1: Number(inputForm.getFieldValue('p1')),
        P2: Number(inputForm.getFieldValue('p2')),
      };
      const dataPost = {
        ...dataPostDiffPressure,
        P_unit_in: `${inputForm.getFieldValue('classesUnit')}${inputForm.getFieldValue('pressureUnit')}`,
      };
      const diffPressureData = await getResultAPI(dataPostDiffPressure, API_PATHS.CALC_DIFF_PRESS);
      diffPressureData?.data &&
        (diffPressureData.data.value || diffPressureData.data.value === 0) &&
        setDeltaP(displayResponseData(diffPressureData.data.value));

      const data = await getResultAPI(dataPost, API_PATHS.CALC_PRESS_RATIO);

      if (data && data?.code && data.code === STATUS_CODE.SUCCESS) {
        data?.data && setPressureRatio(data?.data?.value.toString());
      } else {
        setPressureRatio('');
      }
    } else {
      setDeltaP('');
      setPressureRatio('');
    }

    if (inputForm.getFieldValue('q') && !inputForm.getFieldError('q')[0] && downstreamPipe.current) {
      const dataPost = {
        D: Number(downstreamPipe.current),
        Q_unit_in: inputForm.getFieldValue('qUnit'),
        Q: Number(inputForm.getFieldValue('q')),
        Gf:
          inputForm.getFieldValue('gf') && !inputForm.getFieldError('gf')[0]
            ? Number(inputForm.getFieldValue('gf'))
            : undefined,
      };

      const data = await getResultAPI(dataPost, API_PATHS.CALC_VEL_FROM_FLOW_RATE_LIQ);

      if (data && data?.code && data.code === STATUS_CODE.SUCCESS) {
        data?.data &&
          setResDownstreamPipe({
            ft_s: data?.data[VelocityUnitsID.fts].toString(),
            m_s: data?.data[VelocityUnitsID.ms].toString(),
          });
        setErrorOfDownstreamPipe('');
      } else {
        setResDownstreamPipe({ ft_s: `${t('CM_0178')}`, m_s: `${t('CM_0178')}` });
      }
    } else {
      if (downstreamPipe.current) {
        setErrorOfDownstreamPipe('');
        setResDownstreamPipe({ ft_s: '', m_s: '' });
      }
    }

    const fieldsCvGasSimple = ['q', 'p1', 'p2', 'gf'];

    if (fieldsCvGasSimple.every((field) => inputForm.getFieldValue(field))) {
      if (fieldsCvGasSimple.every((field) => !inputForm.getFieldError(field)[0])) {
        const dataPost = {
          Q: Number(inputForm.getFieldValue('q')),
          Q_unit_in: inputForm.getFieldValue('qUnit'),
          P1: Number(inputForm.getFieldValue('p1')),
          P2: Number(inputForm.getFieldValue('p2')),
          P_unit_in: `${inputForm.getFieldValue('classesUnit')}${inputForm.getFieldValue('pressureUnit')}`,
          Gf: Number(inputForm.getFieldValue('gf')),
        };

        const data = await getResultAPI(dataPost, API_PATHS.CALC_CV_LIQ_SIMPLE);

        if (data && data?.code && data.code === STATUS_CODE.SUCCESS) {
          data.data && setValueCV(`${data.data.value}`);
          setError('');
        } else {
          if (data && data.customError?.code[0]) {
            const customError = data.customError;
            const code = data.customError.code[0];

            if (code === GREAT_PRESSURE) {
              const key = customError.field[0];
              key === P1
                ? setError(t('CM_0193', { fieldName: `${t('CM_0194')}` }).toString())
                : setError(t('CM_0193', { fieldName: `${t('CM_0195')}` }).toString());
              setValueCV(`${t('CM_0178')}`);

              return;
            }

            ValidateCodes.P1_LESS_OR_EQUAL_P2 === code && setError(`${t('1507')}`);

            if (SpecialCodes.CRITICAL_STATE === code) {
              setError(`${t('2002')}`);
              setValueCV(`${t('CM_0178')}`);
              setErrorCV(`${t('CM_0196')}`);

              return;
            }
          }

          setValueCV(t('CM_0178').toString());
          setErrorCV(t('CM_0178').toString());
        }
      } else {
        setValueCV(t('CM_0178').toString());
        setErrorCV(t('CM_0178').toString());
      }
    } else {
      setValueCV('');
      setErrorCV('');
    }
  }

  const onFormValuesChange = (): void => {
    getResult();
    inputForm
      .validateFields(validateFields)
      .then(() => {
        setError('');
      })
      .catch(() => {
        if (inputForm.customError[0]?.msg) {
          setError(inputForm.customError[0]?.msg);
        } else {
          setError('');
          setErrorOfDownstreamPipe('');
        }
      });
  };

  const baseFormOpt: IBaseFormOptions = {
    form: inputForm,
    onFormValuesChange,
    className: '',
    fields: [
      {
        label: t('CM_0002'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'q',
            rules: getNegativeNumberRule(t('CM_0160'), undefined, undefined, undefined, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            name: 'qUnit',
            options: FLOW_RATE_LIQUID_UNITS.map((item) => ({
              key: item.value,
              value: item.value,
              label: `${t(item.name) + item.unit}`,
            })),
            value: FlowRateLiquidUnitsID.m3h,
          },
        ],
      },
      {
        label: t('CM_0003'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'p1',
            rules: getDefaultRule(t(`${t('CM_0194')}`), undefined, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            value: PressureUnitsID.Mpa,
            name: 'pressureUnit',
            options: PRESSURE_UNITS.map((item) => ({ key: item.unit, value: item.value, label: item.unit })),
            onChange: (value: string): void => setPressureUnit(value),
          },
          {
            type: BaseFormInputType.Select,
            value: PressureClassesID.Gauge,
            name: 'classesUnit',
            options: PRESSURE_CLASSES.map((item) => ({ key: item.unit, value: item.value, label: t(item.name) })),
            onChange: (value: string): void => setClassesUnit(value),
          },
        ],
      },
      {
        label: t('CM_0004'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'p2',
            rules: getDefaultRule(t(`${t('CM_0195')}`), undefined, true),
            placeholder: t('CM_0129'),
            unit: getSelectUnit(pressureUnit, classesUnit) && getSelectUnit(pressureUnit, classesUnit)[0],
          },
        ],
      },
      {
        label: t('CM_0005'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'delta',
            unit: getSelectUnit(pressureUnit, classesUnit) && getSelectUnit(pressureUnit, classesUnit)[1],
            value: deltaP,
            isResultField: true,
            disabled: true,
            isSpecialFormat: true,
          },
        ],
      },
      {
        label: t('CM_0008'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'gf',
            rules: getNegativeNumberRule(t('CM_0190'), undefined, undefined, undefined, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
            unit: `(${t('CM_0009')})`,
          },
        ],
      },
    ],
  };

  return (
    <PageContainer>
      <>
        <div className="cv liquid">
          <PageHeading borderColor="san-mario">{t('CM_0001')}</PageHeading>

          <BaseForm options={baseFormOpt}></BaseForm>
        </div>

        <ResultCV
          errorCV={errorCV}
          error={error}
          valueCV={valueCV}
          pressureRatio={pressureRatio}
          resDownstreamPipe={resDownstreamPipe}
          targetOfFlow={'CM_0015'}
          errorOfDownstreamPipe={errorOfDownstreamPipe}
          setErrorOfDownstreamPipe={setErrorOfDownstreamPipe}
          getResult={getResult}
          downstreamPipe={downstreamPipe}
          setResDownstreamPipe={setResDownstreamPipe}
          setErrorCV={setErrorCV}
          CVtype={'liquid'}
        />
      </>
    </PageContainer>
  );
}

export default LiquidCVCalculate;
