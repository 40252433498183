/**
 * Unit ID definition
 *
 * Created: 2022/21/02
 * __author__: DienPD1
 * __copyright__:Copyright KITZ Inc. 2022 All Rights Reserved
 * __version__: 1.0
 */

export enum PressureUnitsID {
  Mpa = '01',
  kPa = '02',
  Pa = '03',
  bar = '04',
  kgfcm2 = '05',
  mmHgTorr = '06',
  mmH2OmmAq = '07',
  psilbfin2 = '08',
  inH2O = '09',
  ftH2O = '10',
}

export enum PressureClassesID {
  Gauge = 'G',
  Absolute = 'A',
}

export enum TemperatureUnitsID {
  Celsius = 'T01',
  Kevil = 'T02',
  Fahrenheit = 'T03',
  Rankine = 'T04',
}

export enum FlowRateLiquidUnitsID {
  m3h = 'Q01',
  m3min = 'Q02',
  Lh = 'Q03',
  Lmin = 'Q04',
  Usgalmin = 'Q05',
  kgh = 'Q06',
  th = 'Q07',
  lbh = 'Q08',
}

export enum FlowRateGasUnitsID {
  Nm3h = 'V01',
  Nm3min = 'V02',
  NLh = 'V03',
  NLmin = 'V04',
  m3h = 'V05',
  m3min = 'V06',
  Lh = 'V07',
  Lmin = 'V08',
  Sm3h = 'V09',
  SCFH = 'V10',
  kgh = 'V11',
  th = 'V12',
  lbh = 'V13',
}

export enum FlowRateSteamUnitsID {
  kgh = 'W01',
  th = 'W02',
  lbh = 'W03',
}

export enum ViscosityUnitsID {
  PasNsm2 = 'N01',
  mPascP = 'N02',
  P = 'N03',
  kgfsm2 = 'N04',
  lbfsin2 = 'N05',
  lbfsft2 = 'N06',
  m2s = 'N07',
  mm2scSt = 'N08',
  St = 'N09',
  ft2s = 'N10',
}

export enum VelocityUnitsID {
  ms = 'R01',
  fts = 'R02',
}

export enum FluidStatesID {
  Saturated = 'S01',
  Superheated = 'S02',
}
