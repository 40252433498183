import { useEffect, useMemo } from 'react';
import { ROUTE_APP } from '../routes/Routes';
import { getHomePageDomain, langLoopHelper, routeCondition } from '../helpers/lang';
import i18n from '../i18n';

// eslint-disable-next-line max-lines-per-function
const Home = (): JSX.Element => {
  const homePageDomain = useMemo(() => getHomePageDomain(i18n.language), [i18n.language]);

  useEffect(() => {
    if (!homePageDomain) {
      return;
    }

    window.location.href = `${homePageDomain}/calculator`;
  }, []);

  /**
   * @description render all app route for developer
   * @returns
   */
  const renderSubRouter = (langPath: string): JSX.Element => {
    const routes: JSX.Element[] = [];
    ROUTE_APP.forEach((route) => {
      routes.push(
        <li key={route.path}>
          <a href={`${langPath}/${route.path}`}>{`${langPath}/${route.path}`}</a>
        </li>,
      );
    });

    return (
      <li>
        <label
          style={{
            fontWeight: 'bold',
          }}
        >
          {(langPath ? langPath : 'japanese').toUpperCase()}
        </label>
        <ul
          style={{
            padding: 'revert',
            margin: 'auto',
            listStyle: 'circle',
          }}
        >
          {routes}
        </ul>
      </li>
    );
  };

  /**
   * @description render all app route for developer
   * @returns
   */
  const renderLangRouter = (): JSX.Element => {
    if (homePageDomain) {
      return <></>;
    }

    const routes: JSX.Element[] = [];

    langLoopHelper((langData, lang) => {
      if (!routeCondition(langData)) {
        return null;
      }

      routes.push(
        <ul
          key={lang}
          style={{
            margin: '25px auto',
          }}
        >
          {renderSubRouter(langData.path)}
        </ul>,
      );
    });

    return <>{routes}</>;
  };

  return renderLangRouter();
};

export default Home;
