/* eslint-disable max-lines-per-function */
import { useState } from 'react';
import { Col, Row, Table } from 'antd';
import './UnitTable.scss';
import { useTranslation } from 'react-i18next';
import { AlignType } from 'rc-table/lib/interface';
import { CommonUnit } from '../../constants/units';
import ValueSwitching from '../ValueSwitching';
import '../../styles/pages/CalculateCV.scss';
import { HandleOverFloatText } from '../OverFloatText/HandleOverfloattext';
import {
  bigEString,
  EMPTY_STRING,
  formatTableDataResponse,
  maxExponentialLength,
  setExponentialDataTable,
} from '../../helpers/utils';
import { ColumnProps } from 'antd/es/table';

type UnitTableProps = {
  dataTable1: CommonUnit[];
  dataTable2?: CommonUnit[];
  titleTable1?: string;
  titleTable2?: string;
  description?: string;
};

export function getColumns(isExponential: boolean): ColumnProps<CommonUnit>[] {
  const { t } = useTranslation();

  return [
    {
      title: t('CM_0041'),
      dataIndex: 'result',
      key: 'result',
      width: `40%`,
      align: 'right' as AlignType,
      className: 'result-value',
      render: (value: number): string | number => {
        if (value === undefined) {
          return '-';
        }
        if (!isExponential) {
          return value;
        }
        const valueResult = setExponentialDataTable(value);
        const resultStringArr = valueResult.includes('+') ? valueResult.split('+') : valueResult.split('-');
        const beforeExpo = resultStringArr[0].split(bigEString);
        if (Number(beforeExpo[0]) % 10 === 0 && Number(beforeExpo[0]) !== 0) {
          //Only for case 9999... -> 10.000E
          beforeExpo.push(bigEString);
          beforeExpo[0] = (Number(beforeExpo[0]) / 10).toFixed(maxExponentialLength);
          resultStringArr[0] = beforeExpo.join(EMPTY_STRING);
          resultStringArr[resultStringArr.length - 1] = (
            Number(resultStringArr[resultStringArr.length - 1]) + 1
          ).toString();
        }
        if (resultStringArr[resultStringArr.length - 1].length === 1) {
          resultStringArr[resultStringArr.length - 1] = '0' + resultStringArr[resultStringArr.length - 1];
        }
        return valueResult.includes('+')
          ? resultStringArr.join('+').toLocaleUpperCase()
          : resultStringArr.join('-').toLocaleUpperCase();
      },
    },
    {
      title: t('CM_0042'),
      dataIndex: 'unit',
      key: 'unit',
      width: `30%`,
      align: 'center' as AlignType,
      className: 'text-tr',
      render: (value: string, record: CommonUnit) => (
        <span>
          {record.prefix && <span dangerouslySetInnerHTML={{ __html: t(record.prefix) }}></span>}
          <span style={{ marginLeft: '2px' }} dangerouslySetInnerHTML={{ __html: t(value) }}></span>
          {record.nameCol2 && <span dangerouslySetInnerHTML={{ __html: ` (${t(record.nameCol2)})` }}></span>}
        </span>
      ),
    },
    {
      title: t('CM_0043'),
      dataIndex: 'name',
      key: 'name',
      width: `30%`,
      align: 'center' as AlignType,
      render: (value: string): JSX.Element => <HandleOverFloatText text={t(value)} />,
      className: 'text-tr',
    },
  ];
}

// eslint-disable-next-line max-lines-per-function
export default function UnitTable({
  dataTable1,
  dataTable2,
  titleTable1,
  titleTable2,
  description,
}: UnitTableProps): JSX.Element {
  const { t } = useTranslation();
  const [isExponential, setIsExponential] = useState(false);
  return (
    <div className="unit-table-container">
      <ValueSwitching isExponential={isExponential} setIsExponential={setIsExponential} />

      {description ? <div className="des">{description}</div> : null}

      <div className="first-class">
        <Row>
          <Col>
            <div className="cell-null"></div>
          </Col>
          <Col flex="1 1 112px">
            <Row align="stretch" className="header-table">
              <Col className="header-tr">{t('CM_0041')}</Col>
              <Col className="header-tr">{t('CM_0042')}</Col>
              <Col className="header-tr">{t('CM_0043')}</Col>
            </Row>
          </Col>
        </Row>

        <Row align="stretch">
          {dataTable1 && titleTable1 ? (
            <Col flex="0 1 112px">
              <div className="title-table text-center">{t(titleTable1)}</div>
            </Col>
          ) : null}
          <Col flex="1 1 483px">
            <Table
              bordered
              showHeader={false}
              className={`unit-table table1 ${dataTable2 && titleTable2 ? 'has-table2' : ''}`}
              pagination={false}
              dataSource={isExponential ? dataTable1 : (formatTableDataResponse(dataTable1) as CommonUnit[])}
              columns={getColumns(isExponential)}
            ></Table>
          </Col>
        </Row>
      </div>
      {dataTable2 && titleTable2 ? (
        <>
          <div className="hr"> </div>
          <Row align="stretch">
            <Col flex="0 1 112px">
              <div className="title-table text-center">{t(titleTable2)}</div>
            </Col>
            <Col flex="1 1 483px">
              <Table
                bordered
                showHeader={false}
                className="unit-table table2 title-none"
                pagination={false}
                dataSource={isExponential ? dataTable2 : (formatTableDataResponse(dataTable2) as CommonUnit[])}
                columns={getColumns(isExponential)}
              ></Table>
            </Col>
          </Row>
        </>
      ) : null}
    </div>
  );
}
