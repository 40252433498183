/**
 * Steam Cv Calculation
 *
 * Created: 2022/05/12
 * __author__: VuongLK
 * __copyright__: Copyright KITZ Corporation. 2022 All Rights Reserved
 * __version__: 1.1.0
 */
import { Form, RadioChangeEvent } from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ERROR_CODE,
  FLOW_RATE_STEAM_UNITS,
  FLUID_STATES,
  GREAT_PRESSURE,
  P1,
  PRESSURE_CLASSES,
  PRESSURE_UNITS,
  TEMPERATURE_UNITS,
} from '../../constants/units';
import './style.scss';
import { getDefaultRule, getNegativeNumberRule } from '../../constants/rules';
import PageHeading from '../../components/Heading/PageHeading';
import { STATUS_CODE } from '../../constants/statusCode';
import ResultCV from '../../components/ResultCV/ResultCV';
import { useCustomForm } from '../../hooks/useCustomForm';
import { displayResponseData, displayToFixed2Response } from '../../helpers/utils';
import { getHead, getSelectUnit } from '../../helpers/common';
import { DataPost, getResultAPI } from '../../services/http-client';
import { API_PATHS } from '../../constants/apiPaths';
import PageContainer from '../../layouts/PageContainer';
import { BaseForm } from '../../components/BaseForm';
import { BaseFormInputType, IBaseFormOptions } from '../../components/BaseForm/Interface';
import {
  FlowRateSteamUnitsID,
  FluidStatesID,
  PressureClassesID,
  PressureUnitsID,
  TemperatureUnitsID,
  VelocityUnitsID,
} from '../../constants/unitsID';

// eslint-disable-next-line max-lines-per-function
function SteamCvCalculation(): JSX.Element {
  const { t } = useTranslation();
  const [inputForm] = useCustomForm(Form.useForm());
  const validateFields = ['w', 'p1', 'p2', 't1'];

  const [pressureUnit, setPressureUnit] = useState('01');
  const [classesUnit, setClassesUnit] = useState('G');
  const [fluid, setFluid] = useState(FluidStatesID.Saturated);

  const [deltaP, setDeltaP] = useState<string>('');
  const [saturationTemperatureAtP1, setSaturationTemperatureAtP1] = useState('');
  const [superheat, setSuperheat] = useState('');

  const downstreamPipe = useRef('');
  const [error, setError] = useState('');
  const [errorCV, setErrorCV] = useState('');
  const [errorOfDownstreamPipe, setErrorOfDownstreamPipe] = useState('');
  const [t1Unit, setT1Unit] = useState(TemperatureUnitsID.Celsius);

  const [valueCV, setValueCV] = useState('');
  const [pressureRatio, setPressureRatio] = useState('');
  const [resDownstreamPipe, setResDownstreamPipe] = useState({ ft_s: '', m_s: '' });

  // eslint-disable-next-line complexity
  async function getCalcCvSteamSimple(dataPost: DataPost): Promise<void> {
    const data = await getResultAPI(dataPost, API_PATHS.CALC_CV_STEAM_SIMPLE);

    if (data && data?.code && data.code === STATUS_CODE.SUCCESS) {
      data.data && setValueCV(`${data.data.value}`);
      setError('');
    } else {
      if (data?.errors?.validate) {
        const code = data.errors?.validate[Object.keys(data.errors?.validate)[0]];

        if (code?.[ERROR_CODE] === GREAT_PRESSURE) {
          const key = Object.keys(data.errors?.validate)[0];
          key === P1
            ? setError(t('CM_0193', { fieldName: `${t('CM_0194')}` }).toString())
            : setError(t('CM_0193', { fieldName: `${t('CM_0195')}` }).toString());

          return;
        }

        code?.[ERROR_CODE] &&
          setError(t(`${data.errors?.validate[Object.keys(data.errors?.validate)[0]]?.[ERROR_CODE]}`).toString());
      }

      setValueCV(t('CM_0178').toString());
      setErrorCV(t('CM_0178').toString());
    }
  }

  async function getVelFromFlow(dataPostVelFromFlow: DataPost): Promise<void> {
    const data = await getResultAPI(dataPostVelFromFlow, API_PATHS.CALC_VEL_FROM_FLOW_RATE_STEAM);

    if (data?.code === STATUS_CODE.SUCCESS) {
      data?.data &&
        setResDownstreamPipe({
          ft_s: data?.data[VelocityUnitsID.fts].toString(),
          m_s: data?.data[VelocityUnitsID.ms].toString(),
        });
      setErrorOfDownstreamPipe('');
    } else {
      setResDownstreamPipe({ ft_s: `${t('CM_0178')}`, m_s: `${t('CM_0178')}` });
    }
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  async function getResult(): Promise<void> {
    const dataPressRatio = ['p1', 'p2'];

    if (dataPressRatio.every((field) => inputForm.getFieldValue(field) && !inputForm.getFieldError(field)[0])) {
      const dataPostDiffPressure = {
        P1: Number(inputForm.getFieldValue('p1')),
        P2: Number(inputForm.getFieldValue('p2')),
      };
      const dataPost = {
        ...dataPostDiffPressure,
        P_unit_in: `${inputForm.getFieldValue('classesUnit')}${inputForm.getFieldValue('pressureUnit')}`,
      };
      const diffPressureData = await getResultAPI(dataPostDiffPressure, API_PATHS.CALC_DIFF_PRESS);
      diffPressureData?.data &&
        (diffPressureData.data.value || diffPressureData.data.value === 0) &&
        setDeltaP(displayResponseData(diffPressureData.data.value));

      const data = await getResultAPI(dataPost, API_PATHS.CALC_PRESS_RATIO);

      if (data && data?.code && data.code === STATUS_CODE.SUCCESS) {
        data?.data && setPressureRatio(data?.data?.value.toString());
      } else {
        setPressureRatio('');
      }
    } else {
      setDeltaP('');
      setPressureRatio('');
    }

    const t1Value = inputForm.getFieldValue('t1');
    const fieldsVelFromFlow = ['w', 'p1'];

    if (
      fieldsVelFromFlow.every((field) => inputForm.getFieldValue(field) && !inputForm.getFieldError(field)[0]) &&
      downstreamPipe.current
    ) {
      const dataPostVelFromFlow = {
        D: Number(downstreamPipe.current),
        W: Number(inputForm.getFieldValue('w')),
        W_unit_in: inputForm.getFieldValue('wUnit'),
        P2:
          inputForm.getFieldValue('p2') && !inputForm.getFieldError('p2')[0]
            ? Number(inputForm.getFieldValue('p2'))
            : undefined,
        P1: Number(inputForm.getFieldValue('p1')),
        S: inputForm.getFieldValue('fluid'),
        P_unit_in: `${inputForm.getFieldValue('classesUnit')}${inputForm.getFieldValue('pressureUnit')}`,
        T_unit_in: inputForm.getFieldValue('t1Unit'),
      };

      if (inputForm.getFieldValue('fluid') === FluidStatesID.Saturated) {
        getVelFromFlow(dataPostVelFromFlow);
      } else {
        if (t1Value && !inputForm.getFieldError('t1')[0]) {
          getVelFromFlow({ ...dataPostVelFromFlow, T1: Number(inputForm.getFieldValue('t1')) });
        }
      }
    } else {
      if (downstreamPipe.current) {
        setErrorOfDownstreamPipe('');
        setResDownstreamPipe({ ft_s: '', m_s: '' });
      }
    }

    const fieldsCvSteamSimple = ['w', 'p1', 'p2'];
    const dataPost = {
      W: Number(inputForm.getFieldValue('w')),
      W_unit_in: inputForm.getFieldValue('wUnit'),
      P1: Number(inputForm.getFieldValue('p1')),
      P2: Number(inputForm.getFieldValue('p2')),
      P_unit_in: `${inputForm.getFieldValue('classesUnit')}${inputForm.getFieldValue('pressureUnit')}`,
      S: inputForm.getFieldValue('fluid'),
      T_unit_in: inputForm.getFieldValue('t1Unit'),
    };

    if (fieldsCvSteamSimple.every((field) => inputForm.getFieldValue(field))) {
      if (fieldsCvSteamSimple.every((field) => !inputForm.getFieldError(field)[0])) {
        if (inputForm.getFieldValue('fluid') === FluidStatesID.Saturated) {
          getCalcCvSteamSimple({ ...dataPost, T1: undefined });
        } else {
          if (t1Value && !inputForm.getFieldError('t1')[0]) {
            getCalcCvSteamSimple({ ...dataPost, T1: Number(t1Value) });

            const dataPostSteam = {
              T1: Number(t1Value),
              P_unit_in: `${inputForm.getFieldValue('classesUnit')}${inputForm.getFieldValue('pressureUnit')}`,
              T_unit_in: inputForm.getFieldValue('t1Unit'),
            };
            const pressureP1Value = Number(inputForm.getFieldValue('p1'));

            const data = await getResultAPI({ ...dataPostSteam, P1: pressureP1Value }, API_PATHS.CALC_STEAM_TSH);

            if (data?.code === STATUS_CODE.SUCCESS) {
              data?.data?.value && setSuperheat(data?.data?.value.toString());
            } else {
              setSuperheat('');
            }
          } else {
            setValueCV('');
            setSuperheat('');
            setErrorCV('');
          }
        }
      } else {
        setValueCV(t('CM_0178').toString());
        setErrorCV(t('CM_0178').toString());
      }
    } else {
      setSuperheat('');
      setValueCV('');
      setErrorCV('');
    }

    if (inputForm.getFieldValue('p1') && !inputForm.getFieldError('p1')[0]) {
      const dataPost = {
        P: Number(inputForm.getFieldValue('p1')),
        P_unit_in: `${inputForm.getFieldValue('classesUnit')}${inputForm.getFieldValue('pressureUnit')}`,
      };
      const data = await getResultAPI(dataPost, API_PATHS.CALC_SATURATED_TEMP);

      if (data && data.code === STATUS_CODE.SUCCESS) {
        data.data && setSaturationTemperatureAtP1(data.data?.[inputForm.getFieldValue('t1Unit')].toString());
      } else {
        setSaturationTemperatureAtP1(`${t('CM_0205')}`);
        inputForm.getFieldValue('t1') && setSuperheat(`${t('CM_0205')}`);
      }
    } else {
      setSaturationTemperatureAtP1('');
    }
  }

  const onFormValuesChange = (): void => {
    getResult();
    inputForm
      .validateFields(validateFields)
      .then(() => {
        setError('');
      })
      .catch(() => {
        if (inputForm.customError[0]?.msg) {
          setError(inputForm.customError[0]?.msg);
        } else {
          setError('');
          setErrorOfDownstreamPipe('');
        }
      });
  };
  const showErrorMessage = inputForm.getFieldError(['p1']).length === 1;
  const baseFormOpt: IBaseFormOptions = {
    form: inputForm,
    onFormValuesChange,
    className: 'my-custom-class',
    fields: [
      {
        label: t('CM_0028'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'w',
            rules: getNegativeNumberRule(t('CM_0160'), undefined, undefined, undefined, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            name: 'wUnit',
            options: FLOW_RATE_STEAM_UNITS.map((item) => ({
              key: item.value,
              value: item.value,
              label: `${t(item.name)}`,
            })),
            value: FlowRateSteamUnitsID.kgh,
          },
        ],
      },
      {
        label: t('CM_0003'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'p1',
            rules: getDefaultRule(t(`${t('CM_0194')}`), undefined, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            value: PressureUnitsID.Mpa,
            name: 'pressureUnit',
            options: PRESSURE_UNITS.map((item) => ({ key: item.unit, value: item.value, label: item.unit })),
            onChange: (value: string): void => setPressureUnit(value),
          },
          {
            type: BaseFormInputType.Select,
            value: PressureClassesID.Gauge,
            name: 'classesUnit',
            options: PRESSURE_CLASSES.map((item) => ({ key: item.unit, value: item.value, label: t(item.name) })),
            onChange: (value: string): void => setClassesUnit(value),
          },
        ],
      },
      {
        label: t('CM_0004'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'p2',
            rules: getDefaultRule(t(`${t('CM_0195')}`), undefined, true),
            placeholder: t('CM_0129'),
            unit: getSelectUnit(pressureUnit, classesUnit) && getSelectUnit(pressureUnit, classesUnit)[0],
          },
        ],
      },
      {
        label: t('CM_0005'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'delta',
            unit: getSelectUnit(pressureUnit, classesUnit) && getSelectUnit(pressureUnit, classesUnit)[1],
            value: deltaP,
            isResultField: true,
            disabled: true,
            isSpecialFormat: true,
          },
        ],
      },
      {
        label: t('CM_0138'),
        note: <span className="note2">{t('CM_0031')}</span>,
        inputs: [
          {
            type: BaseFormInputType.RadioGroup,
            name: 'fluid',
            options: FLUID_STATES.map((item) => ({
              key: item.value,
              value: item.value,
              label: `${t(item.name)}`,
            })),
            value: FluidStatesID.Saturated,
            onChange: (e: RadioChangeEvent): void => {
              setFluid(e.target.value);
              setSuperheat('');
              inputForm.setFieldValue('t1', '');
            },
            note: <>{t('CM_0031')}</>,
          },
        ],
      },
      {
        label: t('CM_0018'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 't1',
            rules: getDefaultRule(t('CM_0159'), undefined, true),
            disabled: fluid === FluidStatesID.Saturated,
            autoComplete: false,
            placeholder: fluid === FluidStatesID.Saturated ? '' : t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            name: 't1Unit',
            value: TemperatureUnitsID.Celsius,
            onChange(value: TemperatureUnitsID): void {
              setT1Unit(value);
            },
            options: TEMPERATURE_UNITS.map((item) => ({
              key: item.unit,
              value: item.value,
              label: `${t(item.label)}`,
            })),
          },
        ],
      },
      {
        label: t('CM_0032'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'saturationTemperatureAtP1',
            unit: getHead(t1Unit),
            value: showErrorMessage ? `${t('CM_0205')}` : displayToFixed2Response(saturationTemperatureAtP1),
            isResultField: true,
            disabled: true,
            isSpecialFormat: true,
          },
        ],
      },
      {
        label: t('CM_0033'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'superheat',
            unit: getHead(t1Unit),
            value:
              showErrorMessage && fluid === FLUID_STATES[1].value
                ? `${t('CM_0205')}`
                : displayToFixed2Response(superheat),
            isResultField: true,
            disabled: true,
            // limitMinValue: limitMinToDisplayExpo,
            // isRoundingString: true,
            isSpecialFormat: true,
          },
        ],
      },
    ],
  };

  return (
    <PageContainer>
      <>
        <div className="cv steam">
          <PageHeading borderColor="pear">{t('CM_0027')}</PageHeading>

          <BaseForm options={baseFormOpt}></BaseForm>
        </div>
        <ResultCV
          errorCV={errorCV}
          error={error}
          valueCV={valueCV}
          pressureRatio={pressureRatio}
          resDownstreamPipe={resDownstreamPipe}
          targetOfFlow={'CM_0026'}
          errorOfDownstreamPipe={errorOfDownstreamPipe}
          setErrorOfDownstreamPipe={setErrorOfDownstreamPipe}
          getResult={getResult}
          downstreamPipe={downstreamPipe}
          setResDownstreamPipe={setResDownstreamPipe}
          setErrorCV={setErrorCV}
          CVtype={'gas'}
        />
      </>
    </PageContainer>
  );
}

export default SteamCvCalculation;
