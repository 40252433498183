import i18n from '../i18n';
import {
  FlowRateGasUnitsID,
  FlowRateLiquidUnitsID,
  FlowRateSteamUnitsID,
  FluidStatesID,
  PressureClassesID,
  PressureUnitsID,
  TemperatureUnitsID,
  VelocityUnitsID,
} from './unitsID';
export const P_STRING = 'P';
export const GREAT_PRESSURE = 1511;
export const P1 = 'P1';
export const T1 = 'T1';

export interface CommonUnit {
  label?: string;
  value: string;
  unit?: string;
  name?: string;
  result?: number;
  nameCol2?: string;
  prefix?: string;
  id?: string;
  // type?: 'normal' | 'actual' | 'standard';
  // tab?: 'mass' | 'volume';
}
export interface ITableData {
  key: string;
  value: string;
  unit: string;
  name: string;
}

export enum PressureUnitTable {
  Absolute = 'Absolute',
  Gauge = 'Gauge',
}

export interface IPressureUnit extends CommonUnit {
  tab?: PressureUnitTable;
}

export const PRESSURE_UNITS_TABLE: IPressureUnit[] = [
  { value: 'G01', label: 'MPa(G)', unit: 'MPa(G)', name: 'CM_0062', tab: PressureUnitTable.Gauge },
  { value: 'G02', label: 'kPa(G)', unit: 'kPa(G)', name: 'CM_0063', tab: PressureUnitTable.Gauge },
  { value: 'G03', label: 'Pa(G)', unit: 'Pa(G)', name: 'CM_0064', tab: PressureUnitTable.Gauge },
  { value: 'G04', label: 'bar(G)', unit: 'bar(G)', name: 'CM_0065', tab: PressureUnitTable.Gauge },
  {
    value: 'G05',
    label: 'kgf/cm<sup>2</sup>(G)',
    unit: 'kgf/cm<sup>2</sup>(G)',
    name: 'CM_0066',
    tab: PressureUnitTable.Gauge,
  },
  {
    value: 'G06',
    label: 'mmHg or Torr(G)',
    unit: 'mmHg or Torr(G)',
    name: 'CM_0067',
    tab: PressureUnitTable.Gauge,
  },
  {
    value: 'G07',
    label: 'mmH<sub>2</sub>o or mmAq(G)',
    unit: 'mmH<sub>2</sub>o or mmAq(G)',
    name: 'CM_0068',
    tab: PressureUnitTable.Gauge,
  },
  {
    value: 'G08',
    label: 'psi or lbf/in<sup>2</sup>(G)',
    unit: 'psi or lbf/in<sup>2</sup>(G)',
    name: 'CM_0069',
    tab: PressureUnitTable.Gauge,
  },
  {
    value: 'G09',
    label: 'inH<sub>2</sub>O(G)',
    unit: 'inH<sub>2</sub>O(G)',
    name: 'CM_0070',
    tab: PressureUnitTable.Gauge,
  },
  {
    value: 'G10',
    label: 'ftH<sub>2</sub>O(G)',
    unit: 'ftH<sub>2</sub>O(G)',
    name: 'CM_0071',
    tab: PressureUnitTable.Gauge,
  },
  { value: 'A01', label: 'MPa(a)', unit: 'MPa(a)', name: 'CM_0062', tab: PressureUnitTable.Absolute },
  { value: 'A02', label: 'kPa(a)', unit: 'kPa(a)', name: 'CM_0063', tab: PressureUnitTable.Absolute },
  { value: 'A03', label: 'Pa(a)', unit: 'Pa(a)', name: 'CM_0064', tab: PressureUnitTable.Absolute },
  { value: 'A04', label: 'bar(a)', unit: 'bar(a)', name: 'CM_0065', tab: PressureUnitTable.Absolute },
  {
    value: 'A05',
    label: 'kgf/cm<sup>2</sup>(a)',
    unit: 'kgf/cm<sup>2</sup>(a)',
    name: 'CM_0066',
    tab: PressureUnitTable.Absolute,
  },
  {
    value: 'A06',
    label: 'mmHg or Torr(a)',
    unit: 'mmHg or Torr(a)',
    name: 'CM_0067',
    tab: PressureUnitTable.Absolute,
  },
  {
    value: 'A07',
    label: 'mmH<sub>2</sub>o or mmAq(a)',
    unit: 'mmH<sub>2</sub>o or mmAq(a)',
    name: 'CM_0068',
    tab: PressureUnitTable.Absolute,
  },
  {
    value: 'A08',
    label: 'psi or lbf/in<sup>2</sup>(a)',
    unit: 'psi or lbf/in<sup>2</sup>(a)',
    name: 'CM_0069',
    tab: PressureUnitTable.Absolute,
  },
  {
    value: 'A09',
    label: 'inH<sub>2</sub>O(a)',
    unit: 'inH<sub>2</sub>O(a)',
    name: 'CM_0070',
    tab: PressureUnitTable.Absolute,
  },
  {
    value: 'A10',
    label: 'ftH<sub>2</sub>O(a)',
    unit: 'ftH<sub>2</sub>O(a)',
    name: 'CM_0071',
    tab: PressureUnitTable.Absolute,
  },
];

export const PRESSURE_UNITS = [
  { value: PressureUnitsID.Mpa, label: 'MPa', unit: 'MPa', name: 'CM_0062' },
  { value: PressureUnitsID.kPa, label: 'kPa', unit: 'kPa', name: 'CM_0063' },
  { value: PressureUnitsID.Pa, label: 'Pa', unit: 'Pa', name: 'CM_0064' },
  { value: PressureUnitsID.bar, label: 'bar', unit: 'bar', name: 'CM_0065' },
  { value: PressureUnitsID.kgfcm2, label: 'kgf/cm<sup>2</sup>', unit: 'kgf/cm<sup>2</sup>', name: 'CM_0066' },
  {
    value: PressureUnitsID.mmHgTorr,
    label: 'mmHg or Torr',
    unit: 'mmHg or Torr',
    name: 'CM_0067',
  },
  {
    value: PressureUnitsID.mmH2OmmAq,
    label: 'mmH<sub>2</sub>O or mmAq',
    unit: 'mmH<sub>2</sub>O or mmAq',
    name: 'CM_0068',
  },
  {
    value: PressureUnitsID.psilbfin2,
    label: 'psi or lbf/in<sup>2</sup>',
    unit: 'psi or lbf/in<sup>2</sup>',
    name: 'CM_0069',
  },
  { value: PressureUnitsID.inH2O, label: 'inH<sub>2</sub>O', unit: 'inH<sub>2</sub>O', name: 'CM_0070' },
  { value: PressureUnitsID.ftH2O, label: 'ftH<sub>2</sub>O', unit: 'ftH<sub>2</sub>O', name: 'CM_0071' },
];

export const PRESSURE_CLASSES = [
  { value: PressureClassesID.Gauge, name: 'CM_0006', label: '(G) ゲージ圧力', unit: 'G' },
  { value: PressureClassesID.Absolute, name: 'CM_0007', label: '(a) 絶対圧力', unit: 'a' },
];

export const TEMPERATURE_UNITS = [
  {
    value: TemperatureUnitsID.Celsius,
    name: 'CM_0091',
    unit: '°C',
    label: 'CM_0142',
  },
  {
    value: TemperatureUnitsID.Kevil,
    name: 'CM_0092',
    unit: 'K',
    label: 'CM_0143',
  },
  {
    value: TemperatureUnitsID.Fahrenheit,
    name: 'CM_0093',
    unit: '°F',
    label: 'CM_0144',
  },
  {
    value: TemperatureUnitsID.Rankine,
    name: 'CM_0094',
    unit: '°R',
    label: 'CM_0145',
  },
];

export enum ViscosityLiquidUnitTab {
  viscosity = 'VISCOSTY',
  kinematicViscosity = 'KINEMATIC_VISCOSTY',
}
export interface IViscosityLiquidUnit extends CommonUnit {
  tab?: ViscosityLiquidUnitTab;
}
export const VISCOSITY_LIQUID_UNITS: IViscosityLiquidUnit[] = [
  {
    value: '01',
    label: `${i18n.t('CM_0107')} Pa・s or N・s/m2`,
    unit: 'Pa・s or N・s/m2',
    name: `${i18n.t('CM_0109')}`,
    tab: ViscosityLiquidUnitTab.viscosity,
  },
  {
    value: '02',
    label: `${i18n.t('CM_0107')} mPa・s or cP`,
    unit: 'mPa・s or cP',
    name: `${i18n.t('CM_0110')}`,
    tab: ViscosityLiquidUnitTab.viscosity,
  },
  {
    value: '03',
    label: `${i18n.t('CM_0107')} P`,
    unit: 'P',
    name: `${i18n.t('CM_0111')}`,
    tab: ViscosityLiquidUnitTab.viscosity,
  },
  {
    value: '04',
    label: `${i18n.t('CM_0107')} kgf・s/m2`,
    unit: 'kgf・s/m2',
    name: `${i18n.t('CM_0112')}`,
    tab: ViscosityLiquidUnitTab.viscosity,
  },
  {
    value: '05',
    label: `${i18n.t('CM_0107')} lbf・s/in<sup>2</sup>`,
    unit: 'lbf・s/in<sup>2</sup>',
    name: `${i18n.t('CM_0113')}`,
    tab: ViscosityLiquidUnitTab.viscosity,
  },
  {
    value: '06',
    label: `${i18n.t('CM_0107')} lbf・s/ft2`,
    unit: 'lbf・s/ft2',
    name: `${i18n.t('CM_0114')}`,
    tab: ViscosityLiquidUnitTab.viscosity,
  },
  {
    value: '07',
    label: `${i18n.t('CM_0108')} m2/s`,
    unit: 'm<sup>2</sup>/s',
    name: `${i18n.t('CM_0115')}`,
    tab: ViscosityLiquidUnitTab.kinematicViscosity,
  },
  {
    value: '08',
    label: `${i18n.t('CM_0108')} mm2/s or cSt`,
    unit: 'mm<sup>2</sup>/s or cSt',
    name: `${i18n.t('CM_0116')}`,
    tab: ViscosityLiquidUnitTab.kinematicViscosity,
  },
  {
    value: '09',
    label: `${i18n.t('CM_0108')} St`,
    unit: 'St',
    name: `${i18n.t('CM_0117')}`,
    tab: ViscosityLiquidUnitTab.kinematicViscosity,
  },
  {
    value: '10',
    label: `${i18n.t('CM_0108')} ft2/s`,
    unit: 'ft<sup>2</sup>/s',
    name: `${i18n.t('CM_0118')}`,
    tab: ViscosityLiquidUnitTab.kinematicViscosity,
  },
];

export const TEMPERATURE_TABLE_DATA: ITableData[] = [
  { key: '1', value: '', unit: '°C', name: `${i18n.t('CM_0091')}` },
  { key: '2', value: '', unit: 'K', name: `${i18n.t('CM_0092')}` },
  { key: '3', value: '', unit: '°F', name: `${i18n.t('CM_0093')}` },
  { key: '4', value: '', unit: '°R', name: `${i18n.t('CM_0094')}` },
];

export const FLUID_STATES = [
  { value: FluidStatesID.Saturated, name: 'CM_0029' },
  { value: FluidStatesID.Superheated, name: 'CM_0030' },
];

export const LIQUID_FLOW_UNITS: { volume: CommonUnit[]; mass: CommonUnit[] } = {
  volume: [
    {
      unit: 'm<sup>3</sup>/h',
      name: 'CM_0044',
      value: 'A01',
    },
    {
      unit: 'm<sup>3</sup>/min',
      name: 'CM_0045',
      value: 'A02',
    },
    {
      unit: 'L/h',
      name: 'CM_0046',
      value: 'A03',
    },
    {
      unit: 'L/min',
      name: 'CM_0047',
      value: 'A04',
    },
    {
      unit: 'gal/min',
      name: 'CM_0048',
      value: 'A05',
    },
  ],
  mass: [
    {
      unit: 'kg/h',
      name: 'CM_0049',
      value: 'M01',
    },
    {
      unit: 't/h',
      name: 'CM_0050',
      value: 'M02',
    },
    {
      unit: 'lb/h',
      name: 'CM_0051',
      value: 'M03',
    },
  ],
};
export enum UnitType {
  NORMAL = 'NORMAL',
  ACTUAL = 'ACTUAL',
  STANDARD = 'STANDARD',
}
export const GAS_FLOW_UNITS: {
  volume: {
    normal: CommonUnit[];
    actual: CommonUnit[];
    standard: CommonUnit[];
  };
  mass: CommonUnit[];
} = {
  volume: {
    normal: [
      {
        value: 'S01',
        unit: 'Nm<sup>3</sup>/h',
        name: 'CM_0054',
      },
      {
        value: 'S02',
        unit: 'Nm<sup>3</sup>/min',
        name: 'CM_0055',
      },
      {
        value: 'S03',
        unit: 'NL/h',
        name: 'CM_0056',
      },
      {
        value: 'S04',
        unit: 'NL/min',
        name: 'CM_0057',
      },
    ],
    actual: [
      {
        value: 'A01',
        unit: 'm<sup>3</sup>/h',
        name: 'CM_0044',
      },
      {
        value: 'A02',
        unit: 'm<sup>3</sup>/min',
        name: 'CM_0045',
      },
      {
        value: 'A03',
        unit: 'L/h',
        name: 'CM_0046',
      },
      {
        value: 'A04',
        unit: 'L/min',
        name: 'CM_0047',
      },
    ],
    standard: [
      {
        value: 'S05',
        unit: 'Sm<sup>3</sup>/h',
        name: 'CM_0058',
      },
      {
        value: 'S06',
        unit: 'SCFH',
        name: 'CM_0192',
      },
    ],
  },
  mass: LIQUID_FLOW_UNITS.mass,
};

export enum GasFlowUnitTab {
  MASS = 'MASS',
  VOLUME = 'VOLUME',
}
export interface IGasFlowUnit extends CommonUnit {
  type?: UnitType;
  tab?: GasFlowUnitTab;
}
export const GAS_FLOW_UNIT_LIST: IGasFlowUnit[] = [
  {
    value: '01',
    label: '体積 Nm<sup>3</sup>/h  (ノルマル)',
    unit: 'Nm<sup>3</sup>/h',
    name: 'CM_0054',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.NORMAL,
  },
  {
    value: '02',
    label: '体積 Nm<sup>3</sup>/min　(ノルマル)',
    unit: 'Nm<sup>3</sup>/min',
    name: 'CM_0055',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.NORMAL,
  },
  {
    value: '03',
    label: '体積 NL/h (ノルマル)',
    unit: 'NL/h',
    name: 'CM_0056',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.NORMAL,
  },
  {
    value: '04',
    label: '体積 NL/min (ノルマル)',
    unit: 'NL/min',
    name: 'CM_0057',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.NORMAL,
  },
  {
    value: '05',
    label: '体積 m<sup>3</sup>/h (実流量)',
    unit: 'm<sup>3</sup>/h',
    name: 'CM_0044',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.ACTUAL,
  },
  {
    value: '06',
    label: '体積 m<sup>3</sup>/min (実流量)',
    unit: 'm<sup>3</sup>/min',
    name: 'CM_0045',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.ACTUAL,
  },
  {
    value: '07',
    label: '体積 L/h (実流量)',
    unit: 'L/h',
    name: 'CM_0046',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.ACTUAL,
  },
  {
    value: '08',
    label: '体積 L/min (実流量)',
    unit: 'L/min',
    name: 'CM_0047',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.ACTUAL,
  },

  {
    value: '09',
    label: '体積 Sm<sup>3</sup>/h (スタンダード)',
    unit: 'Sm<sup>3</sup>/h',
    name: 'CM_0058',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.STANDARD,
  },
  {
    value: '10',
    label: '体積 SCFH (スタンダード)',
    unit: 'SCFH',
    name: 'CM_0192',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.STANDARD,
  },
  {
    unit: 'kg/h',
    name: 'CM_0049',
    value: '11',
    label: '質量 kg/h',
    tab: GasFlowUnitTab.MASS,
  },
  {
    unit: 't/h',
    name: 'CM_0050',
    value: '12',
    label: '質量 t/h',
    tab: GasFlowUnitTab.MASS,
  },
  {
    unit: 'lb/h',
    name: 'CM_0051',
    value: '13',
    label: '質量 lb/h',
    tab: GasFlowUnitTab.MASS,
  },
];

export interface OptionSelect {
  label: string;
  value: number;
}

export const VELOCITY_UNITS = [
  { label: 'm/s', value: VelocityUnitsID.ms },
  { label: 'ft/s', value: VelocityUnitsID.fts },
];

export const ERROR_CODE = 'error code';

export const STEAM_SATURATION = FluidStatesID.Saturated;
export const STEAM_HOT = FluidStatesID.Superheated;

export const HEAD = {
  [TemperatureUnitsID.Celsius]: '°C',
  [TemperatureUnitsID.Kevil]: 'K',
  [TemperatureUnitsID.Fahrenheit]: '°F',
  [TemperatureUnitsID.Rankine]: '°R',
};

export const K = '02';
export const HIGH_LIGHT = 0.5;
export const VALIDATE = 'validate';

export const FLOW_RATE_LIQUID_UNITS = [
  { value: FlowRateLiquidUnitsID.m3h, name: 'CM_0021', unit: ' m<sup>3</sup>/h', label: '' },
  { value: FlowRateLiquidUnitsID.m3min, name: 'CM_0021', unit: ' m<sup>3</sup>/min', label: '' },
  { value: FlowRateLiquidUnitsID.Lh, name: 'CM_0021', unit: ' L/h', label: '' },
  { value: FlowRateLiquidUnitsID.Lmin, name: 'CM_0021', unit: ' L/min', label: '' },
  { value: FlowRateLiquidUnitsID.Usgalmin, name: 'CM_0187', unit: '', label: '' },
  { value: FlowRateLiquidUnitsID.kgh, name: 'CM_0184', unit: '', label: '' },
  { value: FlowRateLiquidUnitsID.th, name: 'CM_0185', unit: '', label: '' },
  { value: FlowRateLiquidUnitsID.lbh, name: 'CM_0186', unit: '', label: '' },
];

export const FLOW_RATE_GAS_UNITS = [
  { label: '', unit: '', name: 'CM_0146', value: FlowRateGasUnitsID.Nm3h },
  { label: '', unit: '', name: 'CM_0147', value: FlowRateGasUnitsID.Nm3min },
  { label: '', unit: '', name: 'CM_0148', value: FlowRateGasUnitsID.NLh },
  { label: '', unit: '', name: 'CM_0149', value: FlowRateGasUnitsID.NLmin },
  { label: '', unit: '', name: 'CM_0150', value: FlowRateGasUnitsID.m3h },
  { label: '', unit: '', name: 'CM_0151', value: FlowRateGasUnitsID.m3min },
  { label: '', unit: '', name: 'CM_0152', value: FlowRateGasUnitsID.Lh },
  { label: '', unit: '', name: 'CM_0153', value: FlowRateGasUnitsID.Lmin },
  { label: '', unit: '', name: 'CM_0154', value: FlowRateGasUnitsID.Sm3h },
  { label: '', unit: '', name: 'CM_0155', value: FlowRateGasUnitsID.SCFH },
  { label: '', unit: '', name: 'CM_0184', value: FlowRateGasUnitsID.kgh },
  { label: '', unit: '', name: 'CM_0185', value: FlowRateGasUnitsID.th },
  { label: '', unit: '', name: 'CM_0186', value: FlowRateGasUnitsID.lbh },
];

export const FLOW_RATE_STEAM_UNITS = [
  { label: '', unit: '', name: 'CM_0184', value: FlowRateSteamUnitsID.kgh },
  { label: '', unit: '', name: 'CM_0185', value: FlowRateSteamUnitsID.th },
  { label: '', unit: '', name: 'CM_0186', value: FlowRateSteamUnitsID.lbh },
];
