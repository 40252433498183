/**
 * Handle api get temperatureConversion result
 *
 * Created: 2022/12/14
 * __author__: DucND16
 * __copyright__:
 * __version__: 1.0.0
 */

import { AxiosResponse } from 'axios';
import req from './http-client';
import { IResponseInterface } from './responseInterface';
export interface IBodyData {
  T: number;
  T_unit_in: string;
}

const getTemperatureUnitConversion = (t: string, tUnit: string): Promise<AxiosResponse<IResponseInterface>> => {
  const bodyData: IBodyData = {
    T: Number(t),
    T_unit_in: tUnit,
  };

  return req.post('/convTempUnit', {
    ...bodyData,
  });
};

export { getTemperatureUnitConversion };
