import { ResourceLanguage } from 'i18next';

import en from '../assets/locales/en.json';
import jp from '../assets/locales/jp.json';

const rootDomain = {
  en: 'kitz.com',
  ja: 'kitz.co.jp',
};

export enum LangRouteCondition {
  ALLOW = 'allow',
  NOT_ALLOW = 'not_allow',
}

export const routeCondition = (lang: ILangDetailConfig): boolean => {
  if (!location.host.includes(rootDomain.ja) && !location.host.includes(rootDomain.en)) {
    return true;
  }

  return lang.condition === LangRouteCondition.ALLOW;
};

export interface ILangHomePageMapping {
  defaultHomePageDomain: string;
}

export interface ILangDetailConfig extends ResourceLanguage, ILangHomePageMapping {
  path: string;
  dateCountryCode: string;
  gaId: string;
  condition: string;
}

export interface ILangConfig {
  [lang: string]: ILangDetailConfig;
}

export const DEFAULT_LANGUAGE = 'ja';

export const LANG_CONFIG: ILangConfig = {
  en: {
    translation: en,
    path: 'en',
    dateCountryCode: 'en-GB',
    defaultHomePageDomain: '',
    gaId: 'GTM-TCWSZNB',
    condition: location.hostname.includes(rootDomain.en) ? LangRouteCondition.ALLOW : LangRouteCondition.NOT_ALLOW,
  },
  ja: {
    translation: jp,
    path: '',
    dateCountryCode: 'ja-JP',
    defaultHomePageDomain: '',
    gaId: 'GTM-5L3T3M2',
    condition: LangRouteCondition.ALLOW,
  },
};

export const SUPPORTED_LANG = Object.keys(LANG_CONFIG);

interface IDomainMapping {
  [lang: string]: string;
}
const langHomePageDomainMapping = ((): IDomainMapping => {
  const domainConfig: string = process.env.REACT_APP_HOME_PAGE_DOMAIN || '';
  const config: IDomainMapping = {
    [DEFAULT_LANGUAGE]: LANG_CONFIG[DEFAULT_LANGUAGE].defaultHomePageDomain,
  };

  if (!domainConfig) {
    return config;
  }

  try {
    const homePage = domainConfig.split('--');

    homePage.forEach((l) => {
      const [key, val] = l.split('=');

      if (key && val) {
        config[key] = val;
      }
    });
  } catch (error) {
    console.log('Cannot detect home page mapping');
  } finally {
    return config;
  }
})();

export const getHomePageDomain = (lang: string): string => {
  const domainMapping = langHomePageDomainMapping[lang];

  if (!domainMapping) {
    return LANG_CONFIG[DEFAULT_LANGUAGE].defaultHomePageDomain;
  }
  return domainMapping;
};

export const langMapHelper = (
  callback: (langData: ILangDetailConfig, lang: string, index: number) => JSX.Element | null,
): (JSX.Element | null)[] => {
  return SUPPORTED_LANG.map((lang, index) => {
    return callback(LANG_CONFIG[lang], lang, index);
  });
};

export const langLoopHelper = (callback: (langData: ILangDetailConfig, lang: string, index: number) => void): void => {
  SUPPORTED_LANG.forEach((lang, index) => {
    callback(LANG_CONFIG[lang], lang, index);
  });
};

export const getGaId = (lang: string): string => {
  const config = LANG_CONFIG[lang];

  if (!config) {
    return LANG_CONFIG[DEFAULT_LANGUAGE].gaId;
  }

  return config.gaId;
};

export const appendGaTag = (lang: string): void => {
  const gaId = getGaId(lang);
  const script = document.createElement('script');

  script.innerText = `
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${gaId}');
  `;
  document.head.appendChild(script);

  const noscript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${gaId}`;
  iframe.width = '0';
  iframe.height = '0';
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  noscript.appendChild(iframe);

  document.body.appendChild(noscript);
};

export const getLangPath = (lang: string, pathArray: string[]): string => {
  const langPath = LANG_CONFIG[lang].path;

  if (!langPath) {
    return [''].concat(pathArray).join('/');
  }

  return ['', langPath].concat(pathArray).join('/');
};
