/**
 * Constants are used for flow velocity liquid calculation
 *
 * Created: 2023/01/04
 * __author__: DuongDL
 * __copyright__:
 * __version__: 1.0
 */
import { CommonUnit } from '../../constants/units';
import { FlowRateLiquidUnitsID } from '../../constants/unitsID';

export const LIQUID_VELOCITY_UNITS: { absolutePressure: CommonUnit[]; gaugePressure: CommonUnit[] } = {
  absolutePressure: [
    {
      unit: 'm<sup>3</sup>/h',
      name: 'CM_0044',
      value: FlowRateLiquidUnitsID.m3h,
      id: FlowRateLiquidUnitsID.m3h,
    },
    {
      unit: 'm<sup>3</sup>/min',
      name: 'CM_0045',
      value: FlowRateLiquidUnitsID.m3min,
      id: FlowRateLiquidUnitsID.m3min,
    },
    {
      unit: 'L/h',
      name: 'CM_0046',
      value: FlowRateLiquidUnitsID.Lh,
      id: FlowRateLiquidUnitsID.Lh,
    },
    {
      unit: 'L/min',
      name: 'CM_0047',
      value: FlowRateLiquidUnitsID.Lmin,
      id: FlowRateLiquidUnitsID.Lmin,
    },
    {
      unit: 'US gal/min',
      name: 'CM_0048',
      value: FlowRateLiquidUnitsID.Usgalmin,
      id: FlowRateLiquidUnitsID.Usgalmin,
    },
  ],
  gaugePressure: [
    {
      unit: 'kg/h',
      name: 'CM_0049',
      value: FlowRateLiquidUnitsID.kgh,
      id: FlowRateLiquidUnitsID.kgh,
    },
    {
      unit: 't/h',
      name: 'CM_0050',
      value: FlowRateLiquidUnitsID.th,
      id: FlowRateLiquidUnitsID.th,
    },
    {
      unit: 'lb/h',
      name: 'CM_0051',
      value: FlowRateLiquidUnitsID.lbh,
      id: FlowRateLiquidUnitsID.lbh,
    },
  ],
};
