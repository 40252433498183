import {
  IFlowSteamCalculationReq,
  ISaturatedTemperatureReq,
  ISuperHeatedTemperatureReq,
} from './../interfaces/flowCalculation';
/**
 * Apis are used for flow calculation
 *
 * Created: 2022/15/12
 * __author__: DienPD1
 * __copyright__:
 * __version__: 1.0
 */
import { IFlowLiquidCalculationReq, IFlowGasCalculationReq } from './../interfaces/flowCalculation';
import { AxiosResponse } from 'axios';
import req from './http-client';
import { API_PATHS } from '../constants/apiPaths';

export interface IResponseData {
  [x: string]: number;
}

/**
 * Api get different pressure
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
export function getCalculateDifferentPressure(inputData: {
  P1: number;
  P2: number;
}): Promise<AxiosResponse<{ data: IResponseData }>> {
  return req.post(API_PATHS.CALC_DIFF_PRESS, inputData);
}

/**
 * Api get pressure ratio
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
export function getCalculatePressureRatio(inputData: {
  P1: number;
  P2: number;
  P_unit_in: string;
}): Promise<AxiosResponse<{ data: IResponseData }>> {
  return req.post(API_PATHS.CALC_PRESS_RATIO, inputData);
}

/**
 * Api get saturated temperature calculated
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
export function getCalculateSaturatedTemperature(
  inputData: ISaturatedTemperatureReq,
): Promise<AxiosResponse<{ data: IResponseData }>> {
  return req.post(API_PATHS.CALC_SATURATION_TEMPERATURE, inputData);
}

/**
 * Api get super heated temperature calculated
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
export function getCalculateSuperHeatedTemperature(
  inputData: ISuperHeatedTemperatureReq,
): Promise<AxiosResponse<{ data: IResponseData }>> {
  return req.post(API_PATHS.CALC_SUPER_HEAT, inputData);
}

/**
 * Api get liquid flow rate calculated
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
export function getFlowLiquidCalculateResult(
  inputData: IFlowLiquidCalculationReq,
): Promise<AxiosResponse<{ data: IResponseData }>> {
  return req.post(API_PATHS.CALC_FLOW_RATE_LIQUID, inputData);
}

/**
 * Api get gas flow rate calculated
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
export function getFlowGasCalculateResult(
  inputData: IFlowGasCalculationReq,
): Promise<AxiosResponse<{ data: IResponseData }>> {
  return req.post(API_PATHS.CALC_FLOW_RATE_GAS, inputData);
}

/**
 * Api get gas flow rate calculated
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
export function getFlowSteamCalculateResult(
  inputData: IFlowSteamCalculationReq,
): Promise<AxiosResponse<{ data: IResponseData }>> {
  return req.post(API_PATHS.CALC_FLOW_RATE_STEAM, inputData);
}
