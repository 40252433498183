import {
  FlowRateGasUnitsID,
  FlowRateLiquidUnitsID,
  FlowRateSteamUnitsID,
  PressureClassesID,
  PressureUnitsID,
  TemperatureUnitsID,
} from '../../constants/unitsID';
import i18n from '../../i18n';

export const NO_1 = '01';
export const NO_2 = '02';
export const R_1 = 'R01';
export const R_2 = 'R02';
// export const judgementMsg = `${t('CM_0177')}`;
export enum UnitType {
  NORMAL = 'NORMAL',
  ACTUAL = 'ACTUAL',
  STANDARD = 'STANDARD',
}
export enum GasFlowUnitTab {
  MASS = 'MASS',
  VOLUME = 'VOLUME',
}
export interface CommonUnit {
  label: string;
  value: string;
  unit: string;
  name: string;
  result?: number;
  // type?: 'normal' | 'actual' | 'standard';
  type?: string;
}
export interface IGasFlowUnit extends CommonUnit {
  type?: UnitType;
  tab?: GasFlowUnitTab;
}
export const STEAM_FLOW_UNIT_LIST: IGasFlowUnit[] = [
  {
    unit: 'kg/h',
    name: 'CM_0184',
    value: FlowRateSteamUnitsID.kgh,
    label: 'CM_0184',
    tab: GasFlowUnitTab.MASS,
  },
  {
    unit: 't/h',
    name: 'CM_0185',
    value: FlowRateSteamUnitsID.th,
    label: 'CM_0185',
    tab: GasFlowUnitTab.MASS,
  },
  {
    unit: 'lb/h',
    name: 'CM_0186',
    value: FlowRateSteamUnitsID.lbh,
    label: 'CM_0186',
    tab: GasFlowUnitTab.MASS,
  },
];
export const TEMPERATURE_UNITS: CommonUnit[] = [
  {
    value: TemperatureUnitsID.Celsius,
    name: 'CM_0142',
    unit: '°C',
    label: 'CM_0142',
  },
  {
    value: TemperatureUnitsID.Kevil,
    name: 'CM_0143',
    unit: 'K',
    label: 'CM_0143',
  },
  {
    value: TemperatureUnitsID.Fahrenheit,
    name: 'CM_0144',
    unit: '°F',
    label: 'CM_0144',
  },
  {
    value: TemperatureUnitsID.Rankine,
    name: 'CM_0145',
    unit: '°R',
    label: 'CM_0145',
  },
];
export const PRESSURE_CLASSES: CommonUnit[] = [
  { value: PressureClassesID.Gauge, name: 'CM_0006', label: i18n.t('CM_0006') as string, unit: 'CM_0006' },
  { value: PressureClassesID.Absolute, name: 'CM_0007', label: i18n.t('CM_0007') as string, unit: 'CM_0007' },
];
export const PRESSURE_UNITS: CommonUnit[] = [
  { value: PressureUnitsID.Mpa, label: 'MPa', unit: 'MPa', name: 'CM_0062' },
  { value: PressureUnitsID.kPa, label: 'kPa', unit: 'kPa', name: 'CM_0063' },
  { value: PressureUnitsID.Pa, label: 'Pa', unit: 'Pa', name: 'CM_0064' },
  { value: PressureUnitsID.bar, label: 'bar', unit: 'bar', name: 'CM_0065' },
  { value: PressureUnitsID.kgfcm2, label: 'kgf/cm<sup>2</sup>', unit: 'kgf/cm<sup>2</sup>', name: 'CM_0066' },
  {
    value: PressureUnitsID.mmHgTorr,
    label: 'mmHg or Torr',
    unit: 'mmHg or Torr',
    name: 'CM_0067',
  },
  {
    value: PressureUnitsID.mmH2OmmAq,
    label: 'mmH<sub>2</sub>O or mmAq',
    unit: 'mmH<sub>2</sub>O or mmAq',
    name: 'CM_0068',
  },
  {
    value: PressureUnitsID.psilbfin2,
    label: 'psi or lbf/in<sup>2</sup>',
    unit: 'psi or lbf/in<sup>2</sup>',
    name: 'CM_0069',
  },
  { value: PressureUnitsID.inH2O, label: 'inH<sub>2</sub>O', unit: 'inH<sub>2</sub>O', name: 'CM_0070' },
  { value: PressureUnitsID.ftH2O, label: 'ftH<sub>2</sub>O', unit: 'ftH<sub>2</sub>O', name: 'CM_0071' },
];
export interface IGasFlowUnit extends CommonUnit {
  type?: UnitType;
  tab?: GasFlowUnitTab;
}
export const GAS_FLOW_UNIT_LIST: IGasFlowUnit[] = [
  {
    value: FlowRateGasUnitsID.Nm3h,
    label: i18n.t('CM_0146') as string,
    unit: 'Nm<sup>3</sup>/h',
    name: 'CM_0146',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.NORMAL,
  },
  {
    value: FlowRateGasUnitsID.Nm3min,
    label: `${i18n.t('CM_0147')}`,
    unit: 'Nm<sup>3</sup>/min',
    name: 'CM_0147',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.NORMAL,
  },
  {
    value: FlowRateGasUnitsID.NLh,
    label: `${i18n.t('CM_0148')}`,
    unit: 'NL/h',
    name: 'CM_0148',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.NORMAL,
  },
  {
    value: FlowRateGasUnitsID.NLmin,
    label: `${i18n.t('CM_0149')}`,
    unit: 'NL/min',
    name: 'CM_0149',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.NORMAL,
  },
  {
    value: FlowRateGasUnitsID.m3h,
    label: `${i18n.t('CM_0150')}`,
    unit: 'm<sup>3</sup>/h',
    name: 'CM_0150',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.ACTUAL,
  },
  {
    value: FlowRateGasUnitsID.m3min,
    label: `${i18n.t('CM_0151')}`,
    unit: 'm<sup>3</sup>/min',
    name: 'CM_0151',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.ACTUAL,
  },
  {
    value: FlowRateGasUnitsID.Lh,
    label: `${i18n.t('CM_0152')}`,
    unit: 'L/h',
    name: 'CM_0152',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.ACTUAL,
  },
  {
    value: FlowRateGasUnitsID.Lmin,
    label: `${i18n.t('CM_0153')}`,
    unit: 'L/min',
    name: 'CM_0153',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.ACTUAL,
  },

  {
    value: FlowRateGasUnitsID.Sm3h,
    label: `${i18n.t('CM_0154')}`,
    unit: 'Sm<sup>3</sup>/h',
    name: 'CM_0154',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.STANDARD,
  },
  {
    value: FlowRateGasUnitsID.SCFH,
    label: `${i18n.t('CM_0155')}`,
    unit: 'SCFH',
    name: 'CM_0155',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.STANDARD,
  },
  {
    unit: 'kg/h',
    name: 'CM_0184',
    value: FlowRateGasUnitsID.kgh,
    label: `${i18n.t('CM_0184')}`,
    tab: GasFlowUnitTab.MASS,
  },
  {
    unit: 't/h',
    name: 'CM_0185',
    value: FlowRateGasUnitsID.th,
    label: `${i18n.t('CM_0185')}`,
    tab: GasFlowUnitTab.MASS,
  },
  {
    unit: 'lb/h',
    name: 'CM_0186',
    value: FlowRateGasUnitsID.lbh,
    label: `${i18n.t('CM_0186')}`,
    tab: GasFlowUnitTab.MASS,
  },
];
export const LIQUID_FLOW_UNITS: { volume: CommonUnit[]; mass: CommonUnit[] } = {
  volume: [
    {
      unit: `${i18n.t('CM_0150')}`,
      name: 'CM_0150',
      value: FlowRateLiquidUnitsID.m3h,
      label: 'm<sup>3</sup>/h',
      type: 'CM_0021',
    },
    {
      unit: `${i18n.t('CM_0151')}`,
      name: 'CM_0151',
      value: FlowRateLiquidUnitsID.m3min,
      label: 'm<sup>3</sup>/min',
      type: 'CM_0021',
    },
    {
      unit: `${i18n.t('CM_0152')}`,
      name: 'CM_0152',
      value: FlowRateLiquidUnitsID.Lh,
      label: 'L/h',
      type: 'CM_0021',
    },
    {
      unit: `${i18n.t('CM_0153')}`,
      name: 'CM_0153',
      value: FlowRateLiquidUnitsID.Lmin,
      label: 'L/min',
      type: 'CM_0021',
    },
    {
      unit: `${i18n.t('CM_0187')}`,
      name: 'CM_0187',
      value: FlowRateLiquidUnitsID.Usgalmin,
      label: 'US gal/min',
      type: 'CM_0021',
    },
  ],
  mass: [
    {
      unit: `${i18n.t('CM_0184')}`,
      name: 'CM_0184',
      value: FlowRateLiquidUnitsID.kgh,
      label: 'kg/h',
      type: 'CM_0022',
    },
    {
      unit: `${i18n.t('CM_0185')}`,
      name: 'CM_0185',
      value: FlowRateLiquidUnitsID.th,
      label: 't/h',
      type: 'CM_0022',
    },
    {
      unit: `${i18n.t('CM_0186')}`,
      name: 'CM_0186',
      value: FlowRateLiquidUnitsID.lbh,
      label: 'lb/h',
      type: 'CM_0022',
    },
  ],
};
export enum UnitValue {
  Gauge = 'G',
  Absolute = 'A',
  Mpa = '01',
  kPa = '02',
  Pa = '03',
  bar = '04',
  kgfcm2 = '05',
  mmHgTorr = '06',
  mmH2OmmAq = '07',
  psilbfin2 = '08',
  inH2O = '09',
  ftH2O = '10',
  m3h = 'A01',
  m3min = 'A02',
  Lh = 'A03',
  Lmin = 'A04',
  Usgalmin = 'A05',
  kgh = '06',
  th = '07',
  lbh = '08',
  Nm3h = 'S01',
  Nm3min = 'S02',
  NLh = 'S03',
  NLmin = 'S04',
  Sm3h = 'S05',
  SCFH = 'S06',
  Celsius = '01',
  Kevil = '02',
  Fahrenheit = '03',
  Rankine = '04',
}
