import React, { CSSProperties } from 'react';
import './index.scss';
import { Typography } from 'antd';
const { Paragraph } = Typography;

interface IHandleOverFloatText {
  text: string;
  style?: CSSProperties | undefined;
}
// eslint-disable-next-line max-lines-per-function
export const HandleOverFloatText = (props: IHandleOverFloatText): JSX.Element => {
  return (
    <div>
      <Paragraph className="typo" ellipsis={{ rows: 2, tooltip: `${props.text}` }}>
        <p id="line" style={props.style || undefined}>
          {props.text}
        </p>
      </Paragraph>
    </div>
  );
};
