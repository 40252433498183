/**
 * Calculate steam flow rate page
 *
 * Created: 2022/7/1
 * __author__: DienPD1
 * __copyright__:
 * __version__: 1.0
 */
import ConvertArrow from '../../assets/locales/picture/convert-arrow.svg';
import chart_ja from '../../assets/locales/picture/default_chart.png';
import chart_en from '../../assets/locales/picture/default_chart_en.png';
import { CustomInput } from '../../components/CustomInput/CustomInput';
import PageHeading from '../../components/Heading/PageHeading';
import { getDefaultRule } from '../../constants/rules';
import { debounceFormValueChange } from '../../helpers/debounce';
import { DOT_STRING, EMPTY_STRING, isNumeric } from '../../helpers/utils';
import { useCustomForm } from '../../hooks/useCustomForm';
import useWindowSize from '../../hooks/useWindowResize';
import i18n from '../../i18n';
import Header from '../../layouts/Header';
import PageContainer from '../../layouts/PageContainer';
import { AxiosResponseData } from '../../services/http-client';
import { getPressureFromTemperature, getTemperatureFromPressure } from '../../services/linearInterpolation';
import './index.scss';
import { getCompareEqualRuleToOtherField, getDigitRule } from './validateRules';
import { Scatter, ScatterConfig } from '@ant-design/plots';
import { Button, Col, Form, Row } from 'antd';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IPointLine {
  key: string;
  temperature: number;
  pressure: number;
}

const CALC_PRESS_MSG = {
  Default: '',
  Err: i18n.t('CM_0199'),
};

const CALC_TEMP_MSG = {
  Default: '',
  Err: i18n.t('CM_0200'),
};

const DEFAULT_VALUE = {
  Digit1: '2',
  Digit2: '1',
};

/**
 * Calculate steam flow rate UI
 *
 * Created: 2022/17/12
 *  @author DienPD1
 */
// eslint-disable-next-line max-lines-per-function, complexity
export default function LinearInterpolation(): JSX.Element {
  const { t } = useTranslation();
  const [form] = useCustomForm(Form.useForm());
  const validateFields = ['t1', 'p1', 't2', 'p2', 'tx', 'py', 'digit1', 'digit2'];
  const [errorMessage, setErrorMessage] = useState<string>();
  const [calculatedPressure, setCalculatedPressure] = useState<string>('');
  const [calcPressureErrorMessage, setCalcPressureErrorMessage] = useState<string>(CALC_PRESS_MSG.Default);
  const [calculatedTemperature, setCalculatedTemperature] = useState<string>('');
  const [calcTemperatureErrorMessage, setCalcTemperatureErrorMessage] = useState<string>(CALC_TEMP_MSG.Default);
  const [chartReady, setChartReady] = useState<boolean>(false);
  const { width, height } = useWindowSize();
  const defaultChartData: IPointLine[] = [];
  const chartData = useRef<IPointLine[]>(defaultChartData);
  const [isCannotCalc, setCannotCalc] = useState<boolean>(false);
  const [isTrue, setIsTrue] = useState<boolean>(false);
  const chartDefaultConfig: ScatterConfig = {
    data: chartData.current,
    renderer: 'svg',
    xField: 'temperature',
    yField: 'pressure',
    appendPadding: 16,
    height: 316,
    label: {
      content: ({ temperature, pressure, key }) => {
        const digit1 = form.getFieldValue('digit1');
        if (key === 'point-3' && isNumeric(digit1)) {
          const floorDownNumber = Math.pow(10, digit1);
          return `(${temperature}, ${(Math.floor(Number(pressure) * floorDownNumber) / floorDownNumber).toFixed(
            digit1,
          )})`;
        }
        const digit2 = form.getFieldValue('digit2');
        if (key === 'point-4') {
          const floorDownNumber = Math.pow(10, digit2);
          return `(${(Math.floor(Number(temperature) * floorDownNumber) / floorDownNumber).toFixed(
            digit2,
          )}, ${pressure})`;
        }
        return `(${temperature}, ${pressure})`;
      },
    },
    xAxis: {
      tickCount: 5,
      title: {
        text: `${t('CM_0159')}[℃]`,
      },
      grid: {
        line: {
          style: {
            stroke: '#BFBFBF',
            width: 1,
          },
        },
      },
    },
    yAxis: {
      tickCount: 5,
      title: {
        text: `${t('CM_0170')}[MPa]`,
      },
      line: {
        style: {
          stroke: '#BFBFBF',
          width: 1,
        },
      },
      grid: {
        line: {
          style: {
            stroke: '#BFBFBF',
            width: 1,
          },
        },
      },
    },
    tooltip: {
      showTitle: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, max-lines-per-function
      customContent(title, data: any[]) {
        if (data.length <= 0) {
          return;
        }
        let content = '';
        if (data[0].data) {
          const itemData = data[0].data;
          let t = itemData.temperature;
          let p = itemData.pressure;
          switch (itemData.key) {
            case 'point-1':
              content = `Series "PTレーティング" Point "${t}" (${t}, ${p})`;
              break;
            case 'point-2':
              content = 'Leader Lines PTレーティング';
              break;
            case 'point-3':
              t = fixedNumberByDigitKey('digit1', t);
              p = fixedNumberByDigitKey('digit1', p);
              content = `Series "x => y" Point "${p}" (${t}, ${p})`;
              break;
            case 'point-4':
              t = fixedNumberByDigitKey('digit2', t);
              p = fixedNumberByDigitKey('digit2', p);
              content = `Series "y => x" Point "${t}" (${t}, ${p})`;
              break;
            default:
              break;
          }
        }
        return content;
      },
    },
    pointStyle: (point: IPointLine) => {
      const color = {
        stroke: '#777777',
        lineWidth: 1,
        fill: '#5B8FF9',
      };
      if (point.key === 'point-4') {
        color.fill = '#a5a5a5';
      } else if (point.key === 'point-3') {
        color.fill = '#ed7d31';
      }
      return color;
    },
    regressionLine: {
      type: 'linear',
    },
    colorField: 'key',
    legend: false,
    quadrant: {
      xBaseline: 0,
      yBaseline: 0,
    },
    onReady() {
      setChartReady(true);
    },
  };
  const [chartConfig, setChartConfig] = useState<ScatterConfig>(chartDefaultConfig);

  /**
   * Handle form values change
   *
   * Created: 2022/15/12
   *  @author DienPD1
   */
  // eslint-disable-next-line max-lines-per-function
  const onFormValuesChange = (): void => {
    if (
      form.getFormEmtyOrError(['py', 'tx']).isAllFieldsEmpty ||
      form.getFormEmtyOrError(['t1', 'p1', 't2', 'p2']).isOneFieldEmpty
    ) {
      setIsTrue(false);
    }

    if (isTwoPointEmpty()) {
      clearErrorMessage();
      setCannotCalc(true);
      return;
    }
    setCannotCalc(false);
    form
      .validateFields(validateFields)
      // eslint-disable-next-line max-lines-per-function, complexity
      .then((value) => {
        const t1 = form.getFieldValue('t1');
        const t2 = form.getFieldValue('t2');
        const p1 = form.getFieldValue('p1');
        const p2 = form.getFieldValue('p2');

        const newData: IPointLine[] = [
          {
            key: 'point-1',
            temperature: Number(t1),
            pressure: Number(p1),
          },
          {
            key: 'point-2',
            temperature: Number(t2),
            pressure: Number(p2),
          },
        ];
        const p3 = chartData.current.find((item) => item.key === 'point-3');
        const p4 = chartData.current.find((item) => item.key === 'point-4');
        if (value.tx && !isInRangeXY(t1, t2, value.tx)) {
          setCalcPressureErrorMessage(CALC_PRESS_MSG.Err);
          setCalculatedPressure('');
        }
        if (value.py && !isInRangeXY(p1, p2, value.py)) {
          setCalcTemperatureErrorMessage(CALC_TEMP_MSG.Err);
          setCalculatedTemperature('');
        }
        if (p3 || value.tx) {
          getPressure();
        }
        if (p4 || value.py) {
          getTemperature();
        }
        setErrorMessage('');
        updateChartData(newData);
      })
      // eslint-disable-next-line max-lines-per-function
      .catch(() => {
        const errFields = form.getFieldsError().filter((f) => f.errors.length > 0);
        if (errFields.length > 0) {
          // eslint-disable-next-line max-lines-per-function
          errFields.forEach((f) => {
            const errName = String(f.name[0]);
            if (['t1', 't2'].includes(errName)) {
              setCalculatedPressure('');
            }
            if (['p1', 'p2'].includes(errName)) {
              setCalculatedTemperature('');
            }
            if (f.name.includes('tx')) {
              setCalcPressureErrorMessage(CALC_PRESS_MSG.Err);
            }
            if (f.name.includes('py')) {
              setCalcTemperatureErrorMessage(CALC_TEMP_MSG.Err);
            }
            if (f.name.includes('digit1')) {
              setCalculatedPressure('');
              const pIdx = chartData.current.findIndex((p) => p.key === 'point-3');
              if (pIdx !== -1) {
                chartData.current.splice(pIdx, 1);
                setChartConfig({ ...chartConfig, data: chartData.current });
              }
            }
            if (f.name.includes('digit2')) {
              setCalculatedTemperature('');
              const pIdx = chartData.current.findIndex((p) => p.key === 'point-4');
              if (pIdx !== -1) {
                chartData.current.splice(pIdx, 1);
                setChartConfig({ ...chartConfig, data: chartData.current });
              }
            }
          });
        }
        setErrorMessage(form.customError[0]?.msg);
        setIsTrue(false);
      });
  };

  const clearErrorMessage = (): void => {
    setErrorMessage('');
    setCalcPressureErrorMessage('');
    setCalcTemperatureErrorMessage('');
  };

  const isTwoPointEmpty = (): boolean => {
    return ['t1', 'p1', 't2', 'p2'].some((fieldName) => !form.getFieldValue(fieldName));
  };

  // eslint-disable-next-line max-lines-per-function
  const getPressure = async (): Promise<void> => {
    try {
      const response = await getPressureFromTemperature({
        X: Number(form.getFieldValue('tx')),
        X1: Number(form.getFieldValue('t1')),
        Y2: Number(form.getFieldValue('p1')),
        X2: Number(form.getFieldValue('t2')),
        Y1: Number(form.getFieldValue('p2')),
      });
      const data = response.data as AxiosResponseData;
      if (data.customError) {
        setCalculatedPressure('');
        return;
      }
      const p3 = Number(`${data.data?.value}`);
      const t3 = Number(form.getFieldValue('tx'));
      const point3Index = chartData.current.findIndex((p) => p.key === 'point-3');
      if (point3Index !== -1) {
        chartData.current[point3Index].pressure = p3;
        chartData.current[point3Index].temperature = t3;
      } else {
        chartData.current = [...chartData.current, { key: 'point-3', temperature: t3, pressure: p3 }];
      }
      setCalculatedPressure(fixedNumberByDigitKey('digit1', `${data.data?.value}`));
      updateChartData(chartData.current);
      setCalcPressureErrorMessage('');
      form.getFieldError('py').length < 1 ? setIsTrue(true) : setIsTrue(false);
    } catch (error) {
      console.log(error);
      setIsTrue(false);
    }
  };

  // eslint-disable-next-line max-lines-per-function
  const getTemperature = async (): Promise<void> => {
    try {
      const response = await getTemperatureFromPressure({
        Y: Number(form.getFieldValue('py')),
        X1: Number(form.getFieldValue('t1')),
        Y2: Number(form.getFieldValue('p1')),
        X2: Number(form.getFieldValue('t2')),
        Y1: Number(form.getFieldValue('p2')),
      });
      const data = response.data as AxiosResponseData;
      if (data.customError) {
        setCalculatedTemperature('');
        return;
      }
      const t4 = Number(data.data?.value);
      const p4 = Number(form.getFieldValue('py'));
      const point4Index = chartData.current.findIndex((p) => p.key === 'point-4');
      if (point4Index !== -1) {
        chartData.current[point4Index].pressure = p4;
        chartData.current[point4Index].temperature = t4;
      } else {
        chartData.current = [...chartData.current, { key: 'point-4', temperature: t4, pressure: p4 }];
      }
      setCalculatedTemperature(fixedNumberByDigitKey('digit2', `${data.data?.value}`));
      updateChartData(chartData.current);
      setCalcTemperatureErrorMessage('');
      form.getFieldError('tx').length < 1 ? setIsTrue(true) : setIsTrue(false);
    } catch (error) {
      console.log(error);
    }
  };

  const resetForms = (): void => {
    form.resetFields();
    updateChartData(defaultChartData);
    chartData.current = [];
    setCalculatedPressure('');
    setCalcPressureErrorMessage('');
    setCalculatedTemperature('');
    setCalcTemperatureErrorMessage('');
    setErrorMessage('');
  };

  const updateChartData = (data: IPointLine[]): void => {
    data.sort((a, b) => Number(a.temperature) - Number(b.temperature));
    chartData.current = data;
    updateChartConfig();
  };

  const updateChartConfig = (): void => {
    const listPressure = chartData.current.map((i) => Number(i.pressure));
    const listTemperature = chartData.current.map((i) => Number(i.temperature));
    const isPressureNegative = listPressure.some((n) => n < 0);
    const isAllPressureNegative = listPressure.every((n) => n < 0);
    const isTemperatureNegative = listTemperature.some((n) => n < 0);
    const isAllTemperatureNegative = listTemperature.every((n) => n < 0);
    const maxY = Math.max(...listPressure);
    const minY = Math.min(...listPressure);
    const maxX = Math.max(...listTemperature);
    const minX = Math.min(...listTemperature);

    const xAxis = {
      ...chartConfig.xAxis,
      max: maxX + Math.ceil(Math.abs(maxX / 4)),
      min: minX - Math.ceil(Math.abs(minX / 4)),
      minLimit: !isTemperatureNegative ? 0 : undefined,
      maxLimit: isAllTemperatureNegative ? 0 : undefined,
    };
    const yAxis = {
      ...chartConfig.yAxis,
      max: maxY + Math.ceil(Math.abs(maxY / 4)),
      min: minY - Math.ceil(Math.abs(minY / 4)),
      minLimit: !isPressureNegative ? 0 : undefined,
      maxLimit: isAllPressureNegative ? 0 : undefined,
    };
    setChartConfig({ ...chartConfig, data: chartData.current, xAxis, yAxis });
  };

  const fixedNumberByDigitKey = (digitKey: string, value: string): string => {
    if (!value || !isNumeric(value)) {
      return '';
    }
    const digitValue = form.getFieldValue(digitKey);
    if (!digitValue) {
      return value;
    }
    const digit = isNumeric(digitValue) ? Number(digitValue) : -1;
    const floorDownNumber = Math.pow(10, digit);
    if (Number(value) < 0) {
      const result = value.split(DOT_STRING);
      if (result.length > 1 && digit >= 0) {
        return result[0] + DOT_STRING + result[1].slice(0, digit);
      }
      return result[0];
    }
    return digit >= 0 ? (Math.floor(Number(value) * floorDownNumber) / floorDownNumber).toFixed(digit) : '0';
  };

  const isInRangeXY = (val1: string, val2: string, value: string): boolean => {
    if (!isNumeric(val1) || !isNumeric(val2) || !isNumeric(value)) {
      return false;
    } else {
      if (
        (Number(val1) <= Number(value) && Number(value) <= Number(val2)) ||
        (Number(val2) <= Number(value) && Number(value) <= Number(val1))
      ) {
        return true;
      }
    }
    return false;
  };

  const onTxChange = (value: string): void => {
    if (!value) {
      setCalcPressureErrorMessage(CALC_PRESS_MSG.Default);
      return;
    }
    const val1 = form.getFieldValue('t1');
    const val2 = form.getFieldValue('t2');
    if (isInRangeXY(val1, val2, value)) {
      setCalcPressureErrorMessage(CALC_PRESS_MSG.Default);
    } else {
      setCalculatedPressure('');
      setCalcPressureErrorMessage(CALC_PRESS_MSG.Err);
    }
    if (!form.customError || form.customError[0].inputName !== 'digit1') {
      getPressure();
    }
  };

  const onPyChange = (value: string): void => {
    if (!value) {
      setCalcTemperatureErrorMessage(CALC_TEMP_MSG.Default);
      return;
    }
    const val1 = form.getFieldValue('p1');
    const val2 = form.getFieldValue('p2');
    if (isInRangeXY(val1, val2, value)) {
      setCalcTemperatureErrorMessage(CALC_TEMP_MSG.Default);
    } else {
      setCalculatedTemperature('');
      setCalcTemperatureErrorMessage(CALC_TEMP_MSG.Err);
    }
    if (!form.customError || form.customError[0].inputName !== 'digit2') {
      getTemperature();
    }
  };

  const roundingAxis = (element: SVGTextElement): void => {
    const content = element.textContent;
    if (content === '0') return;
    if (Number(content) % 1 !== 0) {
      element.textContent = Number(element.textContent).toFixed(1);
    }
  };

  // eslint-disable-next-line max-lines-per-function
  const stickTickNumbersToOriginPoint = (): void => {
    const listPressure = chartData.current.map((i) => Number(i.pressure));
    const listTemperature = chartData.current.map((i) => Number(i.temperature));
    const isIncludingNegative = [...listPressure, ...listTemperature].some((n) => n < 0);
    const axisLines = document.querySelectorAll('#-axis-line');
    const xAxis = axisLines[0] as SVGElement;
    const yAxis = axisLines[1] as SVGElement;
    if (xAxis && yAxis) {
      const xAxisNums = xAxis.nextSibling as SVGElement;
      const yAxisNums = yAxis.nextSibling as SVGElement;
      const tickNums_X_Axis = xAxisNums.getElementsByTagName('text');
      const tickNums_Y_Axis = yAxisNums.getElementsByTagName('text');
      if (isIncludingNegative) {
        const isHavingXCoorAt0 = Array.from(tickNums_X_Axis).findIndex((coor) => coor.textContent?.toString() === '0');
        const isHavingYCoorAt0 = Array.from(tickNums_Y_Axis).findIndex((coor) => coor.textContent?.toString() === '0');
        if (tickNums_X_Axis.length - 1 > 0 && isHavingXCoorAt0 > 0) {
          const xAxisWidth = xAxis.getBoundingClientRect().width;
          const translateX = (xAxisWidth / (tickNums_X_Axis.length - 1)) * isHavingXCoorAt0;
          const translateY = 0;
          yAxisNums.setAttribute('transform', `translate(${translateX} ${translateY})`);
        }
        if (tickNums_Y_Axis.length - 1 > 0 && isHavingYCoorAt0 > 0) {
          const yAxisWidth = yAxis.getBoundingClientRect().height;
          const translateX = 0;
          const translateY = (yAxisWidth / (tickNums_Y_Axis.length - 1)) * isHavingYCoorAt0;
          xAxisNums.setAttribute(
            'transform',
            `translate(${translateX} ${translateY > 0 ? translateY * -1 : translateY})`,
          );
        }
      }
      Array.from(tickNums_X_Axis).forEach((svgEl) => roundingAxis(svgEl));
      Array.from(tickNums_Y_Axis).forEach((svgEl) => roundingAxis(svgEl));
    }
  };

  useEffect(() => {
    if (chartReady && chartData.current.length > 0) {
      stickTickNumbersToOriginPoint();
    }
  }, [chartConfig, chartReady]);

  useEffect(() => {
    setTimeout(() => stickTickNumbersToOriginPoint(), 500);
  }, [width, height]);

  const defaultChart = i18n.language === 'en' ? chart_en : chart_ja;
  const lineChart = useMemo(() => <Scatter {...chartConfig} />, [chartConfig]);

  const pageContent = (
    <>
      {/* <Header /> */}
      <div className="linear-interpolation-container">
        <PageHeading borderColor="pt">{t('CM_0119')}</PageHeading>
        <div className="page-info">
          <Row>
            <Col>
              <div className="info">{t('CM_0120')}</div>
              <div className="info-note">{t('PT_0005')}</div>
            </Col>
          </Row>
        </div>
        <Form
          className={`${i18n.language}`}
          form={form}
          colon={false}
          onValuesChange={debounceFormValueChange(onFormValuesChange)}
          initialValues={{ digit1: DEFAULT_VALUE.Digit1, digit2: DEFAULT_VALUE.Digit2 }}
        >
          <div id="chart1" className="input-chart-wrapper flex flex-row">
            <div className={`chart ${!isTwoPointEmpty() ? '' : 'img-default'}`}>
              {!isTwoPointEmpty() ? (
                lineChart
              ) : (
                <img
                  src={defaultChart}
                  onContextMenu={(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => e.preventDefault()}
                />
              )}
            </div>
            <div className="input-leader-points">
              {/* <div className="page-info"> */}
              <Button type="primary" onClick={resetForms}>
                {t('CM_0121')}
              </Button>
              {/* </div> */}
              <div className="title-container">
                <div className="title">{t('CM_0122')}</div>
                <div className="error-message">{errorMessage}</div>
              </div>

              <div className="point">
                {/* <div className="point-title">{t('CM_0123')}</div> */}
                <div className="input-form">
                  <Form.Item label={<label className="point-title">{t('CM_0123')}</label>}>
                    <Row gutter={[8, 16]}>
                      <Col>
                        <div className="input-with-unit">
                          <Form.Item
                            // label={<label className="point-title">{t('CM_0123')}</label>}
                            name="t1"
                            rules={getDefaultRule(t('CM_0197'), undefined, true)}
                            help=""
                          >
                            <Row gutter={[8, 12]}>
                              <Col className="point-label">
                                <label className="input-label point-1">{t('CM_0124')}</label>
                              </Col>
                              <Col className="col-md">
                                <CustomInput
                                  className="input-text"
                                  autoComplete="off"
                                  placeholder={t('CM_0129') || ''}
                                  isScrollToChart={isTrue}
                                />
                              </Col>
                              <Col className="lay-mb">
                                <span className="unit">℃</span>
                              </Col>
                            </Row>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col>
                        <div className="input-with-unit">
                          <Form.Item
                            // label={<label className="point-title">{t('CM_0123')}</label>}
                            name="p1"
                            rules={getDefaultRule(t(`${t('CM_0194')}`), undefined, true)}
                            help=""
                          >
                            <Row gutter={[8, 12]}>
                              <Col>
                                <label className="input-label point-1">{t('CM_0125')}</label>
                              </Col>
                              <Col className="col-md">
                                <CustomInput
                                  className="input-text"
                                  autoComplete="off"
                                  placeholder={t('CM_0129') || ''}
                                  isScrollToChart={isTrue}
                                />
                              </Col>
                              <Col className="layout-mb">
                                <span className="unit">MPa</span>
                              </Col>
                            </Row>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Form.Item>
                </div>
              </div>
              <hr className="my-4"></hr>
              <div className="point">
                {/* <div className="point-title">{t('CM_0126')}</div> */}
                <div className="input-form">
                  <Form.Item label={<label className="point-title">{t('CM_0126')}</label>}>
                    <Row gutter={[8, 16]}>
                      <Col>
                        <div className="input-with-unit">
                          <Form.Item
                            className="a"
                            name="t2"
                            rules={[
                              ...getDefaultRule(t('CM_0198'), undefined, true),
                              getCompareEqualRuleToOtherField('t1'),
                            ]}
                            help=""
                          >
                            <Row gutter={[8, 12]}>
                              <Col>
                                <label className="input-label point-2">{t('CM_0157')}</label>
                              </Col>
                              <Col className="col-md">
                                <CustomInput
                                  className="input-text"
                                  autoComplete="off"
                                  placeholder={t('CM_0129') || ''}
                                  isScrollToChart={isTrue}
                                />
                              </Col>
                              <Col className="layout-mb">
                                <span className="unit">℃</span>
                              </Col>
                            </Row>
                          </Form.Item>
                        </div>
                      </Col>

                      <Col>
                        <div className="input-with-unit">
                          <Form.Item
                            name="p2"
                            rules={[
                              ...getDefaultRule(t(`${t('CM_0195')}`), undefined, true),
                              getCompareEqualRuleToOtherField('p1'),
                            ]}
                            help=""
                          >
                            <Row gutter={[8, 12]}>
                              <Col>
                                <label className="input-label point-2">{t('CM_0158')}</label>
                              </Col>
                              <Col className="col-md">
                                <CustomInput
                                  className="input-text"
                                  autoComplete="off"
                                  placeholder={t('CM_0129') || ''}
                                  isScrollToChart={isTrue}
                                />
                              </Col>
                              <Col className="layout-mb">
                                <span className="unit">MPa</span>
                              </Col>
                            </Row>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="calc-wrapper">
            <div className="title">{t('CM_0127')}</div>
            {/* <div className="info-wrapper">
              <div>
                <Form.Item>
                  <Row className="formula-row-1">
                    <Col>
                      <label className="point-title formula-title">{t('CM_0128')}</label>
                    </Col>
                    <Col>
                      <div className="formula">
                        y = y1 + (y2-y1) x
                        <div>
                          <div>(x2-x)</div>
                          <div>(x2-x1)</div>
                        </div>
                      </div>
                    </Col>
                    <Col className="point-container">
                      <Form.Item
                        name="digit1"
                        label={<label className="point-title">{t('CM_0130')}</label>}
                        rules={[getDigitRule('tx', { allowedBlank: true })]}
                        help=""
                      >
                        <Row>
                          <Col>
                            <CustomInput
                              className="col-md"
                              autoComplete="off"
                              placeholder={`${t('CM_0129')}`}
                              defaultValue={DEFAULT_VALUE.Digit1}
                            />
                          </Col>
                          <Col>
                            <div>{t('CM_0131')}</div>
                          </Col>
                        </Row>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </div>
              <div className="err-container">
                <Row>
                  <Col>
                    <label>{t('CM_0132')}</label>
                  </Col>
                  <Col>
                    <div className="error-message">{calcPressureErrorMessage}</div>
                  </Col>
                </Row>
              </div>
            </div> */}
            <div className="err-input-container">
              <div style={{ width: '100%' }}>
                <div className="err-container">
                  <Row>
                    <Col>
                      <label>{t('PT_0001')}</label>
                    </Col>
                    <Col>
                      <div className="error-message ">{calcPressureErrorMessage}</div>
                    </Col>
                  </Row>
                </div>
                <div className="input-wrapper">
                  <div className="flex input-with-unit">
                    <Form.Item
                      label={<label className="input-label point-3">{`${t('CM_0133')}`}</label>}
                      name="tx"
                      rules={getDefaultRule(t('CM_0133'), undefined, true)}
                    >
                      <Row gutter={8}>
                        <Col className="layout-mb">
                          <CustomInput
                            className="input-text"
                            autoComplete="off"
                            placeholder={t('CM_0129') || ''}
                            onChange={debounce((e): void => onTxChange(e.target.value), 250)}
                            isScrollToChart={isTrue}
                          />
                        </Col>
                        <Col className="layout-mb">
                          <span className="unit leading-10">℃</span>
                        </Col>
                      </Row>
                    </Form.Item>
                  </div>
                  <div className="convert-icon">
                    <embed src={ConvertArrow} />
                  </div>

                  <div className="flex input-with-unit">
                    <Form.Item label={<label className="input-label point-3-convert">{`${t('CM_0134')}`}</label>}>
                      <Col>{/* <label>{t('CM_0132')}</label> */}</Col>
                      <Row>
                        <Col>{/* <label>{t('CM_0132')}</label> */}</Col>
                        <Col className="layout-mb">
                          <CustomInput
                            value={isCannotCalc ? EMPTY_STRING : calculatedPressure}
                            className="input-text disable-field"
                            disabled
                            bordered={false}
                            isResultField
                          />
                        </Col>
                        <Col className="layout-mb">
                          <span className="unit" style={{ marginLeft: '8px' }}>
                            MPa
                          </span>
                        </Col>
                      </Row>
                    </Form.Item>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="err-container">
                  <Row>
                    <Col>
                      <label>{t('PT_0002')}</label>
                    </Col>
                    <Col>
                      <div className="error-message ">{calcTemperatureErrorMessage}</div>
                    </Col>
                  </Row>
                </div>
                <div className="input-wrapper">
                  <div className="flex input-with-unit">
                    <Form.Item
                      name="py"
                      rules={getDefaultRule(t('CM_0136'), undefined, true)}
                      label={<label className="input-label point-3">{`${t('CM_0136')}`}</label>}
                    >
                      <Row gutter={8}>
                        <Col className="layout-mb">
                          <CustomInput
                            className="input-text"
                            autoComplete="off"
                            placeholder={t('CM_0129') || ''}
                            onChange={debounce((e): void => onPyChange(e.target.value), 250)}
                            isScrollToChart={isTrue}
                          />
                        </Col>
                        <Col className="layout-mb">
                          <span className="unit leading-10">MPa</span>
                        </Col>
                      </Row>
                    </Form.Item>
                  </div>
                  <div className="convert-icon">
                    <embed src={ConvertArrow} />
                  </div>
                  <div className="flex input-with-unit" style={{ marginRight: '11px' }}>
                    <Form.Item label={<label className="input-label point-3-convert">{`${t('CM_0137')}`}</label>}>
                      <Col>{/* <label>{t('CM_0132')}</label> */}</Col>
                      <Row gutter={8}>
                        <Col className="layout-mb">
                          <CustomInput
                            value={isCannotCalc ? EMPTY_STRING : calculatedTemperature}
                            className="input-text disable-field"
                            disabled
                            bordered={false}
                            isResultField
                          />
                        </Col>
                        <Col className="layout-mb">
                          <span className="unit">℃</span>
                        </Col>
                      </Row>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-4" />
            <div className="flex" style={{ justifyContent: 'space-between' }}>
              <div className="info-wrapper">
                <div>
                  <Form.Item>
                    <Row className="formula-row-1">
                      <Col>
                        <label className="point-title formula-title">{t('CM_0128')}</label>
                        <div className="formula">
                          <div style={{ marginRight: '4px' }}>y = y1 + (y2-y1) x</div>
                          <div>
                            <div>(x2-x)</div>
                            <div>(x2-x1)</div>
                          </div>
                        </div>
                      </Col>
                      {/* <Col>
                      <div className="formula">
                        y = y1 + (y2-y1) x
                        <div>
                          <div>(x2-x)</div>
                          <div>(x2-x1)</div>
                        </div>
                      </div>
                    </Col> */}
                      <Col className="point-container">
                        <Form.Item
                          name="digit1"
                          label={<label className="point-title">{t('CM_0130')}</label>}
                          rules={[getDigitRule('tx', { allowedBlank: true })]}
                          help=""
                        >
                          <Row>
                            <Col>
                              <CustomInput
                                className="col-md"
                                autoComplete="off"
                                placeholder={`${t('CM_0129')}`}
                                defaultValue={DEFAULT_VALUE.Digit1}
                              />
                            </Col>
                            <Col>
                              <div>{t('CM_0131')}</div>
                            </Col>
                          </Row>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                </div>
                {/* <div className="err-container">
                <Row>
                  <Col>
                    <label>{t('CM_0132')}</label>
                  </Col>
                  <Col>
                    <div className="error-message">{calcPressureErrorMessage}</div>
                  </Col>
                </Row>
              </div> */}
              </div>

              <div className="info-wrapper">
                <div>
                  <Form.Item>
                    <Row className="formula-row-1">
                      <Col>
                        <label className="point-title formula-title">{t('CM_0135')}</label>
                        <div className="formula">
                          <div style={{ marginRight: '4px' }}>x = x1 + (x2-x1) x</div>
                          <div>
                            <div>(y2-y)</div>
                            <div>(y2-y1)</div>
                          </div>
                        </div>
                      </Col>
                      {/* <Col>
                      <div className="formula">
                        x = x1 + (x2-x1) x
                        <div>
                          <div>(y2-y)</div>
                          <div>(y2-y1)</div>
                        </div>
                      </div>
                    </Col> */}
                      <Col className="point-container">
                        <Form.Item
                          label={<label className="point-title point-3">{t('CM_0130')}</label>}
                          name="digit2"
                          rules={[getDigitRule('py', { allowedBlank: true })]}
                          help=""
                        >
                          <Row>
                            <Col>
                              <CustomInput
                                className="col-md"
                                autoComplete="off"
                                placeholder={`${t('CM_0129')}`}
                                defaultValue={DEFAULT_VALUE.Digit2}
                              />
                            </Col>
                            <Col>
                              <div>{t('CM_0131')}</div>
                            </Col>
                          </Row>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                </div>
                {/* <div className="err-container">
                <Row>
                  <Col>
                    <label>{t('CM_0132')}</label>
                  </Col>
                  <Col>
                    <div className="error-message">{calcTemperatureErrorMessage}</div>
                  </Col>
                </Row>
              </div> */}
              </div>
            </div>
            {/* <div className="input-wrapper">
              <div className="flex input-with-unit">
                <Form.Item
                  name="py"
                  rules={getDefaultRule(t('CM_0136'), undefined, true)}
                  label={<label className="input-label point-3">{`${t('CM_0136')} (y)`}</label>}
                >
                  <Row gutter={8}>
                    <Col className="layout-mb">
                      <CustomInput
                        className="input-text"
                        autoComplete="off"
                        placeholder={t('CM_0129') || ''}
                        onChange={debounce((e): void => onPyChange(e.target.value), 250)}
                      />
                    </Col>
                    <Col className="layout-mb">
                      <span className="unit leading-10">MPa</span>
                    </Col>
                  </Row>
                </Form.Item>
              </div>
              <embed className="convert-icon" src={ConvertArrow} />
              <div className="flex input-with-unit" style={{ marginRight: '11px' }}>
                <Form.Item label={<label className="input-label point-3-convert">{`${t('CM_0137')} (x)`}</label>}>
                  <Col>{/* <label>{t('CM_0132')}</label> */}
            {/* </Col>
                  <Row gutter={8}>
                    <Col className="layout-mb">
                      <CustomInput
                        value={calculatedTemperature}
                        className="input-text disable-field"
                        disabled
                        bordered={false}
                        isResultField
                      />
                    </Col>
                    <Col className="layout-mb">
                      <span className="unit">℃</span>
                    </Col>
                  </Row>
                </Form.Item>
              </div>
            </div> */}
          </div>
        </Form>
      </div>
    </>
  );
  // function useWindowSize(): number | undefined {
  //   const [windowSize, setWindowSize] = useState<number>();
  //   useEffect(() => {
  //     function handleResize(): void {
  //       setWindowSize(window.innerWidth);
  //     }
  //     window.addEventListener('resize', handleResize);
  //     handleResize();
  //     return () => window.removeEventListener('resize', handleResize);
  //   }, []);
  //   return windowSize;
  // }
  return (
    <div>
      <Header />
      <PageContainer>{pageContent}</PageContainer>
    </div>
  );
}
