import { RadioChangeEvent } from 'antd';
import { FormInstance, Rule } from 'antd/es/form';
import { Store } from 'antd/es/form/interface';
import { DefaultOptionType } from 'antd/es/select';
import { DefaultTFuncReturn } from 'i18next';
import { ChangeEventHandler, FocusEventHandler } from 'react';

export interface IBaseFormBaseInput {
  name: string;
  value?: string;
  disabled?: boolean;
  className?: string;
  note?: JSX.Element;
}

export interface IBaseFormOption {
  value: string;
  label: string;
  key?: string;
}

export interface IBaseFormFieldConfig {
  label: string;
  className?: string;
}

export enum BaseFormInputType {
  Text = 'text',
  Select = 'select',
  RadioGroup = 'radio-group',
}

export interface IBaseFormTextInput extends IBaseFormBaseInput {
  type: BaseFormInputType.Text;
  placeholder?: string | DefaultTFuncReturn;
  bordered?: boolean;
  maxLength?: number;
  minLength?: number;
  autoComplete?: boolean;
  isResultField?: boolean;
  nameItem?: string;
  rules?: Rule[] | undefined;
  unit?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  isSpecialFormat?: boolean;
  limitMinValue?: number;
  limitMaxValue?: number;
  isIgnoreLimitConvert?: boolean;
  isRoundingString?: boolean;
}

export interface IBaseFormSelectInput extends IBaseFormBaseInput {
  type: BaseFormInputType.Select;
  suffixIcon?: JSX.Element;
  rules?: Rule[] | undefined;
  options: IBaseFormOption[];
  selectWidth?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any, option: DefaultOptionType | DefaultOptionType[]) => void;
}

export interface IBaseFormRadioGroup extends IBaseFormBaseInput {
  type: BaseFormInputType.RadioGroup;
  options: IBaseFormOption[];
  onChange?: (e: RadioChangeEvent) => void;
}

export type IBaseFormInput = IBaseFormTextInput | IBaseFormSelectInput | IBaseFormRadioGroup;

export interface IBaseFormField extends IBaseFormFieldConfig {
  inputs: IBaseFormInput[];
  note?: JSX.Element;
}

export interface IBaseFormOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>;
  note?: string | DefaultTFuncReturn;
  title?: string | DefaultTFuncReturn;
  fields: IBaseFormField[];
  className?: string;
  initialValues?: Store;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFormValuesChange?: ((changedValues: any, values: any) => void) | undefined;
  onFormBlur?: FocusEventHandler<HTMLFormElement>;
}
