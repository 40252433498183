/**
 * Apis are used for linear interpolation
 *
 * Created: 2022/25/12
 * __author__: DienPD1
 * __copyright__:
 * __version__: 1.0
 */
import { AxiosResponse } from 'axios';
import req from './http-client';
import { API_PATHS } from '../constants/apiPaths';

export interface IResponseData {
  [x: string]: number;
}

/**
 * Api get different pressure
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
export function getPressureFromTemperature(inputData: {
  X: number;
  X1: number;
  X2: number;
  Y1: number;
  Y2: number;
}): Promise<AxiosResponse<{ data: IResponseData }>> {
  return req.post(API_PATHS.CALC_PRESSURE_FROM_TEMPERATURE, inputData);
}

/**
 * Api get different pressure
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
export function getTemperatureFromPressure(inputData: {
  Y: number;
  X1: number;
  X2: number;
  Y1: number;
  Y2: number;
}): Promise<AxiosResponse<{ data: IResponseData }>> {
  return req.post(API_PATHS.CALC_TEMPERATURE_FROM_PRESSURE, inputData);
}
