export enum ValidateCodes {
  INVALID_ID = 1000,
  REQUIRED_PARAM = 1100,
  NON_REQUIRED_PARAM = 1101,
  MAX_LENGTH_DIGITS = 1200,
  REQUIRED_NUMERIC = 1300,
  REQUIRED_STRING = 1400,
  OUT_OF_RANGE = 1500,
  ABS_PRESSURE_LESS_THAN_ZERO = 1501,
  ABS_TEMPERATURE_LESS_THAN_ZERO = 1502,
  X_NOT_BETWEEN_X1_X2 = 1503,
  Y_NOT_BETWEEN_Y1_Y2 = 1504,
  PRESSURE_OUT_OF_RANGE = 1505,
  TEMPERATURE_EXCEEDS_800 = 1506,
  P1_LESS_OR_EQUAL_P2 = 1507,
  X1_EQUAL_X2 = 1508,
  Y1_EQUAL_Y2 = 1509,
  SUPERHEATED_LOWER_THAN_SATURATED = 1510,
  ABS_PRESSURE_LESS_OR_EQUAL_ZERO = 1511,
  ABS_TEMPERATURE_LESS_OR_EQUAL_ZERO = 1512,
}

export enum SpecialCodes {
  FLOW_RATE_TOO_HIGH = 2001,
  CRITICAL_STATE = 2002,
  ENCODE_DECODE_ERROR = 2003,
  UNEXPECTED_ERROR = 9999,
}

export const BE_ERROR_CODES = {
  VALIDATE: ValidateCodes,
  OTHERS: SpecialCodes,
};
