/**
 * Flow rate screen
 * @author AnhPV17
 *
 * @return
 */
import { useState, useEffect, ChangeEvent } from 'react';
import { Form, Row, Col } from 'antd';
import '../../styles/pages/FlowRate.scss';
import { useTranslation } from 'react-i18next';
import { getNegativeNumberRule } from '../../constants/rules';
import { LIQUID_FLOW_UNITS } from './constants';
import rounding from '../../helpers/utils';
import PageHeading from '../../components/Heading/PageHeading';
import { STATUS_CODE } from '../../constants/statusCode';
import { calcVelFromFlowRateLiq } from '../../services/flowToVelocity';
import { useCustomForm } from '../../hooks/useCustomForm';
import { CustomInput } from '../../components/CustomInput/CustomInput';
import PageContainer from '../../layouts/PageContainer';
import { BaseForm } from '../../components/BaseForm';
import { BaseFormInputType, IBaseFormOptions } from '../../components/BaseForm/Interface';
import '../../components/BaseForm/index.scss';
import { FlowRateLiquidUnitsID, VelocityUnitsID } from '../../constants/unitsID';
// eslint-disable-next-line max-lines-per-function
export const FlowRate = (): JSX.Element => {
  const { t } = useTranslation();
  const [inputForm] = useCustomForm(Form.useForm());
  const [errorMessage, setErrorMessage] = useState<string>();
  const [disableWaterPonderosity, setDisableWaterPonderosity] = useState<boolean>(true);
  const [dValue, setDChange] = useState<string>();
  const [qValue, setQChange] = useState<string>();
  const [GfValue, setGfChange] = useState<string>('');
  const [QUValue, setQUValue] = useState<string>(LIQUID_FLOW_UNITS.volume[0].value);
  const [msResult, setMsResult] = useState<number>();
  const [ftResult, setFtResult] = useState<number>();
  const validateFields = ['dValue', 'flow-rate-Q'];
  // const velocityMinValue = 0.0000001; //液体 流量から流速 (変更案) E133
  /**
   * create liquid Unit for Select options
   *
   * Created: 2022/29/12
   *  @author AnhPV17
   */
  const liquidUnits = [...LIQUID_FLOW_UNITS.volume, ...LIQUID_FLOW_UNITS.mass].map((item) => {
    return { name: item.name, value: item.value, label: `${t(item.type as string)} ${item.label}` };
  });
  /**
   * Handle form value change
   *
   * Created: 2022/23/12
   *  @author AnhPV17
   */
  const onFormValuesChange = (): void => {
    inputForm
      .validateFields(disableWaterPonderosity ? validateFields : validateFields.concat('gfValue'))
      .then(() => {
        setErrorMessage('');
        // getResult();
      })
      .catch(() => {
        setErrorMessage(inputForm.customError[0]?.msg);
      });
  };

  useEffect(() => {
    const fieldName = disableWaterPonderosity ? validateFields : validateFields.concat('gfValue');
    if (inputForm.getFormEmtyOrError(fieldName).isOneFieldEmpty) {
      setMsResult(undefined);
      setFtResult(undefined);
      return;
    }
    getResult();
  }, [dValue, qValue, QUValue, GfValue, getResult]);
  /**
   * Call api
   *
   * Created: 2022/23/12
   *  @author AnhPV17
   */
  async function getResult(): Promise<void> {
    const dataPost = {
      D: Number(dValue),
      Q: Number(qValue),
      Q_unit_in: QUValue,
    };
    const response = await calcVelFromFlowRateLiq(!GfValue ? dataPost : { ...dataPost, Gf: Number(GfValue) });

    if (response.code === STATUS_CODE.SUCCESS) {
      const result = response.data;
      setMsResult(rounding(result[VelocityUnitsID.ms]));
      setFtResult(rounding(result[VelocityUnitsID.fts]));
    }
  }

  useEffect(() => {
    if (
      QUValue === FlowRateLiquidUnitsID.kgh ||
      QUValue === FlowRateLiquidUnitsID.th ||
      QUValue === FlowRateLiquidUnitsID.lbh
    ) {
      setDisableWaterPonderosity(false);
      setMsResult(undefined);
      setFtResult(undefined);
    } else {
      setDisableWaterPonderosity(true);
      setMsResult(msResult);
      setFtResult(ftResult);
      setGfChange('');
    }
  }, [QUValue]);

  const formOption: IBaseFormOptions = {
    form: inputForm,
    onFormValuesChange: onFormValuesChange,
    className: 'fl-rate-container fr-liquid',
    fields: [
      {
        label: t('CM_0075'),
        className: 'fr-liquid',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'dValue',
            rules: getNegativeNumberRule(t('CM_0191'), '', false, 1, true),
            onChange: (e: ChangeEvent<HTMLInputElement>): void => setDChange(e.target.value),
            placeholder: t('CM_0129'),
            unit: 'mm',
          },
        ],
      },
      {
        label: t('CM_0002'),
        className: 'form-item',
        note: <>{t('CM_0163')}</>,
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'flow-rate-Q',
            onChange: (e: ChangeEvent<HTMLInputElement>): void => setQChange(e.target.value),
            rules: getNegativeNumberRule(t('CM_0160'), '', false, 3, true),
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,

            name: 'pressureClass',
            value: QUValue,
            options: liquidUnits,
            rules: getNegativeNumberRule(t('CM_0160')),
            onChange: (v: string): void => {
              setQUValue(v);
            },
          },
        ],
      },
      {
        label: t('CM_0008'),
        className: 'form-item gfValue',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'gfValue',
            rules: !disableWaterPonderosity ? getNegativeNumberRule(t('CM_0190'), '', false, 2, true) : [],
            disabled: disableWaterPonderosity,
            value: GfValue,
            onChange: (e: ChangeEvent<HTMLInputElement>): void => setGfChange(e.target.value),
            placeholder: disableWaterPonderosity ? '' : (t('CM_0129') as string),
            unit: `(${t('CM_0009')})`,
          },
        ],
      },
    ],
  };

  const inputContainer = (
    <>
      <div className="fl-rate-container liquid">
        <PageHeading className="pipe-diameter-title" borderColor="navy">
          {t('CM_0074')}
        </PageHeading>
        <BaseForm options={formOption}></BaseForm>
        <Form form={inputForm} onValuesChange={onFormValuesChange} colon={false}>
          <div className="input-container fr-liquid">
            <Form.Item className="result">
              <Row className="result-title">
                <Col>
                  <div className="title">{t('CM_0010')}</div>
                </Col>
                <Col>
                  <div className="error-message">{errorMessage}</div>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item className="result" label={<label className="title-result">{t('CM_0014')}</label>}>
              <Row className="result-row" gutter={[16, 16]}>
                <Col className="col-md">
                  <CustomInput
                    className="input-result"
                    disabled={true}
                    value={!errorMessage ? msResult?.toString() : (t('CM_0178') as string)}
                    isResultField={true}
                  />
                  <p className="unit">m/s</p>
                </Col>

                <Col className="col-md">
                  <CustomInput
                    className="input-result"
                    disabled={true}
                    value={!errorMessage ? ftResult?.toString() : (t('CM_0178') as string)}
                    isResultField={true}
                  />
                  <p className="unit">ft/s</p>
                </Col>
              </Row>
            </Form.Item>
            <p className="notice">{t('CM_0015')}</p>
          </div>
        </Form>
      </div>
    </>
  );
  return (
    <>
      <PageContainer>
        <>{inputContainer}</>
      </PageContainer>
    </>
  );
};
