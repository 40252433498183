/**
 * Calculate different pressure steam page
 *
 * Created: 2022/25/12
 * __author__: DienPD1
 * __copyright__:Copyright KITZ Inc. 2022 All Rights Reserved
 * __version__: 1.0
 */
import { useEffect, useRef, useState } from 'react';
import { Form, Row } from 'antd';
import PageContainer from '../../layouts/PageContainer';
import { CommonUnit, P1 } from '../../constants/units';
import { useTranslation } from 'react-i18next';
import { getDefaultRule, getNegativeNumberRule } from '../../constants/rules';
import PageHeading from '../../components/Heading/PageHeading';
import {
  DIFF_PRESSURE_GAS_TABLE_UNITS,
  pressureRatioString,
  PRESSURE_CLASSES,
  PRESSURE_UNITS,
  TEMPERATURE_UNITS,
  W_UNITS,
} from './constants';
import { FLUID_STATES } from '../FlowCalculation/constants';
import { IDifferentialSteamCalculation } from '../../interfaces/differentialPressureCalculation';
import {
  getCalculateSaturatedTemperature,
  getCalculateSuperHeatedTemperature,
  getDifferentialPressureSteamCalculateResult,
} from '../../services/differentialPressure';
import { STATUS_CODE } from '../../constants/statusCode';
import { debounceFormValueChange } from '../../helpers/debounce';
import UnitTable from '../../components/UnitTable/UnitTable';
import {
  IFlowCalculateResultResponse,
  ISaturatedTemperatureReq,
  ISuperHeatedTemperatureReq,
} from '../../interfaces/flowCalculation';
import { EMPTY_STRING, isCannotCalcPressure, isNumeric, minusString } from '../../helpers/utils';
// import { HandleOverFloatText } from '../../components/OverFloatText/HandleOverfloattext';
import { useCustomForm } from '../../hooks/useCustomForm';
import { getMessageByErrorCode } from '../../helpers/common';
import { CustomInput } from '../../components/CustomInput/CustomInput';
import { BaseForm } from '../../components/BaseForm';
import { BaseFormInputType, IBaseFormOptions } from '../../components/BaseForm/Interface';
import { AxiosResponseData, ErrorType, TableData } from '../../services/http-client';
import {
  displayPressureRatioResponse,
  isResponseResultOverLimit,
  displayResponseData,
  displayToFixed2Response,
} from '../../helpers/utils';

/**
 * Calculate gas flow rate UI
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
// eslint-disable-next-line max-lines-per-function, complexity
export default function PressureSteamCalculation(): JSX.Element {
  const { t } = useTranslation();
  const [inputForm] = useCustomForm(Form.useForm());
  const defaultValidateFields = ['cvValue', 'p1', 'w'];
  const advancedValidateFields = ['cvValue', 'p1', 'w', 't1'];
  const validateFields = useRef<string[]>(defaultValidateFields);
  const beValidateErrorCodes = [1505, 1506, 1507, 1510, 1511, 1512, 2001];
  const [errorMessage, setErrorMessage] = useState<string>();
  const [judgementMessage, setJudgementMessage] = useState<string>();
  const [pressureRatio, setPressureRatio] = useState<string>('');
  const [fluidState, setFluidState] = useState<string>(FLUID_STATES[0].value);
  const [gasVolumes, setGasVolumes] = useState<CommonUnit[]>(DIFF_PRESSURE_GAS_TABLE_UNITS);
  const [saturationDegree, setSaturationDegree] = useState<string>();
  const [superHeatDegree, setSuperHeatedDegree] = useState<string>();
  const [p1, onP1Change] = useState<string>();
  const [t1, onT1Change] = useState<string>();
  const [w, onWChange] = useState<string>();
  const [pressureUnit, onPressureUnitSelect] = useState<string>(PRESSURE_UNITS[0].value);
  const [pressureClass, onPressureClassSelect] = useState<string>(PRESSURE_CLASSES[0].value);
  const [temperatureUnit, setTemperatureUnit] = useState<string>(TEMPERATURE_UNITS[0].value);
  const [wUnit, setWUnit] = useState<string>(W_UNITS[0].value);
  const [selectedUnit, setSelectedUnit] = useState<string>(`${TEMPERATURE_UNITS[0].unit}`);
  const [isPressureError, setPressureError] = useState<boolean>(false);

  /**
   * Handle form values change
   *
   * Created: 2022/17/12
   *  @author DienPD1
   */
  const onFormValuesChange = (): void => {
    const isFormValid = inputForm.getFormEmtyOrError(validateFields.current);
    isFormValid.isOneFieldEmpty && setPressureRatio('');
    inputForm
      .validateFields(validateFields.current)
      .then(() => {
        setErrorMessage('');
        setJudgementMessage('');
        if (!isFormValid.isOneFieldEmpty) {
          setPressureError(false);
          getResult();
        } else {
          setGasVolumes(DIFF_PRESSURE_GAS_TABLE_UNITS);
        }
      })
      .catch(() => {
        setErrorMessage(inputForm.customError[0]?.msg);
        setJudgementMessage(`${t('CM_0178')}`);
        setPressureRatio(`${t('CM_0203')}`);
        setGasVolumes(DIFF_PRESSURE_GAS_TABLE_UNITS);
      });
  };

  /**
   * Get data form input fields
   *
   * Created: 2022/17/12
   *  @author DienPD1
   */
  const getInputData = (): IDifferentialSteamCalculation => {
    let data: IDifferentialSteamCalculation = {
      Cv: Number(inputForm.getFieldValue('cvValue')),
      P1: Number(inputForm.getFieldValue('p1')),
      P_unit_in: `${inputForm.getFieldValue('pressureClass')}${inputForm.getFieldValue(
        'pressureUnit',
      )}`.toLocaleUpperCase(),
      W: Number(inputForm.getFieldValue('w')),
      W_unit_in: inputForm.getFieldValue('wUnit'),
      S: inputForm.getFieldValue('fluidState'),
    };
    if (inputForm.getFieldValue('fluidState') === FLUID_STATES[1].value) {
      data = {
        ...data,
        ...{
          T1: Number(inputForm.getFieldValue('t1')),
          T_unit_in: inputForm.getFieldValue('temperatureUnit'),
        },
      };
    }
    return data;
  };

  /**
   * Call api get calculated gas flow rate
   *
   * Created: 2022/15/12
   *  @author DienPD1
   */
  // eslint-disable-next-line max-lines-per-function
  const getResult = async (): Promise<void> => {
    try {
      const response = await getDifferentialPressureSteamCalculateResult(getInputData());
      const data = response.data as AxiosResponseData;
      if (data.customError) {
        const customError = data.customError;
        const errCode = customError.code[0];
        const isHavePressureError = data.errors.validate?.[P1];
        if (customError.type === ErrorType.Validate) {
          const fieldName = customError.field?.[0];
          if (errCode && beValidateErrorCodes.includes(errCode)) {
            setErrorMessage(getMessageByErrorCode(errCode, fieldName));
            if (isHavePressureError) {
              setSaturationDegree(`${t('CM_0205')}`);
              setPressureError(true);
            }
          }
        } else {
          setErrorMessage(`${t(errCode.toString())}`);
        }
        setGasVolumes(DIFF_PRESSURE_GAS_TABLE_UNITS);
        setJudgementMessage(`${t('CM_0178')}`);
        setPressureRatio(`${t('CM_0203')}`);

        return;
      }
      data.data && setPressureRatio((data.data as TableData)[pressureRatioString].toString());
      setJudgementMessageByRatio();
      setResultToTable(data);
    } catch (error) {
      console.log(error);
    }
  };

  const setJudgementMessageByRatio = (): void => {
    if (Number(pressureRatio) < 0.5) {
      setJudgementMessage(`${t('CM_0040')}`);
    } else {
      setErrorMessage(`${t('2002')}`);
      setJudgementMessage(`${t('CM_0196')}`);
      setPressureRatio(`${t('CM_0203')}`);
    }
  };

  /**
   * Set calculated results to table
   *
   * Created: 2022/15/12
   *  @author DienPD1
   */
  const setResultToTable = (data: AxiosResponseData): void => {
    const tableData = data.data as TableData;
    const volumeUnits = gasVolumes.map((item) => {
      if (tableData[item.value] || tableData[item.value] === 0) {
        return { ...item, result: tableData[item.value] };
      }
      return item;
    });
    setGasVolumes(volumeUnits);
  };

  /**
   * Call api get calculated super heated temperature at P1
   *
   * Created: 2022/17/12
   *  @author DienPD1
   */
  const getSuperHeatedTemperature = async (): Promise<void> => {
    try {
      const inputData: ISuperHeatedTemperatureReq = {
        P1: Number(inputForm.getFieldValue('p1')),
        P_unit_in: `${inputForm.getFieldValue('pressureClass')}${inputForm.getFieldValue(
          'pressureUnit',
        )}`.toLocaleUpperCase(),
        T1: Number(inputForm.getFieldValue('t1')),
        T_unit_in: inputForm.getFieldValue('temperatureUnit'),
      };
      const response = await getCalculateSuperHeatedTemperature(inputData);
      const data = response.data as IFlowCalculateResultResponse;
      if (isPressureError) {
        inputForm.getFieldValue('t1') ? setSuperHeatedDegree(`${t('CM_0205')}`) : setSuperHeatedDegree(EMPTY_STRING);
        return;
      }
      if (data.code === STATUS_CODE.SUCCESS) {
        data.data?.value && setSuperHeatedDegree(data.data.value.toString());
      }
      if (data.customError && data.customError.code && data.customError.code.length > 0) {
        setSuperHeatedDegree(EMPTY_STRING);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Call api get calculated saturated temperature at P1
   *
   * Created: 2022/17/12
   *  @author DienPD1
   */
  const getSaturatedTemperatureAtP1 = async (): Promise<void> => {
    try {
      const pData: ISaturatedTemperatureReq = {
        P: Number(inputForm.getFieldValue('p1')),
        P_unit_in: `${inputForm.getFieldValue('pressureClass')}${inputForm.getFieldValue('pressureUnit')}`,
      };
      const response = await getCalculateSaturatedTemperature(pData);
      const data = response.data as IFlowCalculateResultResponse;
      if (data.code === STATUS_CODE.SUCCESS && data.data && data.data?.[temperatureUnit]) {
        setSaturationDegree(data.data[temperatureUnit].toString());
      }
      if (data.customError && data.customError.code.length > 0) {
        if (data.customError.code[0] === 1511) {
          setSaturationDegree(minusString);
          setSuperHeatedDegree(minusString);
        } else {
          setSaturationDegree(`${t('CM_0205')}`);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isNumeric(p1) && isNumeric(w)) {
      getSaturatedTemperatureAtP1();
    } else {
      setSaturationDegree(undefined);
    }
  }, [p1, pressureUnit, pressureClass, temperatureUnit, w, wUnit, t1]);

  useEffect(() => {
    if (isNumeric(p1) && isNumeric(t1) && isNumeric(w)) {
      getSuperHeatedTemperature();
    } else {
      setSuperHeatedDegree(undefined);
    }
  }, [t1, temperatureUnit, p1, pressureUnit, pressureClass, w, wUnit, isPressureError]);

  useEffect(() => {
    if (fluidState === FLUID_STATES[0].value) {
      validateFields.current = defaultValidateFields;
      setSuperHeatedDegree(undefined);
      onFormValuesChange();
    } else {
      validateFields.current = advancedValidateFields;
      setGasVolumes(DIFF_PRESSURE_GAS_TABLE_UNITS);
    }
  }, [fluidState]);

  const conditionalToShowResult = (type: number | undefined | string): string => {
    const conditionalToShowErrMessage = inputForm.getFieldError(['p1']).length === 1;

    if (conditionalToShowErrMessage) {
      if (isCannotCalcPressure('p1', inputForm.customError)) {
        return minusString;
      }
      return `${t('CM_0205')}`;
    }

    if (type === 0 || (type && isNumeric(type.toString()))) {
      if (isResponseResultOverLimit(Number(type))) {
        return displayResponseData(Number(type));
      }
      return displayToFixed2Response(type);
    }
    return type ? type.toString() : EMPTY_STRING;
  };

  const baseFormOpt: IBaseFormOptions = {
    form: inputForm,
    onFormValuesChange: debounceFormValueChange(onFormValuesChange),
    fields: [
      {
        label: t('CM_0011'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'cvValue',
            rules: getNegativeNumberRule(t('CM_0011'), undefined, undefined, undefined, true),
            placeholder: t('CM_0129'),
          },
        ],
      },
      {
        label: t('CM_0028'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'w',
            rules: getNegativeNumberRule(t('CM_0160'), undefined, false, undefined, true),
            placeholder: t('CM_0129'),
            onChange: (e) => onWChange(e.target.value),
          },
          {
            type: BaseFormInputType.Select,
            name: 'wUnit',
            value: W_UNITS[0].value,
            options: W_UNITS.map((item) => ({
              key: item.unit,
              value: item.value,
              label: `${t(item.unit)}`,
            })),
            onChange: (e) => setWUnit(e),
          },
        ],
      },
      {
        label: t('CM_0003'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'p1',
            rules: getDefaultRule(t(`${t('CM_0194')}`), undefined, true),
            placeholder: t('CM_0129'),
            onChange: (e) => onP1Change(e.target.value),
          },
          {
            type: BaseFormInputType.Select,
            name: 'pressureUnit',
            value: PRESSURE_UNITS[0].value,
            options: PRESSURE_UNITS.map((item) => ({
              key: item.unit,
              value: item.value,
              label: `${item.unit}`,
            })),
            onChange: (e) => onPressureUnitSelect(e),
          },
          {
            type: BaseFormInputType.Select,
            name: 'pressureClass',
            value: PRESSURE_CLASSES[0].value,
            options: PRESSURE_CLASSES.map((item) => ({
              key: item.unit,
              value: item.value,
              label: t(item.name),
            })),
            onChange: (e) => onPressureClassSelect(e),
          },
        ],
      },
      {
        label: t('CM_0138'),
        inputs: [
          {
            type: BaseFormInputType.RadioGroup,
            name: 'fluidState',
            options: FLUID_STATES.map((item) => ({ key: item.unit, value: item.value, label: t(item.name) })),
            value: fluidState,
            onChange: (e): void => {
              if (e.target.value === FLUID_STATES[0].value) {
                setSuperHeatedDegree(EMPTY_STRING);
                inputForm.setFieldValue('t1', EMPTY_STRING);
              }

              setFluidState(e.target.value);
            },
            note: <>{t('CM_0031')}</>,
          },
        ],
      },
      {
        label: t('CM_0018'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 't1',
            rules: getDefaultRule(t('CM_0159'), undefined, true),
            placeholder: fluidState === FLUID_STATES[0].value ? EMPTY_STRING : t('CM_0129'),
            disabled: fluidState === FLUID_STATES[0].value,
            onChange: (e) => onT1Change(e.target.value),
          },
          {
            type: BaseFormInputType.Select,
            name: 'temperatureUnit',
            value: TEMPERATURE_UNITS[0].value,
            options: TEMPERATURE_UNITS.map((item) => ({
              key: item.unit,
              value: item.value,
              label: `${t(item.name)}`,
            })),
            onChange: (e): void => {
              setSelectedUnit(`${TEMPERATURE_UNITS.filter((item) => item.value === e)[0].unit}`);
              setTemperatureUnit(e);
            },
          },
        ],
      },
      {
        label: t('CM_0032'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'saturationDegree',
            className: 'input-result',
            disabled: true,
            isResultField: true,
            value: conditionalToShowResult(saturationDegree),
            unit: selectedUnit,
            isSpecialFormat: true,
          },
        ],
      },
      {
        label: t('CM_0033'),
        className: 'gf-value',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'superheatDegree',
            className: 'input-result',
            disabled: true,
            isResultField: true,
            value: fluidState === FLUID_STATES[0].value ? EMPTY_STRING : conditionalToShowResult(superHeatDegree),
            unit: selectedUnit,
            isSpecialFormat: true,
          },
        ],
      },
    ],
  };
  const children = (
    <div className="diff-pressure-container steam">
      <PageHeading borderColor="pear">{t('CM_0073')}</PageHeading>
      <BaseForm options={baseFormOpt}></BaseForm>

      <div className="result-container">
        <div className="result">
          <div className="title">{t('CM_0010')}</div>
          <div className="error-message">{errorMessage}</div>
        </div>
        <Row gutter={16} className="include-result">
          <Row className="include-result__col">
            <div className="flex dental-container" style={{ marginLeft: '8px', marginRight: '103px' }}>
              <div className="judgement" style={{ width: '96px', marginRight: '16px' }}>
                <div className="title" style={{ width: '96px' }}>
                  {t('CM_0037')}
                </div>
              </div>
              <CustomInput
                className="input-result"
                value={judgementMessage}
                disabled={true}
                placeholder=""
                isResultField={true}
              />
            </div>
          </Row>
          <Row className="include-result__col">
            <div className="flex dental-container">
              <div className="judgement " style={{ marginRight: '16px' }}>
                <div className="unit" style={{ width: '96px' }}>
                  {t('CM_0012')}
                </div>
              </div>
              <CustomInput
                className={`text-right input-result`}
                // ${
                //   !isNaN(Number(pressureRatio)) && Number(pressureRatio) > 0.5 ? 'highlight-input' : ''
                // }`}
                value={
                  pressureRatio
                    ? Number(pressureRatio)
                      ? Number(displayPressureRatioResponse(pressureRatio)) >= 0.5
                        ? (t('CM_0203') as string)
                        : displayPressureRatioResponse(pressureRatio)
                      : pressureRatio
                    : EMPTY_STRING
                }
                disabled={true}
                placeholder=""
                isResultField={true}
                isSpecialFormat={true}
              />
            </div>
          </Row>
        </Row>

        <div className="result-table">
          <UnitTable dataTable1={gasVolumes} titleTable1={t('CM_0156') || ''}></UnitTable>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <PageContainer>{children}</PageContainer>
    </>
  );
}
