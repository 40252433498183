import { errorType } from '../constants/rules';
import { CommonUnit } from '../constants/units';
import { CustomError, CustomFormInstance } from '../hooks/useCustomForm';
import i18next from '../i18n';
import { isInteger, cloneDeep } from 'lodash';

export const isNumeric = (str: string | undefined): boolean => {
  return !isNaN(Number(str)) && !isNaN(parseFloat(str || ''));
};

export const isBetween = (current: number, n1: number, n2: number): boolean => {
  return current >= n1 && current <= n2;
};

export const MAX_NUMBER_DIGIT = 15;

export const isNumberLengthInvalid = (x: string): boolean => {
  return x.trim().length > MAX_NUMBER_DIGIT || getExponentialNumberLength(Number(x)) > MAX_NUMBER_DIGIT;
};
export const isResponseResultOverLimit = (x?: number): boolean => {
  return !!(x && (Math.abs(x as number) >= maxNumberNormalDisplay || Math.abs(x as number) < minNumberNormalDisplay));
};

/**
 * Rule rounding number
 * @author AnhPV17
 *
 * @return
 */
const roundingArray = [100, 10, 1, 0.1, 0.01, 0.001, 0.0001, 0.00001, 0.000001, 0.0000001];
export default function rounding(x: number): number {
  for (const i in roundingArray) {
    if (x >= roundingArray[i]) {
      return Number(x.toFixed(Number(i)));
    }
  }
  return Number(x);
}

export function roundingString(x: number): string {
  for (const i in roundingArray) {
    if (x >= roundingArray[i]) {
      return x.toFixed(Number(i));
    }
  }
  return x.toString();
}

export const maxNumberOnlyDigits = 10;
export const betWeenNumber = Math.pow(10, maxNumberOnlyDigits);
export const maxNumberNormalDisplay = 99999999999.5;
export const minNumberNormalDisplay = 0.0001;
export const shortDigitLength = 5;
export const longDigitLength = 9;
export const maxDigitLength = 6;
export const eString = 'e';
export const bigEString = 'E';
export const plusString = '+';
export const minusString = '-';
export const localeStringLang = 'en';
export const DOT_STRING = '.';
export const EMPTY_STRING = '';
export const maxExponentialLength = 3;

export const getDecimalString = (value: string): string => {
  if (value.includes(eString)) {
    return value;
  }
  let res = '';
  for (let i = value.length; i >= 0; i--) {
    if (Number(value.slice(value.length - 1, value.length)) !== 0) {
      return value;
    }
    if (Number(value.slice(i - 1, i)) !== 0) {
      return res.slice(0, res.length - 1);
    }
    res = value.slice(0, i);
  }
  return res;
};

const setExponentialString = (value: string): string => {
  const valueBeforeE = Number(value.slice(0, value.indexOf(eString)));
  const equation = value.slice(value.indexOf(eString) + 1, value.indexOf(eString) + 2);
  const valueAfterE = Number(value.slice(value.indexOf(equation) + 1));
  if (Number(valueBeforeE) % maxNumberOnlyDigits === 0) {
    const afterE = equation === plusString ? valueAfterE + 1 : valueAfterE - 1;
    return valueBeforeE / maxNumberOnlyDigits + eString + equation + afterE;
  }
  return value;
};

const convertToBeforeFormat = (isNegative: boolean, no: number): number => {
  return isNegative ? -no : no;
};
export const setThousandSeparatorForNumber = (value: string): string => {
  const isNegativeNumber = Number(value) < 0;
  const integerPart = Math.floor(Math.abs(Number(value)));
  const numberDigitsLeft =
    maxNumberOnlyDigits > integerPart.toString().length ? maxNumberOnlyDigits - integerPart.toString().length : 0;
  const decimalPart = (Number(value) - Number(integerPart)).toFixed(numberDigitsLeft).toString();

  const startOfDecimalsNumber = decimalPart.indexOf(DOT_STRING) + 1;
  const isRemainAvailable = Number(decimalPart).toString().slice(startOfDecimalsNumber).length >= numberDigitsLeft;

  const displayDecimalPart = isRemainAvailable
    ? decimalPart.slice(
        startOfDecimalsNumber,
        startOfDecimalsNumber + maxNumberOnlyDigits - integerPart.toString().length,
      )
    : decimalPart.slice(startOfDecimalsNumber);

  return Number(displayDecimalPart) !== 0
    ? convertToBeforeFormat(isNegativeNumber, integerPart).toLocaleString(localeStringLang) +
        DOT_STRING +
        getDecimalString(displayDecimalPart)
    : convertToBeforeFormat(isNegativeNumber, integerPart).toLocaleString(localeStringLang);
};

// eslint-disable-next-line max-lines-per-function
export const convertOverLimitResponseResult = (x: number): string => {
  // Case x> max
  if (Math.abs(x) >= maxNumberNormalDisplay) {
    const overMaxExpoDisplay = x.toExponential(shortDigitLength);
    return formatExpoNumber(
      setThousandSeparatorForNumber(overMaxExpoDisplay.slice(0, overMaxExpoDisplay.indexOf(eString))) +
        overMaxExpoDisplay.slice(overMaxExpoDisplay.indexOf(eString)),
    );
  }

  const inputString = x.toString();
  if (isInteger(x)) {
    return inputString;
  }
  // Case x < min
  if (Math.abs(x) < minNumberNormalDisplay) {
    const exponentialString = x.toExponential();
    const beforeExponential = exponentialString.slice(0, exponentialString.indexOf(eString));
    const afterExponential = exponentialString.slice(exponentialString.indexOf(eString));

    const numberBeforeE = Number(beforeExponential);
    const numberAfterE = Number(numberBeforeE.toFixed(shortDigitLength));
    const beforeE =
      numberAfterE === Math.floor(numberAfterE) ? Math.floor(numberAfterE).toString() : numberAfterE.toString();
    const beforeExpoLength = beforeExponential.replace(DOT_STRING, EMPTY_STRING).toString().length;

    if (beforeExpoLength >= maxDigitLength) {
      return formatExpoNumber(setExponentialString(beforeE + afterExponential));
    }
    const stringNumber = x.toString();
    const toFixedNumber = Number(stringNumber.slice(stringNumber.indexOf(minusString) + 1));
    if (stringNumber.includes(eString)) {
      const decimalLength = beforeExpoLength + toFixedNumber - 1;
      if (toFixedNumber >= maxNumberOnlyDigits || decimalLength >= maxNumberOnlyDigits) {
        return formatExpoNumber(beforeE + afterExponential);
      }

      return x.toFixed(decimalLength);
    } else if (stringNumber.slice(stringNumber.indexOf(DOT_STRING) + 1).length >= maxNumberOnlyDigits) {
      return formatExpoNumber(beforeE + afterExponential);
    }
    return x.toString();
  }
  return inputString;
};

export const formatExpoNumber = (str: string): string => {
  const valueUpperCase = str.toUpperCase();
  if (valueUpperCase.includes(bigEString)) {
    const ePart = valueUpperCase.slice(valueUpperCase.indexOf(bigEString));
    const numberPart = valueUpperCase.replace(ePart, EMPTY_STRING);
    const equation = ePart.includes(plusString) ? plusString : minusString;
    const eNumber = ePart.slice(ePart.indexOf(equation) + 1, ePart.length);
    return eNumber.length === 1 ? numberPart + ePart.replace(equation, equation + '0') : numberPart + ePart;
  }

  return str;
};

export const convertResponseResult = (x: number): string => {
  //Case1	X>= 10^N
  if (x >= betWeenNumber) {
    return Math.round(x).toString();
  }
  //Case 2: x is an integer
  if (isInteger(x)) {
    return x.toString();
  }

  // x is a decimal
  const decimalDigitsLength = Math.abs(x).toString().replace(DOT_STRING, '').length;
  if (decimalDigitsLength) {
    // Case 3: digits is <= max
    let decimalResult = x.toString();
    const integerLength = Math.floor(Math.abs(x)).toString().length;
    // Case 4: digits is > max
    if (decimalDigitsLength > maxNumberOnlyDigits) {
      decimalResult = x.toFixed(
        maxNumberOnlyDigits - integerLength + 1 > 0 ? maxNumberOnlyDigits - integerLength + 1 : 0,
      );
    }
    return decimalResult.toString();
  }
  return x.toString();
};

// Check result
const isHaveResult = (tableResult: CommonUnit[]): boolean => {
  return tableResult.filter((item) => item.result).length > 0;
};

export const displayResponseData = (value: number): string => {
  return (
    isResponseResultOverLimit(value)
      ? convertOverLimitResponseResult(value)
      : setThousandSeparatorForNumber(convertResponseResult(value))
  ).toUpperCase();
};

//Format table data
export const formatTableDataResponse = (tableData: CommonUnit[]): CommonUnit[] => {
  return cloneDeep(tableData).map((item) => {
    if (isHaveResult(tableData) && item.result) {
      return {
        ...item,
        result: displayResponseData(item.result),
      };
    }
    return item;
  }) as CommonUnit[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValidateFieldsNotEmpty = (form: CustomFormInstance<any>, validateFields: string[]): boolean => {
  return validateFields.every((fieldName) => !!form.getFieldValue(fieldName));
};

export const getExpoResult = (value: number): string => {
  const roundNumber = Math.pow(10, maxExponentialLength);
  const normalDisplay = Math.abs(value).toExponential();
  const beforeE = normalDisplay.slice(0, normalDisplay.indexOf(eString));
  const isExpoOverLength = beforeE.length > shortDigitLength; // Check over 3 decimal digit before E
  const expoLength = beforeE.length >= shortDigitLength ? shortDigitLength : beforeE.length;
  let actualBeforeExpo = '';
  if (isExpoOverLength) {
    actualBeforeExpo = (
      Math.round(Number(normalDisplay.slice(0, maxDigitLength)) * roundNumber) / roundNumber
    ).toFixed(maxExponentialLength);
  } else {
    actualBeforeExpo = Number(normalDisplay.slice(0, expoLength)).toFixed(maxExponentialLength);
  }
  return actualBeforeExpo + normalDisplay.slice(normalDisplay.indexOf(eString));
};

export const setExponentialDataTable = (value: number): string => {
  if (isInteger(value)) {
    return value.toExponential(maxExponentialLength).toUpperCase();
  }

  const normalDisplay = Math.abs(value).toExponential();

  if (!normalDisplay.includes(DOT_STRING)) {
    return value.toExponential(maxExponentialLength).toUpperCase();
  }

  return (value < 0 ? minusString + getExpoResult(value) : getExpoResult(value)).toUpperCase();
};

export const roundingCVDeltaP = (x: number): string => {
  if (isResponseResultOverLimit(x)) {
    return formatExpoNumber(convertOverLimitResponseResult(x).toUpperCase());
  }
  if (x >= 0.001) {
    const roundownNumber = Math.pow(10, 3);
    return (Math.floor(x * roundownNumber) / roundownNumber).toFixed(3); //H272 sheet Cv値(気体) (変更案)
  }
  return x.toString();
};

export const displayPressureRatioResponse = (value: string): string => {
  if (value) {
    if (Number(value)) {
      return roundingCVDeltaP(Number(value));
    }
    return value;
  }
  return EMPTY_STRING;
};

export const displayToFixed2Response = (value: string | number | null | undefined, limit = 0.1): string => {
  if (value === null || value === undefined) {
    return EMPTY_STRING;
  }
  const stringValue = value.toString();
  if (!isNumeric(stringValue)) {
    return stringValue;
  }
  const valueNumberDisplay = Number(stringValue);
  if (!valueNumberDisplay && valueNumberDisplay !== 0) {
    return stringValue;
  }
  if (valueNumberDisplay >= limit) {
    const res = valueNumberDisplay.toFixed(2);
    return setThousandSeparatorForNumber(res.slice(0, res.indexOf(DOT_STRING))) + res.slice(res.indexOf(DOT_STRING));
  }

  if (isResponseResultOverLimit(valueNumberDisplay)) {
    return convertOverLimitResponseResult(valueNumberDisplay).toUpperCase();
  }
  return stringValue;
};

export const getExponentialNumberLength = (num: number): number => {
  const numStr = num
    .toString()
    .replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, (a: string, b: string, c: string, d: string, e: string): string => {
      return Number(e) < 0
        ? b + '0.' + Array(1 - Number(e) - c.length).join('0') + c + d
        : b + c + d + Array(Number(e) - d.length + 1).join('0');
    });

  return numStr.length;
};

export const isCannotCalcPressure = (inputName: string, customError: CustomError[]): boolean => {
  if (!customError || customError.length === 0 || !inputName) {
    return false;
  }
  const inputNameError = customError.find((item) => item.inputName === inputName);
  return !!(inputNameError && inputNameError.validateType && inputNameError.validateType === errorType.isNaN);
};
export const getJsonValidateError = (fieldName: string, priority: number, msgCode: string): string => {
  return JSON.stringify({ priority: priority, msg: i18next.t(msgCode, { fieldName: fieldName }) });
};
