import { useMemo } from 'react';
import { Form } from 'antd';
import { debounce } from 'lodash';
import { formHeaderRender, getInitValue, inputGroupRender, inputRender } from './Helper';
import { IBaseFormOptions } from './Interface';

import './index.scss';

// eslint-disable-next-line max-lines-per-function
export const BaseForm = (props: { options: IBaseFormOptions }): JSX.Element => {
  const formOptions = props.options;
  const inputForm = formOptions.form;

  const initialValues = useMemo(() => {
    if (formOptions.initialValues) {
      return formOptions.initialValues;
    }

    return getInitValue(formOptions.fields);
  }, [formOptions.fields]);

  const formFieldsRender = useMemo(() => {
    const formFields = formOptions.fields.map((field, index) => {
      if (field.inputs.length === 1) {
        return inputRender(field.inputs[0], field);
      }

      return inputGroupRender(field, index);
    });

    return formFields;
  }, [formOptions]);

  return (
    <>
      <div className={`input-container`}>
        {formHeaderRender(formOptions)}

        <Form
          form={inputForm}
          colon={false}
          onBlur={formOptions.onFormBlur}
          className={`base-form ${formOptions.className}`}
          initialValues={initialValues}
          onValuesChange={formOptions.onFormValuesChange && debounce(formOptions.onFormValuesChange, 250)}
        >
          {formFieldsRender}
        </Form>
      </div>
    </>
  );
};
