/**
 * Constants are used for different pressure calculation
 *
 * Created: 2022/15/12
 * __author__: DienPD1
 * __copyright__:Copyright KITZ Inc. 2022 All Rights Reserved
 * __version__: 1.0
 */
import {
  FlowRateGasUnitsID,
  FlowRateLiquidUnitsID,
  FlowRateSteamUnitsID,
  PressureClassesID,
  PressureUnitsID,
  TemperatureUnitsID,
} from '../../constants/unitsID';
import { ISelectOption } from '../../interfaces/selectOption';
import { ITableUnit } from '../../interfaces/tableUnit';

export const PRESSURE_CLASSES: ISelectOption[] = [
  { value: PressureClassesID.Gauge, unit: 'G', name: 'CM_0006' },
  { value: PressureClassesID.Absolute, unit: 'a', name: 'CM_0007' },
];

export const PRESSURE_UNITS: ISelectOption[] = [
  { value: PressureUnitsID.Mpa, name: 'CM_0062', unit: 'MPa' },
  { value: PressureUnitsID.kPa, name: 'CM_0063', unit: 'kPa' },
  { value: PressureUnitsID.Pa, name: 'CM_0064', unit: 'Pa' },
  { value: PressureUnitsID.bar, name: 'CM_0065', unit: 'bar' },
  { value: PressureUnitsID.kgfcm2, name: 'CM_0066', unit: 'kgf/cm<sup>2</sup>' },
  { value: PressureUnitsID.mmHgTorr, name: 'CM_0067', unit: 'mmHg or Torr' },
  { value: PressureUnitsID.mmH2OmmAq, name: 'CM_0068', unit: 'mmH<sub>2</sub>O or mmAq' },
  { value: PressureUnitsID.psilbfin2, name: 'CM_0069', unit: 'psi or lbf/in<sup>2</sup>' },
  { value: PressureUnitsID.inH2O, name: 'CM_0070', unit: 'inH<sub>2</sub>O' },
  { value: PressureUnitsID.ftH2O, name: 'CM_0071', unit: 'ftH<sub>2</sub>O' },
];

export const TEMPERATURE_UNITS: ISelectOption[] = [
  { value: TemperatureUnitsID.Celsius, name: 'CM_0142', unit: '°C' },
  { value: TemperatureUnitsID.Kevil, name: 'CM_0143', unit: 'K' },
  { value: TemperatureUnitsID.Fahrenheit, name: 'CM_0144', unit: '°F' },
  { value: TemperatureUnitsID.Rankine, name: 'CM_0145', unit: '°R' },
];

export const V_UNITS: ISelectOption[] = [
  { unit: 'Nm<sup>3</sup>/h', name: 'CM_0146', value: FlowRateGasUnitsID.Nm3h },
  { unit: 'Nm<sup>3</sup>/min', name: 'CM_0147', value: FlowRateGasUnitsID.Nm3min },
  { unit: 'NL/h', name: 'CM_0148', value: FlowRateGasUnitsID.NLh },
  { unit: 'NL/min', name: 'CM_0149', value: FlowRateGasUnitsID.NLmin },
  { unit: 'm<sup>3</sup>/h', name: 'CM_0150', value: FlowRateGasUnitsID.m3h },
  { unit: 'm<sup>3</sup>/min', name: 'CM_0151', value: FlowRateGasUnitsID.m3min },
  { unit: 'L/h', name: 'CM_0152', value: FlowRateGasUnitsID.Lh },
  { unit: 'L/min', name: 'CM_0153', value: FlowRateGasUnitsID.Lmin },
  { unit: 'Sm<sup>3</sup>/h', name: 'CM_0154', value: FlowRateGasUnitsID.Sm3h },
  { unit: 'SCFH', name: 'CM_0155', value: FlowRateGasUnitsID.SCFH },
  { unit: 'kg/h', name: 'CM_0184', value: FlowRateGasUnitsID.kgh },
  { unit: 't/h', name: 'CM_0185', value: FlowRateGasUnitsID.th },
  { unit: 'lb/h', name: 'CM_0186', value: FlowRateGasUnitsID.lbh },
];

export const W_UNITS: ISelectOption[] = [
  { unit: 'CM_0184', name: 'CM_0049', value: FlowRateSteamUnitsID.kgh },
  { unit: 'CM_0185', name: 'CM_0050', value: FlowRateSteamUnitsID.th },
  { unit: 'CM_0186', name: 'CM_0051', value: FlowRateSteamUnitsID.lbh },
];

export const DIFF_PRESSURE_GAS_TABLE_UNITS: ITableUnit[] = [
  { value: PressureUnitsID.Mpa, name: 'CM_0062', unit: 'MPa' },
  { value: PressureUnitsID.kPa, name: 'CM_0063', unit: 'kPa' },
  { value: PressureUnitsID.Pa, name: 'CM_0064', unit: 'Pa' },
  { value: PressureUnitsID.bar, name: 'CM_0065', unit: 'bar' },
  { value: PressureUnitsID.kgfcm2, name: 'CM_0066', unit: 'kgf/cm<sup>2</sup>' },
  { value: PressureUnitsID.mmHgTorr, name: 'CM_0067', unit: 'mmHg or Torr' },
  { value: PressureUnitsID.mmH2OmmAq, name: 'CM_0068', unit: 'mmH<sub>2</sub>O or mmAq' },
  { value: PressureUnitsID.psilbfin2, name: 'CM_0069', unit: 'psi or lbf/in<sup>2</sup>' },
  { value: PressureUnitsID.inH2O, name: 'CM_0070', unit: 'inH<sub>2</sub>O' },
  { value: PressureUnitsID.ftH2O, name: 'CM_0071', unit: 'ftH<sub>2</sub>O' },
];

export const DIFF_PRESSURE_LIQUID_FLOW_UNITS: ISelectOption[] = [
  { unit: 'm<sup>3</sup>/h', name: 'CM_0150', value: FlowRateLiquidUnitsID.m3h, prefix: 'CM_0021' },
  { unit: 'm<sup>3</sup>/min', name: 'CM_0151', value: FlowRateLiquidUnitsID.m3min, prefix: 'CM_0021' },
  { unit: 'L/h', name: 'CM_0152', value: FlowRateLiquidUnitsID.Lh, prefix: 'CM_0021' },
  { unit: 'L/min', name: 'CM_0153', value: FlowRateLiquidUnitsID.Lmin, prefix: 'CM_0021' },
  { unit: 'US gal/min', name: 'CM_0048', value: FlowRateLiquidUnitsID.Usgalmin, prefix: 'CM_0021' },
  { unit: 'kg/h', name: 'CM_0049', value: FlowRateLiquidUnitsID.kgh, prefix: 'CM_0022' },
  { unit: 't/h', name: 'CM_0050', value: FlowRateLiquidUnitsID.th, prefix: 'CM_0022' },
  { unit: 'lb/h', name: 'CM_0051', value: FlowRateLiquidUnitsID.lbh, prefix: 'CM_0022' },
];

export const pressureRatioString = 'sa_atsu_hi';
