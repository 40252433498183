import { HEAD, PRESSURE_CLASSES, PRESSURE_UNITS } from '../constants/units';
import i18next from '../i18n';

/**
 * get Select Unit
 *
 * Created: 2022/05/12
 * __author__: VuongLK
 * __copyright__: Copyright KITZ Corporation. 2022 All Rights Reserved
 * __version__: 1.1.0
 */
export function getSelectUnit(selectedUnit: string, selectedClass: string): (string | undefined)[] {
  const unit = PRESSURE_UNITS.find((item) => item.value === selectedUnit);
  const classPre = PRESSURE_CLASSES.find((item) => item.value === selectedClass);

  if (classPre && unit) {
    return [unit?.label?.replace(' or ', `(${classPre.unit}) or `) + `(${classPre.unit})`, unit?.label];
  }

  return [unit?.label, classPre?.label];
}

// eslint-disable-next-line complexity
export function getMessageByErrorCode(code: number, fieldError?: string): string {
  let msg = '';
  switch (code) {
    case 1505:
    case 1506:
    case 1507:
    case 1510:
    case 1512:
    case 2001:
      msg = `${i18next.t(code.toString())}`;
      break;
    case 1511:
      if (!fieldError) {
        return msg;
      }
      const errField = fieldError && fieldError === 'P1' ? 'CM_0194' : 'CM_0195';
      msg = `${i18next.t('CM_0193', { fieldName: i18next.t(errField) })}`;
      break;
    default:
      break;
  }
  return msg;
}

export function getHead(head: string | undefined): string {
  return head ? HEAD[head as keyof typeof HEAD] : '℃';
}
