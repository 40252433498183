import { CommonUnit } from '../../constants/units';
import { FlowRateGasUnitsID, FlowRateSteamUnitsID } from '../../constants/unitsID';
import { ITableUnit } from '../../interfaces/tableUnit';

export const VOLUME_FLOW_GAS_TABLE_UNITS: CommonUnit[] = [
  {
    value: FlowRateGasUnitsID.Nm3h,
    label: 'CM_0146',
    unit: 'Nm<sup>3</sup>/h',
    name: 'CM_0054',
    nameCol2: 'CM_0023',
  },
  {
    value: FlowRateGasUnitsID.Nm3min,
    label: 'CM_0147',
    unit: 'Nm<sup>3</sup>/min',
    name: 'CM_0055',
    nameCol2: 'CM_0023',
  },
  {
    value: FlowRateGasUnitsID.NLh,
    label: 'CM_0148',
    unit: 'NL/h',
    name: 'CM_0056',
    nameCol2: 'CM_0023',
  },
  {
    value: FlowRateGasUnitsID.NLmin,
    label: 'CM_0149',
    unit: 'NL/min',
    name: 'CM_0057',
    nameCol2: 'CM_0023',
  },
  {
    value: FlowRateGasUnitsID.m3h,
    label: 'CM_0150',
    unit: 'm<sup>3</sup>/h',
    name: 'CM_0044',
    nameCol2: 'CM_0024',
  },
  {
    value: FlowRateGasUnitsID.m3min,
    label: 'CM_0151',
    unit: 'm<sup>3</sup>/min',
    name: 'CM_0045',
    nameCol2: 'CM_0024',
  },
  {
    value: FlowRateGasUnitsID.Lh,
    label: 'CM_0152',
    unit: 'L/h',
    name: 'CM_0046',
    nameCol2: 'CM_0024',
  },
  {
    value: FlowRateGasUnitsID.Lmin,
    label: 'CM_0153',
    unit: 'L/min',
    name: 'CM_0047',
    nameCol2: 'CM_0024',
  },

  {
    value: FlowRateGasUnitsID.Sm3h,
    label: 'CM_0154',
    unit: 'Sm<sup>3</sup>/h',
    name: 'CM_0058',
    nameCol2: 'CM_0025',
  },
  {
    value: FlowRateGasUnitsID.SCFH,
    label: 'CM_0155',
    unit: 'SCFH',
    name: 'CM_0192',

    nameCol2: 'CM_0025',
  },
];

export const MASS_FLOW_GAS_TABLE_UNITS: ITableUnit[] = [
  { unit: 'kg/h', name: 'CM_0049', value: FlowRateGasUnitsID.kgh },
  { unit: 't/h', name: 'CM_0050', value: FlowRateGasUnitsID.th },
  { unit: 'lb/h', name: 'CM_0051', value: FlowRateGasUnitsID.lbh },
];

export const MASS_FLOW_GAS_TABLE_UNITS_SINGLE: ITableUnit[] = [
  { unit: 'kg/h', name: 'CM_0049', value: FlowRateSteamUnitsID.kgh },
  { unit: 't/h', name: 'CM_0050', value: FlowRateSteamUnitsID.th },
  { unit: 'lb/h', name: 'CM_0051', value: FlowRateSteamUnitsID.lbh },
];
