import React from 'react';
import i18n from '../../i18n';
import './index.scss';
import { DEFAULT_LANGUAGE, LANG_CONFIG } from '../../helpers/lang';
interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  borderColor?: 'green' | 'blue' | 'yellow' | 'red' | 'navy' | 'jade' | 'pear' | 'san-mario' | 'pt';
}

const getLocaleTime = (lang: string): string => {
  let countryCode = LANG_CONFIG[lang].dateCountryCode;
  if (!countryCode) {
    countryCode = LANG_CONFIG[DEFAULT_LANGUAGE].dateCountryCode;
  }
  return new Date()
    .toLocaleDateString(countryCode, {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    .replace(/ /g, '-');
};

export default function PageHeading({ children, borderColor, className, ...otherProps }: IProps): JSX.Element | null {
  const classTitle = `title-primary ${borderColor || 'green'} ${className || ''}`;
  return (
    <div className="flex flex-row items-center pt-7 pb-5 header-container">
      <h1 className={classTitle} {...otherProps}>
        {children}
      </h1>
      <div className="date ml-auto mr-2.5">{getLocaleTime(i18n.language)}</div>
    </div>
  );
}
