import { CaretDownOutlined } from '@ant-design/icons';
import { Col, Form, Input, Radio, Row, Select, Space } from 'antd';
import { CustomInput } from '../CustomInput/CustomInput';
import {
  BaseFormInputType,
  IBaseFormField,
  IBaseFormFieldConfig,
  IBaseFormInput,
  IBaseFormOptions,
  IBaseFormRadioGroup,
  IBaseFormSelectInput,
  IBaseFormTextInput,
} from './Interface';
import { Store } from 'antd/es/form/interface';

const BASE_FORM_ITEM = 'base-form-item';
const getFormItemLabel = (fieldLabel?: string): JSX.Element | undefined => {
  if (!fieldLabel) {
    return;
  }
  return <label className="form-label">{fieldLabel}</label>;
};

const getFormItemClassName = (fieldClass?: string): string => {
  if (!fieldClass) {
    return '';
  }
  return fieldClass;
};

const getSuffixIcon = (suffixIcon?: JSX.Element): JSX.Element => {
  if (suffixIcon) {
    return suffixIcon;
  }
  return <CaretDownOutlined className="ant-select-suffix" />;
};

const inputElementRender = (input: IBaseFormTextInput): JSX.Element => (
  <CustomInput
    autoComplete={input.autoComplete ? 'on' : 'off'}
    placeholder={input.placeholder ? input.placeholder : ''}
    className={`input-text ${input.className ? input.className : ''}`}
    value={input.value}
    name={input.name}
    disabled={input.disabled}
    bordered={input.bordered}
    maxLength={input.maxLength}
    isResultField={input.isResultField}
    onChange={input.onChange}
    isSpecialFormat={input.isSpecialFormat}
    limitMinValue={input.limitMinValue}
    limitMaxValue={input.limitMaxValue}
    isIgnoreLimitConvert={input.isIgnoreLimitConvert}
    isRoundingString={input.isRoundingString}
  />
);

// eslint-disable-next-line max-lines-per-function
const inputTextRender = (input: IBaseFormTextInput, fieldConfig?: IBaseFormFieldConfig): JSX.Element => {
  let inputElement = inputElementRender(input);

  if (fieldConfig) {
    inputElement = (
      <Row gutter={[16, 16]} className="row-gap-mb">
        <Col className="col-md">{inputElement}</Col>
        {input.unit && <Col className="unit" dangerouslySetInnerHTML={{ __html: input.unit }}></Col>}
      </Row>
    );
  }

  return (
    <div key={input.name} className={fieldConfig ? BASE_FORM_ITEM : ''}>
      <Form.Item
        key={input.name}
        label={getFormItemLabel(fieldConfig?.label)}
        name={input.name}
        rules={input.rules}
        help=""
        className={getFormItemClassName(fieldConfig?.className)}
      >
        {inputElement}
      </Form.Item>
      {input.note && noteField(input.note)}
    </div>
  );
};

const selectRender = (
  input: IBaseFormSelectInput,
  fieldConfig?: IBaseFormFieldConfig,
  selectWidth?: number,
): JSX.Element => {
  return (
    <div key={input.name} className={fieldConfig ? BASE_FORM_ITEM : ''}>
      <Form.Item
        label={getFormItemLabel(fieldConfig?.label)}
        name={input.name}
        help=""
        className={getFormItemClassName(fieldConfig?.className)}
      >
        <Select
          dropdownMatchSelectWidth={(!!selectWidth && selectWidth) || false}
          suffixIcon={getSuffixIcon(input.suffixIcon)}
          onChange={input.onChange}
        >
          {input.options.map((item) => (
            <Select.Option key={item.key ? item.key : item.value} value={item.value}>
              <div dangerouslySetInnerHTML={{ __html: item.label }}></div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {input.note && <div className="base-form-note">{input.note}</div>}
    </div>
  );
};

export const radioGroupRender = (input: IBaseFormRadioGroup, fieldConfig?: IBaseFormFieldConfig): JSX.Element => {
  return (
    <div key={input.name} className={fieldConfig ? BASE_FORM_ITEM : ''}>
      <Form.Item
        label={getFormItemLabel(fieldConfig?.label)}
        name={input.name}
        className={`radio-button ${input.className}`}
        help=""
      >
        <Radio.Group defaultValue={input.value} onChange={input.onChange}>
          {input.options.map((item) => {
            return (
              <Radio key={item.key ? item.key : item.value} value={item.value}>
                {item.label}
              </Radio>
            );
          })}
        </Radio.Group>
      </Form.Item>
      {input.note && noteField(input.note)}
    </div>
  );
};

export const getInitValue = (fields: IBaseFormField[]): Store => {
  const initValue: Store = {};
  fields.forEach((field) => {
    field.inputs.forEach((input) => {
      initValue[input.name] = input.value;
    });
  });

  return initValue;
};

export const inputRender = (input: IBaseFormInput, fieldConfig?: IBaseFormFieldConfig): JSX.Element => {
  let content: JSX.Element | null = null;
  switch (input.type) {
    case BaseFormInputType.Text:
      content = inputTextRender(input, fieldConfig);
      break;
    case BaseFormInputType.Select:
      content = selectRender(input, fieldConfig, input.selectWidth);
      break;
    case BaseFormInputType.RadioGroup:
      content = radioGroupRender(input, fieldConfig);
      break;
    default:
      break;
  }

  return content ? content : <></>;
};

export const inputGroupRender = (field: IBaseFormField, i: number): JSX.Element => {
  return (
    <div key={i} className="base-form-item">
      <Form.Item
        className={`input-group ${field.className ? field.className : ''}`}
        label={getFormItemLabel(field.label)}
      >
        <Input.Group>
          <Row gutter={[16, 16]} className="row-gap-mb">
            {field.inputs.map((input) => {
              return (
                <Col key={input.name} className="col-md base-form-input">
                  {inputRender(input)}
                </Col>
              );
            })}
          </Row>
        </Input.Group>
        {field.note && <div className="base-form-note base-form-note-group"> {field.note}</div>}
      </Form.Item>
    </div>
  );
};

export const formHeaderRender = (formOptions: IBaseFormOptions): JSX.Element | undefined => {
  let noteEle = null;
  let titleEle = null;

  if (formOptions.note) {
    noteEle = (
      <div className="note note--size-big mb-2">
        <span className="text-default">{formOptions.note}</span>
      </div>
    );
  }

  if (formOptions.title) {
    titleEle = (
      <div id="primary-title" className="title">
        {formOptions.title}
      </div>
    );
  }

  if (titleEle || noteEle) {
    return (
      <Space direction="vertical">
        {noteEle}
        {titleEle}
      </Space>
    );
  }
};

function noteField(note: JSX.Element): JSX.Element {
  return (
    <div className="flex flex-wrap">
      <div className="ant-form-item-no-colon"></div>
      <div className="base-form-note base-form-note-group">{note}</div>
    </div>
  );
}
