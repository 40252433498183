/* eslint-disable max-lines-per-function */
/**
 * Liquid Viscosity Conversion Unit Page
 *
 * Created: 2022/14/12
 * __author__: DucND16
 * __copyright__:Copyright KITZ Inc. 2022 All Rights Reserved
 * __version__: 1.0
 */
import { Form } from 'antd';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '../../components/BaseForm';
import { BaseFormInputType, IBaseFormOptions } from '../../components/BaseForm/Interface';
import PageHeading from '../../components/Heading/PageHeading';
import UnitTable from '../../components/UnitTable/UnitTable';
import { getNegativeNumberRule, limitMinToDisplayExpo } from '../../constants/rules';
import { STATUS_CODE } from '../../constants/statusCode';
import { ERROR_CODE } from '../../constants/units';
import { debounceFormValueChange } from '../../helpers/debounce';
import rounding, { EMPTY_STRING } from '../../helpers/utils';
import { useCustomForm } from '../../hooks/useCustomForm';
import i18n from '../../i18n';
import PageContainer from '../../layouts/PageContainer';
import { getLiquidViscosityConversion, IResponseData } from '../../services/liquidViscosity';
import '../../styles/pages/LiquidViscosityUnit.scss';

import { IViscosityLiquidUnit, VISCOSITY_LIQUID_UNITS } from '../FlowUnit/constants';

// eslint-disable-next-line max-lines-per-function
/**
 * Liquid Viscosity Conversion Unit UI
 *
 * Created: 2022/14/12
 *  @author DucND16
 */
export default function LiquidViscosityUnit(): JSX.Element {
  const { t } = useTranslation();
  const [selectInputUnit, setSelectInputUnit] = useState<string>(VISCOSITY_LIQUID_UNITS[0].value || '');
  const [selectConvertUnit, setSelectConvertUnit] = useState<string>(VISCOSITY_LIQUID_UNITS[1].value || '');
  const [tableValue, setTableValue] = useState<IViscosityLiquidUnit[]>(VISCOSITY_LIQUID_UNITS);
  const [errorMes, setErrorMes] = useState<string>('');
  const [form] = useCustomForm(Form.useForm());

  /**
   * Call api
   *
   * Created: 2022/14/12
   *  @author DucND14
   */
  const fetchLiquidViscosity = async (): Promise<void> => {
    const resultRes = await getLiquidViscosityConversion(
      form.getFieldValue('inputValue'),
      form.getFieldValue('selectInput'),
      form.getFieldValue('waterValue'),
    );
    if (resultRes.data.code === STATUS_CODE.SUCCESS) {
      const tableData: IResponseData = resultRes.data.data;

      if (tableData[`${form.getFieldValue('selectConvert')}`] !== undefined) {
        form.setFieldValue('convertValue', rounding(Number(tableData[`${form.getFieldValue('selectConvert')}`])));
      } else {
        form.setFieldValue('convertValue', '');
      }

      const tableInitData: IViscosityLiquidUnit[] = JSON.parse(JSON.stringify(VISCOSITY_LIQUID_UNITS));
      const tableConvertData: IViscosityLiquidUnit[] = tableInitData.map((item) => {
        if (tableData[item.value || '-1']) {
          return { ...item, result: tableData[item.value || '-1'] };
        }
        return item;
      });
      setTableValue(tableConvertData);
    } else {
      if (resultRes.data.errors?.validate?.T?.[ERROR_CODE]) {
        setErrorMes(`${t(resultRes.data.errors.validate.T?.[ERROR_CODE].toString())}`);
      }
    }
  };

  /**
   * Check value input disabled
   *
   * Created: 2022/15/12
   *  @author DucND14
   */
  const checkIsInputDisabled = (): boolean => {
    const inputSelecteValue = cloneDeep(VISCOSITY_LIQUID_UNITS).find(
      (item) => item.value === form.getFieldValue('selectInput'),
    );
    const convertSelecteValue = cloneDeep(VISCOSITY_LIQUID_UNITS).find(
      (item) => item.value === form.getFieldValue('selectConvert'),
    );
    if (inputSelecteValue?.tab === convertSelecteValue?.tab) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (errorMes) {
      setTableValue(VISCOSITY_LIQUID_UNITS);
      form.setFieldValue('convertValue', `${t('CM_0178')}`);
    }
  }, [errorMes]);

  /**
   * Handle form values change
   *
   * Created: 2022/14/12
   *  @author DucND16
   */
  const onFormValuesChange = (): void => {
    const fieldArray = ['inputValue'];
    if (checkIsInputDisabled()) {
      form.setFieldValue('waterValue', '');
    } else {
      fieldArray.push('waterValue');
    }

    form
      .validateFields()
      .then(() => {
        setErrorMes('');
        if (form.getFormEmtyOrError(fieldArray).isOneFieldEmpty) {
          setTableValue(VISCOSITY_LIQUID_UNITS);
          form.setFieldValue('convertValue', EMPTY_STRING);
          return;
        }
        fetchLiquidViscosity();
      })
      .catch(() => setErrorMes(form.customError[0]?.msg));
  };

  const handleFormBlur = debounceFormValueChange(() => {
    if (!form.getFieldValue('inputValue') && !form.getFieldValue('waterValue')) {
      form.setFields([
        {
          name: 'inputValue',
          errors: [],
        },
        {
          name: 'waterValue',
          errors: [],
        },
      ]);
      setErrorMes('');
      form.setFieldValue('convertValue', '');
    }
  });
  const baseFormOpt: IBaseFormOptions = {
    form: form,
    title: t('CM_0161'),
    note: t('CM_0106'),
    className: i18n.language,
    onFormValuesChange: onFormValuesChange,
    onFormBlur: handleFormBlur,
    fields: [
      {
        label: t('CM_0083'),
        className: '',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'inputValue',
            placeholder: t('CM_0129'),
            rules: getNegativeNumberRule(t('CM_0107'), `${t('CM_0172')}`, false, undefined, true),
          },
          {
            type: BaseFormInputType.Select,
            name: 'selectInput',
            selectWidth: i18n.language === 'en' ? 258 : 0,
            value: selectInputUnit,
            onChange: setSelectInputUnit,
            options: VISCOSITY_LIQUID_UNITS.map((item) => ({
              value: item.value,
              label: `${t(item.label)} ${item.unit}`,
            })),
          },
        ],
      },
      {
        label: t('CM_0084'),
        note: <span className="note1">{t('CM_0162')}</span>,
        className: 'after-convert flex-start margin-top-16-12',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'convertValue',
            isResultField: true,
            className: 'input-result',
          },
          {
            type: BaseFormInputType.Select,
            name: 'selectConvert',
            selectWidth: i18n.language === 'en' ? 258 : 0,
            value: selectConvertUnit,
            onChange: setSelectConvertUnit,
            options: VISCOSITY_LIQUID_UNITS.map((item) => ({
              value: item.value,
              label: `${t(item.label)} ${item.unit}`,
            })),
          },
        ],
      },
      {
        label: t('CM_0096'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'waterValue',
            placeholder: !checkIsInputDisabled() ? (t('CM_0129') as string) : '',
            rules: !checkIsInputDisabled()
              ? getNegativeNumberRule(t('CM_0190'), undefined, false, undefined, true)
              : [],
            disabled: checkIsInputDisabled(),
            unit: `(${t('CM_0009')})`,
          },
        ],
      },
    ],
  };

  const children = (
    <>
      <PageHeading borderColor="san-mario">{t('CM_0105')}</PageHeading>

      <div className="liquid-viscosity-unit">
        <BaseForm options={baseFormOpt}></BaseForm>
        <div className="result-container">
          <div className="result">
            <div className="title">{t('CM_0085')}</div>
            <div className="error-message">{errorMes}</div>
          </div>
          <div className="result-table">
            <UnitTable
              dataTable1={tableValue.slice(0, 6)}
              titleTable1="CM_0107"
              dataTable2={tableValue.slice(6)}
              titleTable2="CM_0108"
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <PageContainer>{children}</PageContainer>
    </>
  );
}
