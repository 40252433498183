/**
 * Calculate liquid flow velocity page
 *
 * Created: 2022/12/31
 * __author__: DuongDL
 * __copyright__: Copyright KITZ Corporation. 2022 All Rights Reserved
 * __version__: 1.0
 */
import { useEffect, useState, useRef, ReactElement } from 'react';
import { Col, Form, Row } from 'antd';
import { CommonUnit } from '../../constants/units';
import PageContainer from '../../layouts/PageContainer';
import '../../styles/pages/FlowVelocity.scss';
import { useTranslation } from 'react-i18next';
import PageHeading from '../../components/Heading/PageHeading';
import { getNegativeNumberRule } from '../../constants/rules';
import { cloneDeep } from 'lodash';
import { debounceFormValueChange } from '../../helpers/debounce';
import { STATUS_CODE } from '../../constants/statusCode';
import UnitTable from '../../components/UnitTable/UnitTable';
import { LIQUID_VELOCITY_UNITS } from './constants';
import { useCustomForm } from '../../hooks/useCustomForm';
import { getResultAPI } from '../../services/http-client';
import { API_PATHS } from '../../constants/apiPaths';
import { BaseForm } from '../../components/BaseForm';
import { BaseFormInputType, IBaseFormOptions } from '../../components/BaseForm/Interface';
import { VelocityUnitsID } from '../../constants/unitsID';
import { EMPTY_STRING } from '../../helpers/utils';

const GF = 1;

//eslint-disable-next-line max-lines-per-function
export default function VelocityLiquid(): ReactElement<Element> {
  const { t } = useTranslation();

  const vUnit = [
    { value: VelocityUnitsID.ms, label: 'm/s' },
    { value: VelocityUnitsID.fts, label: 'ft/s' },
  ];
  const [errorMessage, setErrorMessage] = useState<string>();
  const [currentVUnit, setCurrentVUnit] = useState<string>(vUnit[0].value);

  const tableData = cloneDeep(LIQUID_VELOCITY_UNITS);
  const [inputForm] = useCustomForm(Form.useForm());
  const [GPResult, setGPResult] = useState<CommonUnit[]>(tableData.gaugePressure);
  const [APResult, setAPResult] = useState<CommonUnit[]>(tableData.absolutePressure);
  const refVUnit = useRef<string>(vUnit[0].value);

  /**
   * Get data form input fields
   *
   * Created: 2022/15/12
   *  @author DienPD1
   */
  const onFormValuesChange = (): void => {
    inputForm
      .validateFields()
      .then(() => {
        setErrorMessage('');
        if (!Number(inputForm.getFieldValue('dValue')) || !Number(inputForm.getFieldValue('vValue'))) {
          setAPResult(tableData.absolutePressure);
          setGPResult(tableData.gaugePressure);
          return;
        }
        getResult();
      })
      .catch(() => {
        setErrorMessage(inputForm.customError[0]?.msg);
        setAPResult(tableData.absolutePressure);
        setGPResult(tableData.gaugePressure);
      });
  };
  useEffect(() => {
    getResult();
  }, [currentVUnit]);

  /**
   * Send input to BE and call API
   *
   * Created: 2022/15/12
   *  @author DienPD1
   */
  // eslint-disable-next-line max-lines-per-function
  const getResult = async (): Promise<void> => {
    try {
      const dataPost = {
        D: Number(inputForm.getFieldValue('dValue')),
        V: Number(inputForm.getFieldValue('vValue')),
        V_unit_in: refVUnit.current,
        Gf: Number(inputForm.getFieldsValue().GfValue) || GF,
      };
      const response = await getResultAPI(dataPost, API_PATHS.CALC_FR_FROM_VEL_LIQ);
      if (response?.data && response.code === STATUS_CODE.SUCCESS) {
        const responseData = response.data;
        if (Number(inputForm.getFieldsValue().GfValue)) {
          setGPResult(
            tableData.gaugePressure.map((item: CommonUnit) => {
              if (item.value) {
                return { ...item, result: responseData[item.id as string] };
              }
              return item;
            }),
          );
        } else {
          setGPResult(tableData.gaugePressure);
        }
        setAPResult(
          tableData.absolutePressure.map((item: CommonUnit) => {
            if (item.value) {
              return { ...item, result: responseData[item.id as string] };
            }
            return item;
          }),
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Check value input disabled
   *
   * Created: 2022/15/12
   *  @author KhaiLQ7
   */
  // const checkIsInputDisabled = () => {
  //   const inputSelecteValue = cloneDeep(LIQUID_VELOCITY_UNITS).find(
  //     (item) => item.value === inputForm.getFieldValue('selectInput'),
  //   );
  //   const convertSelecteValue = cloneDeep(LIQUID_VELOCITY_UNITS).find(
  //     (item) => item.value === inputForm.getFieldValue('selectConvert'),
  //   );
  //   if (inputSelecteValue?.tab === convertSelecteValue?.tab) {
  //     return true;
  //   }
  //   return false;
  // };

  const resultTableItems = [
    {
      label: t('CM_0035'),
      key: 'absolutePressure',
      children: (
        <UnitTable
          dataTable1={APResult}
          titleTable1={t('CM_0035') as string}
          dataTable2={GPResult}
          titleTable2={t('CM_0036') as string}
        />
      ),
    },
  ];

  const resultTables = resultTableItems.map((item) => {
    return item.children;
  });

  const resultsContainer = (
    <div className="input-container flow-velocity-liq">
      <Row>
        <Col>
          <div className="title-result">{t('CM_0010')}</div>
        </Col>
        <Col>
          <div className="error-result">{errorMessage ? errorMessage : EMPTY_STRING}</div>
        </Col>
      </Row>
      <div className="velocity-liquid-result-container">
        <div className="result-table">{resultTables}</div>
      </div>
    </div>
  );

  const baseFormOpt: IBaseFormOptions = {
    form: inputForm,
    onFormValuesChange: debounceFormValueChange(onFormValuesChange),
    fields: [
      {
        label: t('CM_0075'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'dValue',
            rules: getNegativeNumberRule(t('CM_0191'), '', false, 1, true),
            placeholder: t('CM_0129'),
            unit: 'mm',
          },
        ],
      },
      {
        label: t('CM_0165'),
        note: <>{t('CM_0162')}</>,
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'vValue',
            rules: getNegativeNumberRule(t('CM_0014'), '', false, 3, true),
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            name: 'rateUnit',
            note: <>{t('CM_0015')}</>,
            value: vUnit[0].value,
            options: vUnit.map((item) => ({
              key: item.value,
              value: item.value,
              label: `${t(item.label)}`,
            })),
            onChange: (item: string): void => {
              refVUnit.current = item;
              setCurrentVUnit(item as string);
            },
          },
        ],
      },
      {
        label: t('CM_0008'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'GfValue',
            rules: getNegativeNumberRule(t('CM_0190'), '', false, 2, true),
            placeholder: t('CM_0129'),
            unit: `(${t('CM_0009')})`,
          },
        ],
      },
    ],
  };

  const inputContainer = <BaseForm options={baseFormOpt}></BaseForm>;

  const children = (
    <>
      <PageHeading borderColor="navy">{t('CM_0079')}</PageHeading>
      {inputContainer}
      <Form colon={false}>{resultsContainer}</Form>
    </>
  );

  return (
    <div className="velocity-liquid-container">
      <PageContainer>{children}</PageContainer>
    </div>
  );
}
