/**
 * Calculate different pressure liquid page
 *
 * Created: 2022/18/12
 * __author__: DienPD1
 * __copyright__:Copyright KITZ Inc. 2022 All Rights Reserved
 * __version__: 1.0
 */
import { useRef, useState } from 'react';
import { Form, Row } from 'antd';
import PageContainer from '../../layouts/PageContainer';
import '../../styles/pages/DifferentialPressure.scss';
import { useTranslation } from 'react-i18next';
import UnitTable from '../../components/UnitTable/UnitTable';
import apiClient, { AxiosResponseData, ErrorType, TableData } from '../../services/http-client';
import { IDifferentialLiquidCalculation } from '../../interfaces/differentialPressureCalculation';
import { API_PATHS } from '../../constants/apiPaths';
import PageHeading from '../../components/Heading/PageHeading';
import {
  DIFF_PRESSURE_GAS_TABLE_UNITS,
  DIFF_PRESSURE_LIQUID_FLOW_UNITS,
  pressureRatioString,
  PRESSURE_CLASSES,
  PRESSURE_UNITS,
} from './constants';
import { CommonUnit } from '../../constants/units';
import { useCustomForm } from '../../hooks/useCustomForm';
import { getDefaultRule, getNegativeNumberRule } from '../../constants/rules';
import { getMessageByErrorCode } from '../../helpers/common';
import { CustomInput } from '../../components/CustomInput/CustomInput';
import { EMPTY_STRING, isNumeric, isValidateFieldsNotEmpty } from '../../helpers/utils';
import { BaseFormInputType, IBaseFormOptions } from '../../components/BaseForm/Interface';
import { BaseForm } from '../../components/BaseForm';
import { displayPressureRatioResponse } from '../../helpers/utils';
import { debounceFormValueChange } from '../../helpers/debounce';

/**
 * Calculate differential pressure gas UI
 *
 * Created: 2022/18/12
 *  @author DienPD1
 */
// eslint-disable-next-line max-lines-per-function
export default function PressureLiquidCalculation(): JSX.Element {
  const { t } = useTranslation();
  const [inputForm] = useCustomForm(Form.useForm());
  const validateFields = ['cvValue', 'p1', 'flowRateQ', 'Gf'];
  const beValidateErrorCodes = [1507, 1511, 2001];
  const [errorMessage, setErrorMessage] = useState<string>();
  const [judgementMessage, setJudgementMessage] = useState<string>();
  const [gasVolumes, setGasVolumes] = useState<CommonUnit[]>(DIFF_PRESSURE_GAS_TABLE_UNITS);

  const pressureRatio = useRef('');

  /**
   * Handle form values change
   *
   * Created: 2022/18/12
   *  @author DienPD1
   */
  const onFormValuesChange = (): void => {
    const isFormValid = isValidateFieldsNotEmpty(inputForm, validateFields);
    !isFormValid && (pressureRatio.current = '');
    inputForm
      .validateFields(validateFields)
      .then(() => {
        setErrorMessage('');
        setJudgementMessage('');
        isFormValid && getResult();
        if (isFormValid) {
          getResult();
        } else {
          setGasVolumes(DIFF_PRESSURE_GAS_TABLE_UNITS);
        }
      })
      .catch(() => {
        setErrorMessage(inputForm.customError[0]?.msg);
        setJudgementMessage(`${t('CM_0178')}`);
        pressureRatio.current = '';
        setGasVolumes(DIFF_PRESSURE_GAS_TABLE_UNITS);
      });
  };

  /**
   * Get data form input fields
   *
   * Created: 2022/18/12
   *  @author DienPD1
   */
  const getInputData = (): IDifferentialLiquidCalculation => {
    return {
      Cv: Number(inputForm.getFieldValue('cvValue')),
      P1: Number(inputForm.getFieldValue('p1')),
      P_unit_in: `${inputForm.getFieldValue('pressureClass')}${inputForm.getFieldValue(
        'pressureUnit',
      )}`.toLocaleUpperCase(),
      Q: Number(inputForm.getFieldValue('flowRateQ')),
      Q_unit_in: inputForm.getFieldValue('qUnit'),
      Gf: Number(inputForm.getFieldValue('Gf')),
    };
  };

  const setJudgementMessageByRatio = (): void => {
    if (Number(pressureRatio.current) < 0.5) {
      setJudgementMessage(`${t('CM_0040')}`);
    } else {
      setJudgementMessage(`${t('CM_0196')}`);
    }
  };

  /**
   * Call api get pressure results
   *
   * Created: 2022/18/12
   *  @author DienPD1
   */
  // eslint-disable-next-line max-lines-per-function, complexity
  async function getResult(): Promise<void> {
    try {
      const response = await apiClient.post(API_PATHS.CALC_DIFF_PRESS_LIQUID, getInputData());
      const data = response.data as AxiosResponseData;
      if (data.customError) {
        const customError = data.customError;
        const errCode = customError.code[0];
        if (customError.type === ErrorType.Validate) {
          const fieldName = customError.field?.[0];
          if (errCode && beValidateErrorCodes.includes(errCode)) {
            setErrorMessage(getMessageByErrorCode(errCode, fieldName));
            setJudgementMessage(`${t('CM_0196')}`);
          } else {
            setJudgementMessage(`${t('CM_0040')}`);
          }
        } else {
          if (errCode === beValidateErrorCodes[2]) {
            // 2001
            setErrorMessage(getMessageByErrorCode(errCode));
            setJudgementMessage(`${t('CM_0196')}`);
          } else {
            setJudgementMessage(getMessageByErrorCode(errCode));
          }
        }
        pressureRatio.current = `${t('CM_0203')}`;
        setGasVolumes(DIFF_PRESSURE_GAS_TABLE_UNITS);

        return;
      }
      data.data && (pressureRatio.current = `${(data.data as TableData)[pressureRatioString]}`);
      setJudgementMessageByRatio();
      setResultToTable(data);
    } catch (err) {
      console.log('err', err);
    }
  }

  /**
   * Set calculated results to table
   *
   * Created: 2022/18/12
   *  @author DienPD1
   */
  const setResultToTable = (data: AxiosResponseData): void => {
    const tableData = data.data as TableData;
    const volumeUnits = gasVolumes.map((item) => {
      if (tableData[item.value] || tableData[item.value] === 0) {
        return { ...item, result: tableData[item.value] };
      }
      return item;
    });
    setGasVolumes(volumeUnits);
  };

  const baseFormOpt: IBaseFormOptions = {
    form: inputForm,
    onFormValuesChange: debounceFormValueChange(onFormValuesChange),
    fields: [
      {
        label: t('CM_0011'),
        className: 'pressure-gases',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'cvValue',
            rules: getNegativeNumberRule(t('CM_0011'), undefined, undefined, undefined, true),
            placeholder: t('CM_0129'),
          },
        ],
      },
      {
        label: t('CM_0002'),
        className: 'pressure-gases',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'flowRateQ',
            rules: getNegativeNumberRule(t('CM_0160'), undefined, undefined, undefined, true),
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            name: 'qUnit',
            value: DIFF_PRESSURE_LIQUID_FLOW_UNITS[0].value,
            options: DIFF_PRESSURE_LIQUID_FLOW_UNITS.map((item) => ({
              key: item.unit,
              value: item.value,
              label: `${t(item.prefix as string)} ${item.unit}`,
            })),
          },
        ],
      },
      {
        label: t('CM_0003'),
        className: 'pressure-gases',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'p1',
            rules: getDefaultRule(t(`${t('CM_0194')}`), undefined, true),
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            name: 'pressureUnit',
            value: PRESSURE_UNITS[0].value,
            options: PRESSURE_UNITS.map((item) => ({
              key: item.unit,
              value: item.value,
              label: `${item.unit}`,
            })),
          },
          {
            type: BaseFormInputType.Select,
            name: 'pressureClass',
            value: PRESSURE_CLASSES[0].value,
            options: PRESSURE_CLASSES.map((item) => ({
              key: item.unit,
              value: item.value,
              label: t(item.name),
            })),
          },
        ],
      },
      {
        label: t('CM_0008'),
        className: 'pressure-gases',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'Gf',
            rules: getNegativeNumberRule(t('CM_0190'), undefined, undefined, undefined, true),
            placeholder: t('CM_0129'),
            unit: `(${t('CM_0009')})`,
          },
        ],
      },
    ],
  };

  const children = (
    <div className="diff-pressure-container liquid">
      <PageHeading borderColor="san-mario">{t('CM_0061')}</PageHeading>
      <BaseForm options={baseFormOpt}></BaseForm>
      <div className=" result-container">
        <div className="result">
          <div className="title">{t('CM_0010')}</div>
          <div className="error-message">{errorMessage}</div>
        </div>
        <Row gutter={16} className="include-result">
          <Row className="include-result__col">
            <div className="flex dental-container" style={{ marginLeft: '8px', marginRight: '103px' }}>
              <div className="judgement" style={{ width: '96px', marginRight: '16px' }}>
                <div className="title" style={{ width: '96px' }}>
                  {t('CM_0037')}
                </div>
              </div>

              <CustomInput
                className="input-result"
                value={judgementMessage?.toString()}
                disabled={true}
                placeholder=""
                isResultField={true}
              />
            </div>
          </Row>
          <Row className="include-result__col">
            <div className="flex dental-container">
              <div className="judgement " style={{ marginRight: '16px' }}>
                <div className="unit" style={{ width: '96px' }}>
                  {t('CM_0012')}
                </div>
              </div>
              <CustomInput
                className={`text-right input-result ${
                  isNumeric(pressureRatio.current) &&
                  Number(displayPressureRatioResponse(pressureRatio.current)) >= 0.5
                    ? 'highlight-input'
                    : ''
                }`}
                value={
                  pressureRatio.current
                    ? Number(pressureRatio.current)
                      ? displayPressureRatioResponse(pressureRatio.current)
                      : pressureRatio.current
                    : EMPTY_STRING
                }
                disabled={true}
                placeholder=""
                isResultField={true}
                isSpecialFormat={true}
              />
            </div>
          </Row>
        </Row>
        <div className="result-table">
          <UnitTable dataTable1={gasVolumes} titleTable1="CM_0156" />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <PageContainer>{children}</PageContainer>
    </>
  );
}
