/**
 * Calculate liquid flow rate page
 *
 * Created: 2022/15/12
 * __author__: DienPD1
 * __copyright__:Copyright KITZ Inc. 2022 All Rights Reserved
 * __version__: 1.0
 */
import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'antd';
import PageContainer from '../../layouts/PageContainer';
import '../../styles/pages/FlowLiquidCalculation.scss';
import { useTranslation } from 'react-i18next';
import {
  PRESSURE_CLASSES,
  PRESSURE_UNITS,
  VOLUME_FLOW_LIQUID_TABLE_UNITS,
  MASS_FLOW_LIQUID_TABLE_UNITS,
} from './constants';
import {
  getCalculateDifferentPressure,
  getCalculatePressureRatio,
  getFlowLiquidCalculateResult,
} from '../../services/flowCalculation';
import { IFlowLiquidCalculationReq } from '../../interfaces/flowCalculation';
import { CommonUnit } from '../../constants/units';
import PageHeading from '../../components/Heading/PageHeading';
import {
  displayPressureRatioResponse,
  displayResponseData,
  EMPTY_STRING,
  isCannotCalcPressure,
  isNumeric,
  isValidateFieldsNotEmpty,
} from '../../helpers/utils';
import UnitTable from '../../components/UnitTable/UnitTable';
import { getDefaultRule, getNegativeNumberRule } from '../../constants/rules';
import { useCustomForm } from '../../hooks/useCustomForm';
import { getMessageByErrorCode } from '../../helpers/common';
import { CustomInput } from '../../components/CustomInput/CustomInput';
import { BaseForm } from '../../components/BaseForm';
import { BaseFormInputType, IBaseFormOptions } from '../../components/BaseForm/Interface';
import { AxiosResponseData, ErrorType, TableData } from '../../services/http-client';
import { PressureUnitsID } from '../../constants/unitsID';
import { SpecialCodes } from '../../constants/errorCode';

/**
 * Calculate liquid flow rate UI
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
// eslint-disable-next-line max-lines-per-function
export default function FlowLiquidCalculation(): JSX.Element {
  const { t } = useTranslation();
  const validateFields = ['cvValue', 'p1', 'p2', 'Gf'];
  const beValidateErrorCodes = [1507, 1511];
  const [inputForm] = useCustomForm(Form.useForm());
  const [errorMessage, setErrorMessage] = useState<string>();
  const [judgementMessage, setJudgementMessage] = useState<string>(EMPTY_STRING);
  const [p1, onP1Change] = useState<string>();
  const [p2, onP2Change] = useState<string>();
  const [pressureUnit, onPressureUnitSelect] = useState<string>(PRESSURE_UNITS[0].value);
  const [pressureClass, onPressureClassSelect] = useState<string>(PRESSURE_CLASSES[0].value);
  const [deltaP, setDifferentPressure] = useState<string>();
  const [p2Unit, setP2Unit] = useState<string>(EMPTY_STRING);
  const [deltaPUnit, setDeltaPUnit] = useState<string>(EMPTY_STRING);
  const [pressureRatio, setPressureRatio] = useState<string>(EMPTY_STRING);
  const [flowVolumeUnits, setFlowVolumeUnits] = useState<CommonUnit[]>(VOLUME_FLOW_LIQUID_TABLE_UNITS);
  const [flowMassUnits, setFlowMassUnits] = useState<CommonUnit[]>(MASS_FLOW_LIQUID_TABLE_UNITS);
  const isCriticalPressure = useRef<boolean>(false);
  /**
   * Handle form values change
   *
   * Created: 2022/15/12
   *  @author DienPD1
   */
  const onFormValuesChange = (): void => {
    const isFormValid = isValidateFieldsNotEmpty(inputForm, validateFields);
    const isPressFieldsValid = isValidateFieldsNotEmpty(inputForm, ['p1', 'p2']);
    !isPressFieldsValid && setPressureRatio(EMPTY_STRING);
    inputForm
      .validateFields(validateFields)
      .then(() => {
        !isCriticalPressure.current && setErrorMessage(EMPTY_STRING);
        if (inputForm.getFormEmtyOrError(validateFields).isOneFieldEmpty) {
          resetTable();
          return;
        }
        isFormValid && getResult();
      })
      .catch(() => {
        if (isCannotCalcPressure('p1', inputForm.customError) || isCannotCalcPressure('p2', inputForm.customError)) {
          setPressureRatio(EMPTY_STRING);
        }
        setErrorMessage(inputForm.customError[0]?.msg);
        setJudgementMessage(`${t('CM_0178')}`);
        resetTable();
      });
  };

  /**
   * Reset table data
   *
   * Created: 2022/9/2
   *  @author DienPD1
   */
  const resetTable = (): void => {
    setFlowVolumeUnits(VOLUME_FLOW_LIQUID_TABLE_UNITS);
    setFlowMassUnits(MASS_FLOW_LIQUID_TABLE_UNITS);
  };

  /**
   * Get data form input fields
   *
   * Created: 2022/15/12
   *  @author DienPD1
   */
  const getInputData = (): IFlowLiquidCalculationReq => {
    return {
      Cv: Number(inputForm.getFieldValue('cvValue')),
      P1: Number(inputForm.getFieldValue('p1')),
      P2: Number(inputForm.getFieldValue('p2')),
      Gf: Number(inputForm.getFieldValue('Gf')),
      P_unit_in: `${inputForm.getFieldValue('pressureClass')}${inputForm.getFieldValue(
        'pressureUnit',
      )}`.toLocaleUpperCase(),
    };
  };

  /**
   * Call api get calculated liquid flow rate
   *
   * Created: 2022/15/12
   *  @author DienPD1
   */
  // eslint-disable-next-line max-lines-per-function, complexity
  const getResult = async (): Promise<void> => {
    try {
      const response = await getFlowLiquidCalculateResult(getInputData());
      const data = response.data as AxiosResponseData;
      if (data.customError) {
        const customError = data.customError;
        const errCode = customError.code[0];
        if (customError.type === ErrorType.Validate) {
          const fieldName = customError.field?.[0];
          if (errCode && beValidateErrorCodes.includes(errCode)) {
            setErrorMessage(getMessageByErrorCode(errCode, fieldName));
            setJudgementMessage(`${t('CM_0178')}`);
          } else {
            setJudgementMessage(`${t('CM_0040')}`);
          }
        }
        resetTable();

        return;
      }
      setResultToTable(data);
    } catch (error) {
      console.log(error);
    }
  };

  const setJudgementMessageByRatio = (value: string): void => {
    if (Number(value) < 0.5) {
      setJudgementMessage(`${t('CM_0040')}`);
      isCriticalPressure.current = false;
      setErrorMessage(EMPTY_STRING);
    } else {
      setErrorMessage(`${t(SpecialCodes.CRITICAL_STATE.toString())}`);
      isCriticalPressure.current = true;
      setJudgementMessage(`${t('CM_0196')}`);
    }
  };

  /**
   * Set calculated results to table
   *
   * Created: 2022/15/12
   *  @author DienPD1
   */
  const setResultToTable = (data: AxiosResponseData): void => {
    const tableData = data.data as TableData;
    const volumeUnits = flowVolumeUnits.map((item) => {
      if (tableData[item.value] || tableData[item.value] === 0) {
        return { ...item, result: tableData[item.value] };
      }
      return item;
    });
    const massUnits = flowMassUnits.map((item) => {
      if (tableData[item.value] || tableData[item.value] === 0) {
        return { ...item, result: tableData[item.value] };
      }
      return item;
    });
    setFlowVolumeUnits(volumeUnits);
    setFlowMassUnits(massUnits);
  };

  /**
   * Call api get differential pressure and pressure ratio
   *
   * Created: 2022/15/12
   *  @author DienPD1
   */
  const calculatePressure = async (p1: number, p2: number): Promise<void> => {
    try {
      const [differentPressureRes, pressureRatioRes] = await Promise.all([
        getCalculateDifferentPressure({ P1: p1, P2: p2 }),
        getCalculatePressureRatio({
          P1: p1,
          P2: p2,
          P_unit_in: `${inputForm.getFieldValue('pressureClass')}${inputForm.getFieldValue(
            'pressureUnit',
          )}`.toLocaleUpperCase(),
        }),
      ]);
      const diffPressureData = differentPressureRes.data as AxiosResponseData;
      if (!diffPressureData.customError && (diffPressureData.data?.value || diffPressureData.data?.value === 0)) {
        setDifferentPressure(`${displayResponseData(diffPressureData.data?.value)}`);
      } else {
        setDifferentPressure(EMPTY_STRING);
      }
      const pressureRatioData = pressureRatioRes.data as AxiosResponseData;
      if (!pressureRatioData.customError && pressureRatioData.data?.value) {
        setPressureRatio(`${pressureRatioData.data.value}`);
        setJudgementMessageByRatio(`${pressureRatioData.data.value}`);
      } else {
        setJudgementMessage(EMPTY_STRING);
        setPressureRatio(EMPTY_STRING);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isNumeric(p1) && isNumeric(p2)) {
      calculatePressure(Number(p1), Number(p2));
    } else {
      setDifferentPressure(EMPTY_STRING);
      setErrorMessage(EMPTY_STRING);
      setJudgementMessage(EMPTY_STRING);
    }
  }, [p1, p2, pressureUnit, pressureClass]);

  useEffect(() => {
    const selectedUnit = PRESSURE_UNITS.find((item) => item.value === pressureUnit);
    const selectedClass = PRESSURE_CLASSES.find((item) => item.value === pressureClass);
    if (selectedUnit && selectedClass) {
      if (
        ([PressureUnitsID.mmHgTorr, PressureUnitsID.mmH2OmmAq, PressureUnitsID.psilbfin2] as string[]).includes(
          selectedUnit.value,
        )
      ) {
        const splitUnit = selectedUnit.unit.split(' or ');
        setP2Unit(`${splitUnit[0]}(${selectedClass.unit}) or ${splitUnit[1]}(${selectedClass.unit})`);
      } else {
        setP2Unit(`${selectedUnit.unit}(${selectedClass.unit})`);
      }
      setDeltaPUnit(`${selectedUnit.unit}`);
    }
  }, [pressureUnit, pressureClass]);

  const baseFormOpt: IBaseFormOptions = {
    form: inputForm,
    onFormValuesChange: onFormValuesChange,
    className: 'my-custom-class',
    fields: [
      {
        label: t('CM_0011'),
        className: 'my-input-custom-class',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'cvValue',
            rules: getNegativeNumberRule(t('CM_0011'), undefined, undefined, undefined, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
          },
        ],
      },
      {
        label: t('CM_0003'),
        className: EMPTY_STRING,
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'p1',
            rules: getDefaultRule(t(`${t('CM_0194')}`), undefined, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
            onChange: (e: React.ChangeEvent<HTMLInputElement>): void => onP1Change(e.target.value),
            value: p1,
          },
          {
            type: BaseFormInputType.Select,
            name: 'pressureUnit',
            rules: getDefaultRule(t(`${t('CM_0194')}`), undefined, true),
            value: pressureUnit,
            options: PRESSURE_UNITS.map((item) => ({ key: item.unit, value: item.value, label: item.unit })),
            onChange: (value: string): void => onPressureUnitSelect(value),
          },
          {
            type: BaseFormInputType.Select,
            name: 'pressureClass',
            rules: getDefaultRule(t(`${t('CM_0194')}`), undefined, true),
            value: pressureClass,
            options: PRESSURE_CLASSES.map((item) => ({ key: item.unit, value: item.value, label: t(item.name) })),
            onChange: (value: string): void => onPressureClassSelect(value),
          },
        ],
      },
      {
        label: t('CM_0004'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'p2',
            rules: getDefaultRule(t(`${t('CM_0195')}`), undefined, true),
            placeholder: t('CM_0129'),
            unit: p2Unit,
            onChange: (e: React.ChangeEvent<HTMLInputElement>): void => onP2Change(e.target.value),
            value: p2,
          },
        ],
      },
      {
        label: t('CM_0005'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'delta',
            unit: deltaPUnit,
            value: deltaP,
            isResultField: true,
            disabled: true,
            className: 'input-result',
            isSpecialFormat: true,
          },
        ],
      },
      {
        label: t('CM_0008'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'Gf',
            placeholder: t('CM_0129'),
            rules: getNegativeNumberRule(t('CM_0190'), undefined, undefined, undefined, true),
            unit: `(${t('CM_0009')})`,
          },
        ],
      },
    ],
  };

  const children = (
    <div className="flow-calculation-container fl-liquid-cal liquid">
      <PageHeading borderColor="san-mario">{t('CM_0034')}</PageHeading>
      <BaseForm options={baseFormOpt}></BaseForm>
      <div className="result-container">
        <div className="result">
          <div className="title">{t('CM_0010')}</div>
          <div className="error-message">{errorMessage}</div>
        </div>
        <div className="flex flex-wrap">
          <div className="judgement">
            <div className="title">{t('CM_0037')}</div>
            <Row gutter={16}>
              <Col className="col-md">
                <CustomInput
                  className="input-result"
                  value={judgementMessage}
                  disabled={true}
                  placeholder=""
                  isResultField={true}
                />
              </Col>
            </Row>
          </div>
          <div className="ratio">
            <div className="title">{t('CM_0012')}</div>
            <Row gutter={16}>
              <Col className="col-md">
                <CustomInput
                  className={`text-right input-result ${
                    isNumeric(pressureRatio) && Number(displayPressureRatioResponse(pressureRatio)) >= 0.5
                      ? 'highlight-input'
                      : EMPTY_STRING
                  }`}
                  value={pressureRatio === EMPTY_STRING ? EMPTY_STRING : displayPressureRatioResponse(pressureRatio)}
                  disabled={true}
                  placeholder=""
                  isResultField={true}
                  isSpecialFormat
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="result-table">
          <UnitTable
            dataTable1={flowVolumeUnits}
            titleTable1={t('CM_0035') || EMPTY_STRING}
            dataTable2={flowMassUnits}
            titleTable2={t('CM_0036') || EMPTY_STRING}
          ></UnitTable>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <PageContainer>{children}</PageContainer>
    </>
  );
}
