/**
 * Api paths are used for app
 *
 * Created: 2022/15/12
 * __author__: DienPD1
 * __copyright__:
 * __version__: 1.0
 */
export const API_PATHS = {
  CALC_PRESS_RATIO: '/calcPressRatio',
  CALC_DIFF_PRESS: '/calcDiffPress',
  CALC_FLOW_RATE_LIQUID: '/calcFlowRateLiqSimple',
  CALC_FLOW_RATE_GAS: '/calcFlowRateGasSimple',
  CALC_FLOW_RATE_STEAM: '/calcFlowRateSteamSimple',
  CALC_DIFF_PRESS_LIQUID: '/calcDiffPressLiqSimple',
  CALC_DIFF_PRESS_GAS: '/calcDiffPressGasSimple',
  CALC_DIFF_PRESS_STEAM: '/calcDiffPressSteamSimple',
  CALC_SATURATION_TEMPERATURE: '/calcSaturatedTemp',
  CALC_SUPER_HEAT: '/calcSteamTSH',
  CALC_VEL_FROM_FR_STEAM: '/calcVelFromFlowRateSteam',
  CALC_SATURATED_TEMP: '/calcSaturatedTemp',
  CALC_STEAM_TSH: '/calcSteamTSH',
  CALC_FR_FROM_VEL_LIQ: '/calcFlowRateFromVelLiq',
  CALC_CV_LIQ_SIMPLE: '/calcCvLiqSimple',
  CALC_VEL_FROM_FLOW_RATE_LIQ: '/calcVelFromFlowRateLiq',
  CALC_CV_GAS_SIMPLE: '/calcCvGasSimple',
  CALC_VEL_FROM_FLOW_RATE_GAS: '/calcVelFromFlowRateGas',
  CALC_VEL_FROM_FLOW_RATE_STEAM: '/calcVelFromFlowRateSteam',
  CALC_CV_STEAM_SIMPLE: '/calcCvSteamSimple',
  CALC_FLOW_RATE_FROM_VEL_STEAM: '/calcFlowRateFromVelSteam',
  CALC_FLOW_RATE_FROM_VEL_GAS: '/calcFlowRateFromVelGas',
  CALC_TEMPERATURE_FROM_PRESSURE: '/interpolateYtoX',
  CALC_PRESSURE_FROM_TEMPERATURE: '/interpolateXtoY',
};
