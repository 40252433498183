/**
 * Validate rules are used for flow unit
 *
 * Created: 2022/15/12
 * __author__: KhaiLQ7
 * __copyright__:Copyright KITZ Inc. 2022 All Rights Reserved
 * __version__: 1.0
 */
import { Rule } from 'antd/es/form';
import i18next from '../../i18n';
import { EMPTY_STRING, getJsonValidateError, isNumberLengthInvalid } from '../../helpers/utils';
import { RulePriority } from '../../constants/rules';

export function getTemperatureRule(fieldName: string): Rule[] {
  return [
    {
      validator: (_, value): Promise<void> => {
        if (!value) {
          return Promise.resolve();
        }
        if (!value.toString().trim()) {
          return Promise.reject(i18next.t('CM_0171', { fieldName: fieldName }));
        }
        if (!isNaN(value)) {
          if (isNumberLengthInvalid(value)) {
            return Promise.reject(i18next.t('CM_0173', { fieldName: fieldName }));
          }
          return Promise.resolve();
        } else {
          return Promise.reject(i18next.t('CM_0171', { fieldName: fieldName }));
        }
      },
    },
  ];
}

export function getPressureRule(fieldName: string): Rule[] {
  return [
    {
      validator: (_, value): Promise<void> => {
        if (!value) {
          return Promise.resolve();
        }
        if (!value.toString().trim()) {
          return Promise.reject(i18next.t('CM_0171', { fieldName: fieldName }));
        }
        if (!isNaN(value)) {
          if (isNumberLengthInvalid(value)) {
            return Promise.reject(i18next.t('CM_0173', { fieldName: fieldName }));
          }
          return Promise.resolve();
        } else {
          return Promise.reject(i18next.t('CM_0171', { fieldName: fieldName }));
        }
      },
    },
  ];
}

export function getFlowUnitRule(fieldName: string): Rule[] {
  return [
    {
      validator: (_, value): Promise<void> => {
        if (!value) {
          return Promise.resolve();
        }
        if (!value.toString().trim()) {
          return Promise.reject(getJsonValidateError(fieldName, RulePriority.isNumeric, 'CM_0171'));
        }
        if (!isNaN(value)) {
          if (value <= 0) {
            return Promise.reject(getJsonValidateError(fieldName, RulePriority.isNegativeNumber, 'CM_0172'));
          }

          if (isNumberLengthInvalid(value)) {
            return Promise.reject(getJsonValidateError(fieldName, RulePriority.maxDigits, 'CM_0173'));
          }
          return Promise.resolve();
        }

        return Promise.reject(getJsonValidateError(fieldName, RulePriority.isNumeric, 'CM_0171'));
      },
    },
  ];
}

export function getBeforeConvertRule(): Rule[] {
  return [
    {
      validator: (_, value): Promise<void> => {
        if (!value) {
          return Promise.resolve();
        }
        if (!value.trim() || isNaN(value)) {
          return Promise.reject(i18next.t('CM_0175'));
        }

        if (isNumberLengthInvalid(value)) {
          return Promise.reject(i18next.t('CM_0174', { fieldName: EMPTY_STRING }));
        }
        return Promise.resolve();
      },
    },
  ];
}
