import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LANGUAGE, LANG_CONFIG, SUPPORTED_LANG, routeCondition } from './helpers/lang';

const languageDetector = new LanguageDetector();

languageDetector.addDetector({
  name: 'pathDetector',
  lookup: () => {
    const langInPath = location.pathname.split('/')[1];
    const lang = SUPPORTED_LANG.find((lang) => {
      if (!routeCondition(LANG_CONFIG[lang])) {
        return;
      }

      return LANG_CONFIG[lang].path === langInPath;
    });

    if (!lang) {
      return DEFAULT_LANGUAGE;
    }

    return lang;
  },
});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: LANG_CONFIG,
    // fallbackLng: DEFAULT_LANGUAGE,
    // lng: 'ja',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['pathDetector'],
    },
  });

export default i18n;
