/**
 * Gas Velocity To Flow Rate
 *
 * Created: 2022/05/12
 * __author__: VuongLK
 * __copyright__: Copyright KITZ Corporation. 2022 All Rights Reserved
 * __version__: 1.1.0
 */

import { Col, Form, Row } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles/pages/CalculateCV.scss';
import {
  ERROR_CODE,
  GREAT_PRESSURE,
  P1,
  PRESSURE_CLASSES,
  PRESSURE_UNITS,
  TEMPERATURE_UNITS,
  VALIDATE,
  VELOCITY_UNITS,
} from '../../constants/units';
import PageHeading from '../../components/Heading/PageHeading';
import UnitTable from '../../components/UnitTable/UnitTable';
import { MASS_FLOW_GAS_TABLE_UNITS, VOLUME_FLOW_GAS_TABLE_UNITS } from './constants';
import { STATUS_CODE } from '../../constants/statusCode';
import { useCustomForm } from '../../hooks/useCustomForm';
import { cloneDeep } from 'lodash';
import { getDefaultRule, getNegativeNumberRule } from '../../constants/rules';
import { API_PATHS } from '../../constants/apiPaths';
import { getResultAPI } from '../../services/http-client';
import PageContainer from '../../layouts/PageContainer';
import { BaseForm } from '../../components/BaseForm';
import { BaseFormInputType, IBaseFormOptions } from '../../components/BaseForm/Interface';
import { PressureClassesID, PressureUnitsID, TemperatureUnitsID, VelocityUnitsID } from '../../constants/unitsID';
// import Row from 'antd/es/row';
// import Col from 'antd/es/col';

// eslint-disable-next-line max-lines-per-function
function GasVelocityToFlowRate(): JSX.Element {
  const { t } = useTranslation();
  const [inputForm] = useCustomForm(Form.useForm());
  const validateFields = ['d', 'v', 'p1', 't1', 'gg'];

  const [dataTable1, setDataTable1] = useState(cloneDeep(VOLUME_FLOW_GAS_TABLE_UNITS));
  const [dataTable2, setDataTable2] = useState(cloneDeep(MASS_FLOW_GAS_TABLE_UNITS));

  const [error, setError] = useState('');

  // eslint-disable-next-line max-lines-per-function, complexity
  async function getResult(): Promise<void> {
    const dataPressRatio = ['d', 'v'];

    if (dataPressRatio.every((field) => inputForm.getFieldValue(field) && !inputForm.getFieldError(field)[0])) {
      const dataPost = {
        D: Number(inputForm.getFieldValue('d')),
        V: Number(inputForm.getFieldValue('v')),
        P1:
          inputForm.getFieldValue('p1') && !inputForm.getFieldError('p1')[0]
            ? Number(inputForm.getFieldValue('p1'))
            : undefined,
        V_unit_in: inputForm.getFieldValue('vUnit'),
        P_unit_in: `${inputForm.getFieldValue('classesUnit')}${inputForm.getFieldValue('pressureUnit')}`,
        T1:
          inputForm.getFieldValue('t1') && !inputForm.getFieldError('t1')[0]
            ? Number(inputForm.getFieldValue('t1'))
            : undefined,
        T_unit_in: inputForm.getFieldValue('t1Unit'),
        Gg:
          inputForm.getFieldValue('gg') && !inputForm.getFieldError('gg')[0]
            ? Number(inputForm.getFieldValue('gg'))
            : undefined,
      };

      const data = await getResultAPI(dataPost, API_PATHS.CALC_FLOW_RATE_FROM_VEL_GAS);

      if (data?.code === STATUS_CODE.SUCCESS) {
        setDataTable1((dataTable1) =>
          dataTable1.map((item) => {
            item.result = data.data && data.data[item.value];
            return item;
          }),
        );

        setDataTable2((dataTable2) =>
          dataTable2.map((item) => {
            item.result = data.data && data.data[item.value];
            return item;
          }),
        );
      } else {
        if (data?.customError && data?.customError.code[0] === GREAT_PRESSURE) {
          data?.customError.field[0] === P1
            ? setError(t('CM_0193', { fieldName: `${t('CM_0194')}` }).toString())
            : setError(`${t(data?.customError.code[0].toString())}`);
          setDataTable1(cloneDeep(VOLUME_FLOW_GAS_TABLE_UNITS));
          setDataTable2(cloneDeep(MASS_FLOW_GAS_TABLE_UNITS));

          return;
        }

        if (data && data.customError && data?.customError.type === VALIDATE) {
          data?.errors?.validate && setError(t(`${data?.customError.code[0]}`).toString());
        }

        data?.errors?.other &&
          data.errors.other[0]?.[ERROR_CODE] &&
          setError(t(`${data.errors.other[0]?.[ERROR_CODE]}`).toString());

        setDataTable1(cloneDeep(VOLUME_FLOW_GAS_TABLE_UNITS));
        setDataTable2(cloneDeep(MASS_FLOW_GAS_TABLE_UNITS));
      }
    } else {
      setDataTable1(cloneDeep(VOLUME_FLOW_GAS_TABLE_UNITS));
      setDataTable2(cloneDeep(MASS_FLOW_GAS_TABLE_UNITS));
    }
  }

  const onFormValuesChange = (): void => {
    inputForm
      .validateFields(validateFields)
      .then(() => {
        getResult();
        setError('');
      })
      .catch(() => {
        setDataTable1(cloneDeep(VOLUME_FLOW_GAS_TABLE_UNITS));
        setDataTable2(cloneDeep(MASS_FLOW_GAS_TABLE_UNITS));
        setError(inputForm.customError[0]?.msg);
      });
  };

  const baseFormOpt: IBaseFormOptions = {
    form: inputForm,
    onFormValuesChange,
    className: 'my-custom-class',
    fields: [
      {
        label: t('CM_0075'),
        className: 'flex-start',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'd',
            rules: getNegativeNumberRule(t('CM_0191'), undefined, undefined, undefined, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
            unit: 'mm',
          },
        ],
      },
      {
        label: t('CM_0165'),
        className: 'flex-start',
        note: <>{t('CM_0099')}</>,
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'v',
            rules: getNegativeNumberRule(t('CM_0014'), undefined, undefined, undefined, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            name: 'vUnit',
            options: VELOCITY_UNITS.map((item) => ({ key: item.value, value: item.value, label: item.label })),
            value: VelocityUnitsID.ms,
            note: <>{t('CM_0026')}</>,
          },
        ],
      },
      {
        label: t('CM_0003'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'p1',
            rules: getDefaultRule(t(`${t('CM_0194')}`), undefined, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            value: PressureUnitsID.Mpa,
            name: 'pressureUnit',
            options: PRESSURE_UNITS.map((item) => ({ key: item.unit, value: item.value, label: item.unit })),
          },
          {
            type: BaseFormInputType.Select,
            value: PressureClassesID.Gauge,
            name: 'classesUnit',
            options: PRESSURE_CLASSES.map((item) => ({ key: item.unit, value: item.value, label: t(item.name) })),
          },
        ],
      },
      {
        label: t('CM_0018'),
        note: <>{t('CM_0164')}</>,
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 't1',
            rules: getDefaultRule(t('CM_0159'), undefined, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            name: 't1Unit',
            value: TemperatureUnitsID.Celsius,
            options: TEMPERATURE_UNITS.map((item) => ({
              key: item.unit,
              value: item.value,
              label: `${t(item.label)}`,
            })),
          },
        ],
      },
      {
        label: t('CM_0019'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'gg',
            rules: getNegativeNumberRule(t('CM_0190'), undefined, undefined, undefined, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
            unit: `(${t('CM_0020')})`,
          },
        ],
      },
    ],
  };

  return (
    <PageContainer>
      <div className="velocity-to-flow-rate gas">
        <PageHeading borderColor="jade">{t('CM_0080')}</PageHeading>
        <BaseForm options={baseFormOpt}></BaseForm>

        <div className="result">
          <Row>
            <Col>
              <h4 className="font-bold">{t('CM_0010')}</h4>
            </Col>
            <Col>
              <span className="error">{error}</span>
            </Col>
          </Row>

          <UnitTable
            titleTable1="CM_0035"
            titleTable2="CM_0036"
            dataTable1={dataTable1}
            dataTable2={dataTable2}
            description={t('CM_0053').toString()}
          />
        </div>
      </div>
    </PageContainer>
  );
}

export default GasVelocityToFlowRate;
