/**
 * Handle api get liquid viscosity Conversion result
 *
 * Created: 2022/12/15
 * __author__: DucND16
 * __copyright__:
 * __version__: 1.0.0
 */

import { AxiosResponse } from 'axios';
import req from './http-client';
import { IResponseInterface } from './responseInterface';
export interface IResponseData {
  [x: string]: number;
}
const getLiquidViscosityConversion = (
  v: string,
  vUnit: string,
  gf: string,
): Promise<AxiosResponse<IResponseInterface>> => {
  const bodyData: {
    V: number;
    V_unit_in: string;
    Gf?: number;
  } = {
    V: Number(v),
    V_unit_in: vUnit,
  };
  if (gf) {
    bodyData.Gf = Number(gf);
  }

  return req.post('/convVisUnitLiq', {
    ...bodyData,
  });
};

export { getLiquidViscosityConversion };
