import { Rule } from 'antd/es/form';
import { RulePriority } from '../../constants/rules';
import { isNumeric, isNumberLengthInvalid, getJsonValidateError, EMPTY_STRING } from '../../helpers/utils';
import i18next from '../../i18n';

enum LinearInterpolationMsgError {
  CompareTemperature = '1508',
  ComparePressure = '1509',
  IsNumberTemperature = 'CM_0201',
  IsNumberPressure = 'CM_0202',
  DecimalTemperature = 'CM_0211',
  DecimalPressure = 'CM_0212',
  InRangeT = 'CM_0199',
  InRangeP = 'CM_0200',
  LengthInvalidT = 'PT_0004',
  LengthInvalidP = 'PT_0003',
}

interface IDigitError {
  isNaN: string;
  isNotInteger: string;
  lengthInvalid: string;
}

const pressureDigitError: IDigitError = {
  isNaN: LinearInterpolationMsgError.IsNumberPressure,
  isNotInteger: LinearInterpolationMsgError.DecimalPressure,
  lengthInvalid: LinearInterpolationMsgError.LengthInvalidP,
};

const temperatureDigitError: IDigitError = {
  isNaN: LinearInterpolationMsgError.IsNumberTemperature,
  isNotInteger: LinearInterpolationMsgError.DecimalTemperature,
  lengthInvalid: LinearInterpolationMsgError.LengthInvalidT,
};

const isInRangeXY = (val1: string, val2: string, value: string): boolean => {
  if (!isNumeric(val1) || !isNumeric(val2) || !isNumeric(value)) {
    return false;
  } else {
    if (
      (Number(val1) <= Number(value) && Number(value) <= Number(val2)) ||
      (Number(val2) <= Number(value) && Number(value) <= Number(val1))
    ) {
      return true;
    }
  }
  return false;
};

/**
 * Get default rule by field name
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
export function getCompareEqualRuleToOtherField(compareField: string): Rule {
  const rule: Rule = ({ getFieldValue }) => ({
    validator(_, value): Promise<string | void> {
      if (value && value === getFieldValue(compareField)) {
        const errCode =
          compareField === 't1'
            ? LinearInterpolationMsgError.CompareTemperature
            : LinearInterpolationMsgError.ComparePressure;
        const error = {
          priority: RulePriority.isEqual,
          msg: i18next.t(errCode),
        };
        return Promise.reject(JSON.stringify(error));
      }
      return Promise.resolve();
    },
  });

  return rule;
}

export function getRuleInRange(rangeField1: string, rangeField2: string): Rule {
  const rule: Rule = ({ getFieldValue }) => ({
    validator(_, value): Promise<string | void> {
      if (!value) {
        return Promise.resolve();
      }
      const val1 = getFieldValue(rangeField1);
      const val2 = getFieldValue(rangeField2);
      if (!isInRangeXY(val1, val2, value)) {
        const errCode =
          rangeField1 === 't1' ? LinearInterpolationMsgError.InRangeT : LinearInterpolationMsgError.InRangeP;
        const error = {
          priority: RulePriority.isInRange,
          msg: i18next.t(errCode),
        };
        return Promise.reject(JSON.stringify(error));
      }
      return Promise.resolve();
    },
  });

  return rule;
}

// eslint-disable-next-line max-lines-per-function
export function getDigitRule(
  fieldName: string,
  options?: {
    allowedBlank?: boolean;
  },
): Rule {
  return () => ({
    validator(_, value): Promise<string | void> {
      if (!value && options?.allowedBlank) {
        return Promise.resolve();
      }
      const fieldInputError = fieldName === 'tx' ? pressureDigitError : temperatureDigitError;
      if (!isNumeric(value)) {
        return Promise.reject(getJsonValidateError(EMPTY_STRING, RulePriority.isNumeric, fieldInputError.isNaN));
      }
      const absNumber = Math.abs(Number(value));
      if (Math.floor(absNumber) !== absNumber) {
        return Promise.reject(
          getJsonValidateError(EMPTY_STRING, RulePriority.isIntegerNumber, fieldInputError.isNotInteger),
        );
      }
      if (isNumberLengthInvalid(value)) {
        return Promise.reject(getJsonValidateError(fieldName, RulePriority.maxDigits, fieldInputError.lengthInvalid));
      }

      return Promise.resolve();
    },
  });
}
