/**
 * Axios configurations
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
import axios from 'axios';
import { STATUS_CODE } from '../constants/statusCode';

const ERR_CODE = 'error code';
export enum ErrorType {
  Validate = 'validate',
  Other = 'other',
}

export interface ResponseError {
  [ERR_CODE]: number;
  message: string;
}

export interface ValidateError {
  [fieldName: string]: ResponseError;
}

export interface CustomErrorDetail {
  type: ErrorType;
  field?: string[];
  code: number[];
}

export interface TableData {
  [fieldName: string]: number;
}

export interface SingleData {
  value: number;
}

export interface AxiosResponseData {
  code: number;
  data?: TableData | SingleData;
  errors: {
    validate?: ValidateError;
    other?: ResponseError[];
  };
  customError?: CustomErrorDetail;
}

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_PATH || '',
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.response.use(
  (response) => {
    const data = response.data as AxiosResponseData;
    let customError!: CustomErrorDetail;
    if (data.code !== STATUS_CODE.SUCCESS) {
      if (data.errors?.validate) {
        const validateErrors = data.errors.validate as ValidateError;
        const errFields = Object.keys(validateErrors);
        const errCodes = errFields.map((fieldName: string) => validateErrors[fieldName][ERR_CODE]);
        customError = {
          type: ErrorType.Validate,
          code: errCodes,
          field: Object.keys(data.errors && data.errors?.validate),
        };
      }
      if (data.errors?.other?.length) {
        const errCodes = data.errors.other.map((other: ResponseError) => other[ERR_CODE]);
        customError = {
          type: ErrorType.Other,
          code: errCodes,
        };
      }
    }
    response.data = { ...data, customError };
    Object.freeze(response);
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default apiClient;

export interface DataPost {
  Gg?: number;
  Gf?: number;
  Q?: number;
  Q_unit_in?: string;
  V?: number;
  V_unit_in?: string;
  P?: number;
  P1?: number;
  P2?: number;
  P_unit_in?: string;
  T1?: number;
  T_unit_in?: string;
  S?: string;
  D?: number;
  W?: number;
  W_unit_in?: string;
}

interface DataResponse {
  code: number;
  data?: {
    [field: string]: number;
  };
  errors?: {
    validate?: {
      [field: string]: {
        ['error code']: number;
        message: string;
      };
    };
    other?: [
      {
        ['error code']: number;
        message: string;
      },
    ];
  };
  customError:
    | {
        code: number[];
        field: string[];
        type: string;
      }
    | undefined;
}

/**
 * get Result API
 *
 * Created: 2022/05/12
 * __author__: VuongLK
 * __copyright__: Copyright KITZ Corporation. 2022 All Rights Reserved
 * __version__: 1.1.0
 */
export async function getResultAPI(dataPost: DataPost, paths: string): Promise<DataResponse | undefined> {
  try {
    const data = await apiClient.post(paths, dataPost);
    return data.data;
  } catch (error) {
    console.log(error);
  }
}
