// export const APP_PATH = {
//   HOME: '/',
//   SIGNUP: '/signup',
//   LOGIN: '/login',
//   LIQUID: '/liquid',
//   GAS: '/gas',
//   STEAM: '/steam',
//   DIFF_PRESSURE_CALCULATION: '/differential-pressure-calculation',
//   FLOW_CALCULATION: '/flow-calculation',
//   TEMPERATURE_UNIT: '/temperature-unit',
//   PRESSURE_UNIT: '/pressure-unit',
//   FLOW_VELOCITY: '/flow-velocity',
//   GAS_UNIT: '/gas-unit',
//   LIQUID_VISCOSITY_UNIT: '/liquid-viscosity-unit',
//   GAS_CV_CALCULATION: '/gas-cv-calculation',
//   LIQUID_CV_CALCULATION: '/liquid-cv-calculation',
//   GAS_VELOCITY_TO_FLOW_RATE: '/gas-velocity-to-flow-rate',
//   STEAM_VELOCITY_TO_FLOW_RATE: '/steam-velocity-to-flow-rate',
//   LIQUID_UNIT: '/liquid-unit',
//   FLOW_RATE: '/flow-rate-liquid',
//   FLOW_RATE_GAS: '/flow-rate-gases',
//   STEAM_CV_CALCULATION: '/steam-cv-calculation',
//   FLOW_RATE_STEAM: '/flow-rate-steam',
//   LINEAR_INTERPOLATION: '/linear-interpolation',
// };

export const APP_PATH = {
  HOME: '',
  SIGNUP: 'signup',
  LOGIN: 'login',
  LIQUID: 'liquid',
  GAS: 'gas',
  STEAM: 'steam',
  DIFF_PRESSURE_CALCULATION: 'differential-pressure-calculation',
  FLOW_CALCULATION: 'flow-calculation',
  TEMPERATURE_UNIT: 'temperature-unit',
  PRESSURE_UNIT: 'pressure-unit',
  FLOW_VELOCITY: 'flow-velocity',
  GAS_UNIT: 'gas-unit',
  LIQUID_VISCOSITY_UNIT: 'liquid-viscosity-unit',
  GAS_CV_CALCULATION: 'gas-cv-calculation',
  LIQUID_CV_CALCULATION: 'liquid-cv-calculation',
  GAS_VELOCITY_TO_FLOW_RATE: 'gas-velocity-to-flow-rate',
  STEAM_VELOCITY_TO_FLOW_RATE: 'steam-velocity-to-flow-rate',
  LIQUID_UNIT: 'liquid-unit',
  FLOW_RATE: 'flow-rate-liquid',
  FLOW_RATE_GAS: 'flow-rate-gases',
  STEAM_CV_CALCULATION: 'steam-cv-calculation',
  FLOW_RATE_STEAM: 'flow-rate-steam',
  LINEAR_INTERPOLATION: 'linear-interpolation',
};
