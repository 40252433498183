/**
 * Steam Velocity To Flow Rate
 *
 * Created: 2022/05/12
 * __author__: VuongLK
 * __copyright__: Copyright KITZ Corporation. 2022 All Rights Reserved
 * __version__: 1.1.0
 */

import { Col, Form, RadioChangeEvent, Row } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import UnitTable from '../../components/UnitTable/UnitTable';
import PageHeading from '../../components/Heading/PageHeading';
import { MASS_FLOW_GAS_TABLE_UNITS_SINGLE } from './constants';
import { STATUS_CODE } from '../../constants/statusCode';
import {
  ERROR_CODE,
  FLUID_STATES,
  GREAT_PRESSURE,
  P1,
  PRESSURE_CLASSES,
  PRESSURE_UNITS,
  STEAM_HOT,
  TEMPERATURE_UNITS,
  VELOCITY_UNITS,
} from '../../constants/units';
import { useCustomForm } from '../../hooks/useCustomForm';
import { cloneDeep } from 'lodash';
import { getDefaultRule, getNegativeNumberRule } from '../../constants/rules';
import { API_PATHS } from '../../constants/apiPaths';
import { getResultAPI } from '../../services/http-client';
import PageContainer from '../../layouts/PageContainer';
import { BaseForm } from '../../components/BaseForm';
import { BaseFormInputType, IBaseFormOptions } from '../../components/BaseForm/Interface';
import { getHead } from '../../helpers/common';
import { displayToFixed2Response, EMPTY_STRING, isCannotCalcPressure, minusString } from '../../helpers/utils';
import {
  FluidStatesID,
  PressureClassesID,
  PressureUnitsID,
  TemperatureUnitsID,
  VelocityUnitsID,
} from '../../constants/unitsID';

// eslint-disable-next-line max-lines-per-function
function SteamVelocityToFlowRate(): JSX.Element {
  const { t } = useTranslation();
  const [inputForm] = useCustomForm(Form.useForm());
  const validateFields = ['d', 'v', 'p1', 'fluid', 't1'];
  const [dataTable, setDataTable] = useState(MASS_FLOW_GAS_TABLE_UNITS_SINGLE);
  const [fluid, setFluid] = useState(FluidStatesID.Saturated);
  const [saturationTemperatureAtP1, setSaturationTemperatureAtP1] = useState<string>('');
  const [superheat, setSuperHeat] = useState<string>('');
  const [error, setError] = useState('');
  const [t1Unit, setT1Unit] = useState(TemperatureUnitsID.Celsius);

  // eslint-disable-next-line max-lines-per-function, complexity
  async function getCalcFlowRateFromVelSteam(dataPost: {
    D: number;
    V: number;
    P1: number;
    V_unit_in: string;
    P_unit_in: string;
    S: string;
    T1: number | undefined;
    T_unit_in: string | undefined;
  }): Promise<void> {
    const data = await getResultAPI(dataPost, API_PATHS.CALC_FLOW_RATE_FROM_VEL_STEAM);

    if (data?.code && data.code === STATUS_CODE.SUCCESS) {
      setError(EMPTY_STRING);
      setDataTable((dataTable) =>
        cloneDeep(dataTable).map((item) => {
          item.result = data.data && data.data[item.value];
          return item;
        }),
      );
    } else {
      if (data && data?.customError && data.customError.code[0] === GREAT_PRESSURE) {
        const key = data?.errors?.validate && Object.keys(data.errors?.validate)[0];
        key === P1
          ? setError(t('CM_0193', { fieldName: `${t('CM_0194')}` }).toString())
          : setError(`${t(data?.customError.code[0].toString())}`);
        setDataTable(MASS_FLOW_GAS_TABLE_UNITS_SINGLE);

        return;
      } else {
        data?.customError && setError(`${t(data?.customError.code[0].toString())}`);
      }

      data?.errors?.other &&
        data.errors.other[0]?.[ERROR_CODE] &&
        setError(t(`${data.errors.other[0]?.[ERROR_CODE]}`).toString());
      setDataTable(MASS_FLOW_GAS_TABLE_UNITS_SINGLE);
    }
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  async function getResult(): Promise<void> {
    if (
      inputForm.getFieldValue('p1') &&
      inputForm.getFieldValue('t1') &&
      !inputForm.getFieldError('p1')[0] &&
      !inputForm.getFieldError('t1')[0]
    ) {
      const dataPostCalcSteamTSH = {
        P1: Number(inputForm.getFieldValue('p1')),
        T1: Number(inputForm.getFieldValue('t1')),
        T_unit_in: inputForm.getFieldValue('t1Unit'),
        P_unit_in: `${inputForm.getFieldValue('classesUnit')}${inputForm.getFieldValue('pressureUnit')}`,
      };

      const data = await getResultAPI(dataPostCalcSteamTSH, API_PATHS.CALC_STEAM_TSH);

      if (data && data?.code && data.code === STATUS_CODE.SUCCESS) {
        data?.data && setSuperHeat(data?.data?.value.toString());
      }
      // else {
      //   setSuperHeat('');
      // }
    } else {
      setSuperHeat('');
    }

    if (
      inputForm.getFieldValue('d') &&
      inputForm.getFieldValue('v') &&
      inputForm.getFieldValue('p1') &&
      !inputForm.getFieldError('d')[0] &&
      !inputForm.getFieldError('v')[0] &&
      !inputForm.getFieldError('p1')[0]
    ) {
      const data = {
        D: Number(inputForm.getFieldValue('d')),
        V: Number(inputForm.getFieldValue('v')),
        P1: Number(inputForm.getFieldValue('p1')),
        V_unit_in: inputForm.getFieldValue('vUnit'),
        P_unit_in:
          inputForm.getFieldValue('classesUnit').toString() + inputForm.getFieldValue('pressureUnit').toString(),
        S: inputForm.getFieldValue('fluid'),
      };

      if (inputForm.getFieldValue('fluid') === STEAM_HOT) {
        setDataTable(MASS_FLOW_GAS_TABLE_UNITS_SINGLE);

        if (inputForm.getFieldValue('t1') && !inputForm.getFieldError('t1')[0]) {
          const dataPost = {
            ...data,
            T1: Number(inputForm.getFieldValue('t1')),
            T_unit_in: inputForm.getFieldValue('t1Unit').toString(),
          };

          getCalcFlowRateFromVelSteam(dataPost);
        } else {
          setError(EMPTY_STRING);
        }
      } else {
        const dataPost = {
          ...data,
          T1: undefined,
          T_unit_in: undefined,
        };

        getCalcFlowRateFromVelSteam(dataPost);
      }
    } else {
      setDataTable(MASS_FLOW_GAS_TABLE_UNITS_SINGLE);
    }

    if (inputForm.getFieldValue('p1') && !inputForm.getFieldError('p1')[0]) {
      const dataPost = {
        P: Number(inputForm.getFieldValue('p1')),
        P_unit_in: `${inputForm.getFieldValue('classesUnit')}${inputForm.getFieldValue('pressureUnit')}`,
      };
      const data = await getResultAPI(dataPost, API_PATHS.CALC_SATURATED_TEMP);

      if (data && data.code === STATUS_CODE.SUCCESS) {
        data.data && setSaturationTemperatureAtP1(data.data?.[inputForm.getFieldValue('t1Unit')].toString());
      } else {
        setSaturationTemperatureAtP1(`${t('CM_0205')}`);
        inputForm.getFieldValue('t1') && setSuperHeat(`${t('CM_0205')}`);
      }
    } else {
      setSaturationTemperatureAtP1('');
    }
  }

  const onFormValuesChange = (): void => {
    if (fluid === FluidStatesID.Saturated) {
      inputForm.clearSelectedInputFormValue(['t1']);
    }
    inputForm
      .validateFields(validateFields)
      .then(() => {
        setError(EMPTY_STRING);
        getResult();
      })
      .catch(() => {
        getResult();
        if (isCannotCalcPressure('p1', inputForm.customError)) {
          setSaturationTemperatureAtP1(minusString);
          setSuperHeat(minusString);
        }
        setDataTable(MASS_FLOW_GAS_TABLE_UNITS_SINGLE);
        setError(inputForm.customError[0]?.msg);
      });
  };

  const baseFormOpt: IBaseFormOptions = {
    form: inputForm,
    onFormValuesChange,
    className: 'my-custom-class',
    fields: [
      {
        label: t('CM_0075'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'd',
            rules: getNegativeNumberRule(t('CM_0191'), undefined, false, 1, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
            unit: 'mm',
          },
        ],
      },
      {
        label: t('CM_0165'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'v',
            rules: getNegativeNumberRule(t('CM_0014'), undefined, false, 4, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            name: 'vUnit',
            options: VELOCITY_UNITS.map((item) => ({ key: item.value, value: item.value, label: item.label })),
            value: VelocityUnitsID.ms,
            note: <>{t('CM_0026')}</>,
          },
        ],
      },
      {
        label: t('CM_0003'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'p1',
            rules: getDefaultRule(t(`${t('CM_0194')}`), 3, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            value: PressureUnitsID.Mpa,
            name: 'pressureUnit',
            options: PRESSURE_UNITS.map((item) => ({ key: item.unit, value: item.value, label: item.unit })),
          },
          {
            type: BaseFormInputType.Select,
            value: PressureClassesID.Gauge,
            name: 'classesUnit',
            options: PRESSURE_CLASSES.map((item) => ({ key: item.unit, value: item.value, label: t(item.name) })),
          },
        ],
      },
      {
        label: t('CM_0138'),
        inputs: [
          {
            type: BaseFormInputType.RadioGroup,
            name: 'fluid',
            options: FLUID_STATES.map((item) => ({
              key: item.value,
              value: item.value,
              label: `${t(item.name)}`,
            })),
            value: FluidStatesID.Saturated,
            onChange: (e: RadioChangeEvent): void => {
              setFluid(e.target.value);
              setSuperHeat('');
              inputForm.setFieldValue('t1', '');
            },
            note: <>{t('CM_0031')}</>,
          },
        ],
      },
      {
        label: t('CM_0018'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 't1',
            rules: getDefaultRule(t('CM_0159'), 2, true),
            disabled: fluid === FluidStatesID.Saturated,
            autoComplete: false,
            placeholder: fluid === FluidStatesID.Saturated ? '' : t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            name: 't1Unit',
            value: TemperatureUnitsID.Celsius,
            onChange(value: TemperatureUnitsID): void {
              setT1Unit(value);
            },
            options: TEMPERATURE_UNITS.map((item) => ({
              key: item.unit,
              value: item.value,
              label: `${t(item.label)}`,
            })),
          },
        ],
      },
      {
        label: t('CM_0032'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'saturationTemperatureAtP1',
            unit: getHead(t1Unit),
            value: displayToFixed2Response(saturationTemperatureAtP1),
            isResultField: true,
            disabled: true,
            isSpecialFormat: true,
          },
        ],
      },
      {
        label: t('CM_0033'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'superheat',
            unit: getHead(t1Unit),
            value: displayToFixed2Response(superheat),
            isResultField: true,
            disabled: true,
            isSpecialFormat: true,
            note: <div className="note-radio">{t('CM_0169')}</div>,
          },
        ],
      },
    ],
  };

  return (
    <PageContainer>
      <div className="velocity-to-flow-rate steam">
        <PageHeading borderColor="pear">{t('CM_0081')}</PageHeading>

        <BaseForm options={baseFormOpt}></BaseForm>

        <div className="result">
          <Row>
            <Col>
              <h4 className="font-bold">{t('CM_0010')}</h4>
            </Col>
            <Col>
              <span className="error">{error}</span>
            </Col>
          </Row>

          <UnitTable dataTable1={dataTable} titleTable1="CM_0036" />
        </div>
      </div>
    </PageContainer>
  );
}

export default SteamVelocityToFlowRate;
