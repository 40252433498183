/**
 * Create Flow Rate Steam screen
 *
 * @author: AnhPV17
 * @return
 */
import { useEffect, useRef, useState } from 'react';
import { Form, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { getDefaultRule, getNegativeNumberRule } from '../../constants/rules';
import rounding, {
  displayToFixed2Response,
  minusString,
  isCannotCalcPressure,
  EMPTY_STRING,
} from '../../helpers/utils';
import '../../styles/pages/FlowRate.scss';
import { PRESSURE_CLASSES, PRESSURE_UNITS, R_1, R_2, STEAM_FLOW_UNIT_LIST, TEMPERATURE_UNITS } from './constants';
import PageHeading from '../../components/Heading/PageHeading';
import { setdataPostSupperHeat, setDataSteam, setResultSuperheated } from '../../services/flowToVelocity';
import { useCustomForm } from '../../hooks/useCustomForm';
import { CustomInput } from '../../components/CustomInput/CustomInput';
import { IFLowRateSteam } from '../../interfaces/steamFLowRate';
import PageContainer from '../../layouts/PageContainer';
import { BaseForm } from '../../components/BaseForm';
import { BaseFormInputType, IBaseFormOptions } from '../../components/BaseForm/Interface';
import { AxiosResponseData, SingleData } from '../../services/http-client';
import { FluidStatesID } from '../../constants/unitsID';
import { GREAT_PRESSURE, P1, T1 } from '../../constants/units';
import { ValidateCodes } from '../../constants/errorCode';

enum FluidStateSteam {
  saturated = FluidStatesID.Saturated,
  superheated = FluidStatesID.Superheated,
}
// eslint-disable-next-line max-lines-per-function
export const Steam = (): JSX.Element => {
  const [fluidStateSteam, setFluidStateSteam] = useState<FluidStateSteam>(FluidStateSteam.saturated);
  const [inputForm] = useCustomForm(Form.useForm());
  const [errorMessage, setErrorMessage] = useState<string>();
  const [tuValue, setTUChange] = useState<string>(TEMPERATURE_UNITS[0].value);
  const [msResult, setMsResult] = useState<string>();
  const [ftResult, setFtResult] = useState<string>();
  const [p1Message, setP1Message] = useState<string>();
  const [p1TempValue, setP1TempValue] = useState<string>();
  const [p1SupperHeatValue, setP1SupperHeatValue] = useState<string>();
  const [pUnit, setPUnit] = useState<string | undefined>(TEMPERATURE_UNITS[0].unit);
  const { t } = useTranslation();
  const validateFields = ['pipe-diameter-d', 'flow-rate-w', 'upstream-pressure-p1'];
  const validateFieldsSat = ['pipe-diameter-d', 'flow-rate-w', 'upstream-pressure-p1', 'fluid-temperature-t1'];
  const currentInput = useRef<string[]>(validateFields);
  const currentSelct = useRef<FluidStateSteam>(FluidStateSteam.saturated);
  /**
   * handle form values change
   *
   * Created: 2022/29/12
   *  @author AnhPV17
   */

  const setResultError = (): void => {
    setMsResult(t('CM_0178') as string);
    setFtResult(t('CM_0178') as string);
  };

  const onFormValuesChange = (): void => {
    inputForm
      .validateFields(currentInput.current)
      .then(() => {
        setErrorMessage('');
        getResultSuperheated();
      })
      .catch(() => {
        if (isCannotCalcPressure('upstream-pressure-p1', inputForm.customError)) {
          setP1TempValue(minusString);
          setP1SupperHeatValue(minusString);
        }
        setResultError();
        setErrorMessage(inputForm.customError[0]?.msg);
      });
    if (fluidStateSteam === FluidStateSteam.saturated) {
      inputForm.clearSelectedInputFormValue(['fluid-temperature-t1']);
    }
  };

  useEffect(() => {
    if (p1Message || errorMessage) {
      setResultError();
    }
  }, [errorMessage, p1Message]);

  /**
   * data to call API
   *
   * Created: 2022/29/12
   *  @author AnhPV17
   */
  const dataPostFromCheckBox1 = (): IFLowRateSteam => {
    const dataPost = {
      P1: Number(inputForm.getFieldValue('upstream-pressure-p1')),
      P_unit_in: `${inputForm.getFieldValue('pu2Value')}${inputForm.getFieldValue('pu1Value')}`,
      W: Number(inputForm.getFieldValue('flow-rate-w')),
      W_unit_in: inputForm.getFieldValue('wuValue'),
      D: Number(inputForm.getFieldValue('pipe-diameter-d')),
      S: currentSelct.current === FluidStateSteam.saturated ? 'S01' : 'S02',
    };

    const data =
      currentSelct.current === FluidStateSteam.saturated
        ? dataPost
        : {
            ...dataPost,
            T1: Number(inputForm.getFieldValue('fluid-temperature-t1')),
            T_unit_in: inputForm.getFieldValue('tuValue'),
          };

    return data;
  };
  /**
   * call 3 API when select superheated steam
   *
   * Created: 2022/29/12
   *  @author AnhPV17
   */
  // eslint-disable-next-line complexity, max-lines-per-function
  const getResultSuperheated = async (): Promise<void> => {
    const dataPostSatTemp = {
      P: inputForm.getFieldValue('upstream-pressure-p1')
        ? Number(inputForm.getFieldValue('upstream-pressure-p1'))
        : undefined,
      P_unit_in: `${inputForm.getFieldValue('pu2Value')}${inputForm.getFieldValue('pu1Value')}`,
    };
    const dataPostSupperHeat = {
      P1: inputForm.getFieldValue('upstream-pressure-p1')
        ? Number(inputForm.getFieldValue('upstream-pressure-p1'))
        : undefined,
      P_unit_in: `${inputForm.getFieldValue('pu2Value')}${inputForm.getFieldValue('pu1Value')}`,
      T1: inputForm.getFieldValue('fluid-temperature-t1')
        ? Number(inputForm.getFieldValue('fluid-temperature-t1'))
        : undefined,
      T_unit_in: inputForm.getFieldValue('tuValue'),
    };

    if (!dataPostSupperHeat.P1) {
      setP1TempValue('');
      setP1SupperHeatValue('');
    }

    if (!dataPostSupperHeat.T1) {
      setP1SupperHeatValue('');
    }

    try {
      const [rspFromFlowRateSteam, rspSaturatedTemp, rspSteamTSH]: AxiosResponseData[] = await Promise.all([
        setDataSteam(dataPostFromCheckBox1()),
        setResultSuperheated(dataPostSatTemp),
        setdataPostSupperHeat(dataPostSupperHeat),
      ]);
      setP1Message('');

      rspSteamTSH.data && setP1SupperHeatValue(displayToFixed2Response(rspSteamTSH.data.value));

      rspSaturatedTemp.data &&
        rspSaturatedTemp.data[inputForm.getFieldValue('tuValue') as keyof SingleData] &&
        setP1TempValue(
          displayToFixed2Response(rspSaturatedTemp.data[inputForm.getFieldValue('tuValue') as keyof SingleData]),
        );

      fluidStateSteam === FluidStateSteam.saturated && setP1SupperHeatValue('');

      const resultFromFlowRateSteam = rspFromFlowRateSteam.data;
      if (resultFromFlowRateSteam && !inputForm.getFormEmtyOrError(currentInput.current).isOneFieldEmpty) {
        setMsResult(rounding(Number(resultFromFlowRateSteam[R_1 as keyof SingleData])).toString());
        setFtResult(rounding(Number(resultFromFlowRateSteam[R_2 as keyof SingleData])).toString());
      } else {
        setMsResult(EMPTY_STRING);
        setFtResult(EMPTY_STRING);
      }
      const validateErrors = rspFromFlowRateSteam.customError;
      if (rspSaturatedTemp.customError && rspSaturatedTemp.customError.code[0] === GREAT_PRESSURE) {
        setP1TempValue(`${t('CM_0205')}`);
        setP1SupperHeatValue(`${t('CM_0205')}`);
        !errorMessage && setP1Message(t('CM_0193', { fieldName: t('CM_0194') }) as string);
        setResultError();
      }
      if (dataPostSupperHeat.P1 && rspSaturatedTemp.customError && rspSaturatedTemp.customError.code[0]) {
        setP1TempValue(`${t('CM_0205')}`);
      }
      if (
        dataPostSupperHeat.P1 &&
        dataPostSupperHeat.T1 &&
        rspSteamTSH.customError &&
        rspSteamTSH.customError.code[0]
      ) {
        setP1SupperHeatValue(`${t('CM_0205')}`);
      }

      if (validateErrors) {
        if (
          inputForm.getFieldValue('upstream-pressure-p1') &&
          validateErrors.field &&
          validateErrors.field[0] === P1
        ) {
          const code = validateErrors.code[0];
          code === GREAT_PRESSURE
            ? setP1Message(t('CM_0193', { fieldName: `${t('CM_0194')}` }) as string)
            : setP1Message(`${t(validateErrors.code[0].toString())}`);
          if (code === ValidateCodes.PRESSURE_OUT_OF_RANGE) {
            setP1TempValue(`${t('CM_0205')}`);
            setResultError();
            inputForm.getFieldValue('fluid-temperature-t1') && setP1SupperHeatValue(`${t('CM_0205')}`);
          }
        } else if (
          inputForm.getFieldValue('fluid-temperature-t1') &&
          validateErrors.field &&
          validateErrors.field[0] === T1
        ) {
          setP1Message(`${t(validateErrors.code[0].toString())}`);
          setResultError();
        }
      }
    } catch (errors) {
      console.log(errors);
    }
  };

  useEffect(() => {
    const x = TEMPERATURE_UNITS.find((item) => item.value == tuValue);
    setPUnit(x?.unit);
  }, [tuValue]);

  const formOption: IBaseFormOptions = {
    form: inputForm,
    onFormValuesChange,
    fields: [
      {
        label: t('CM_0075'),
        className: 'steam-flow',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'pipe-diameter-d',
            rules: getNegativeNumberRule(t('CM_0191'), undefined, false, 1, true),
            placeholder: t('CM_0129'),
            unit: 'mm',
          },
        ],
      },
      {
        label: t('CM_0028'),
        className: 'steam-flow',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'flow-rate-w',
            // className: 'input-text flow-rate-steam-input',
            rules: getNegativeNumberRule(t('CM_0160'), undefined, false, 4, true),
            placeholder: t('CM_0129'),
            unit: 'mm',
          },
          {
            type: BaseFormInputType.Select,
            name: 'wuValue',
            value: STEAM_FLOW_UNIT_LIST[0].value,
            options: STEAM_FLOW_UNIT_LIST.map((item) => {
              return {
                key: item.name,
                value: item.value,
                label: t(item.label),
              };
            }),
          },
        ],
      },
      {
        label: t('CM_0003'),
        className: 'steam-p-flow',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'upstream-pressure-p1',
            rules: getDefaultRule(`${t('CM_0194')}`, 3, true),
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            name: 'pu1Value',
            value: PRESSURE_UNITS[0].value,
            options: PRESSURE_UNITS.map((item) => {
              return {
                key: item.name,
                value: item.value,
                label: t(item.unit),
              };
            }),
          },
          {
            type: BaseFormInputType.Select,
            name: 'pu2Value',
            value: PRESSURE_CLASSES[0].value,
            options: PRESSURE_CLASSES.map((item) => {
              return {
                key: item.name,
                value: item.value,
                label: t(item.unit),
              };
            }),
          },
        ],
      },
      {
        label: t('CM_0138'),
        inputs: [
          {
            note: <>{t('CM_0031')}</>,
            type: BaseFormInputType.RadioGroup,
            onChange: (e): void => {
              setFluidStateSteam(e.target.value);
              if (e.target.value === FluidStateSteam.saturated) {
                currentSelct.current = FluidStateSteam.saturated;
                currentInput.current = validateFields;
                inputForm.setFieldValue('fluid-temperature-t1', EMPTY_STRING);
                setP1SupperHeatValue(EMPTY_STRING);
              } else {
                currentSelct.current = FluidStateSteam.superheated;
                currentInput.current = validateFieldsSat;
              }
            },
            value: 'S01',
            name: 'fluidStateSteam',
            options: [
              {
                key: 'S01',
                value: 'S01',
                label: t('CM_0029'),
              },
              {
                key: 'S02',
                value: 'S02',
                label: t('CM_0030'),
              },
            ],
          },
        ],
      },
      {
        label: t('CM_0018'),
        className: 'gases-t-flow',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'fluid-temperature-t1',
            disabled: fluidStateSteam === FluidStateSteam.saturated,
            rules: getDefaultRule(t('CM_0159'), 2, true),
            placeholder: fluidStateSteam === FluidStateSteam.saturated ? '' : (t('CM_0129') as string),
          },
          {
            type: BaseFormInputType.Select,
            name: 'tuValue',
            value: TEMPERATURE_UNITS[0].value,
            options: TEMPERATURE_UNITS.map((item) => {
              return {
                key: item.name,
                value: item.value,
                label: t(item.label),
              };
            }),
            onChange: (value: string): void => {
              setTUChange(value);
            },
          },
        ],
      },
      {
        label: t('CM_0032'),
        className: 'saturation-temperature',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'saturation-temperature',
            disabled: true,
            value: p1TempValue?.toString(),
            isResultField: true,
            unit: pUnit,
            isSpecialFormat: true,
          },
        ],
      },
      {
        label: t('CM_0033'),
        className: 'degree-superheat',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'degree-superheat',
            disabled: true,
            value: p1SupperHeatValue?.toString(),
            isResultField: true,
            unit: pUnit,
            isSpecialFormat: true,
          },
        ],
      },
    ],
  };

  const inputContainer = (
    <>
      <div className="fl-rate-container steam">
        <PageHeading borderColor="pear">{t('CM_0078')}</PageHeading>
        <BaseForm options={formOption}></BaseForm>
        <Form
          colon={false}
          // form={inputForm}
          // onValuesChange={onFormValuesChange}
          // initialValues={{
          //   pressureUnit: TEMPERATURE_UNITS[0].unit,
          // }}
        >
          <div className="input-container">
            <div className="final-result fr-steam">
              <Row style={{ marginBottom: '16px' }}>
                <Col>
                  <div className="title">{t('CM_0010')}</div>
                </Col>
                <Col className="col-md">
                  <label className="error-message" hidden={!!errorMessage}>
                    {p1Message}
                  </label>
                  <label className="error-message">{errorMessage}</label>
                </Col>
              </Row>

              <div>
                <Form.Item className="result" label={<label className="title-result">{t('CM_0014')}</label>}>
                  <Row className="result-row" gutter={[16, 16]}>
                    <Col className="col-md">
                      <CustomInput
                        className="input-result"
                        disabled={true}
                        value={msResult?.toString()}
                        isResultField={true}
                      />
                      <p className="unit">m/s</p>
                    </Col>

                    <Col className="col-md">
                      <CustomInput
                        className="input-result"
                        disabled={true}
                        value={ftResult?.toString()}
                        isResultField={true}
                      />
                      <p className="unit">ft/s</p>
                    </Col>
                  </Row>
                </Form.Item>
                <p className="notice">{t('CM_0026')}</p>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
  const children = (
    <>
      {/*{pageTitle}*/}

      {inputContainer}
    </>
  );
  return (
    <>
      {/* <Header /> */}
      <PageContainer>{children}</PageContainer>
    </>
  );
};
