/* eslint-disable max-lines-per-function */
import GoToTop from '../components/Gototop/GoToTop';
import Loading from '../components/Loading/Loading';
import { APP_PATH } from '../constants/paths';
import { langMapHelper, routeCondition } from '../helpers/lang';
import Layout from '../layouts/Layout';
import GasCvCalculation from '../pages/CVCalculate/GasCvCalculation';
import LiquidCVCalculate from '../pages/CVCalculate/LiquidCVCalculate';
import SteamCvCalculation from '../pages/CVCalculate/SteamCvCalculation';
import PressureGasCalculation from '../pages/DifferentialPressureCalculation/DifferentialPressureGasCalculation';
import PressureLiquidCalculation from '../pages/DifferentialPressureCalculation/DifferentialPressureLiquidCalculation';
import PressureSteamCalculation from '../pages/DifferentialPressureCalculation/DifferentialPressureSteamCalculation';
import { DocumentTitle } from '../pages/DocumentTitle';
import FlowGasCalculation from '../pages/FlowCalculation/FlowGasCalculation';
import FlowLiquidCalculation from '../pages/FlowCalculation/FlowLiquidCalculation';
import FlowSteamCalculation from '../pages/FlowCalculation/FlowSteamCalculation';
import { FlowRate } from '../pages/FlowRate/FlowRate';
import { Gases } from '../pages/FlowRate/Gases';
import { Steam } from '../pages/FlowRate/Steam';
import LiquidViscosityUnit from '../pages/FlowUnit/LiquidViscosityUnit';
import TemperatureUnitPage from '../pages/FlowUnit/TemperatureUnitPage';
import VelocityLiquid from '../pages/FlowVelocity/VelocityLiquid';
import Home from '../pages/Home';
import LinearInterpolation from '../pages/LinearInterpolation/LinearInterpolation';
import GasVelocityToFlowRate from '../pages/VelocityToFlowRate/GasVelocityToFlowRate';
import SteamVelocityToFlowRate from '../pages/VelocityToFlowRate/SteamVelocityToFlowRate';
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes as Switch } from 'react-router-dom';

// Split code page by layzy
const LoginPage = React.lazy(() => import('../pages/LoginPage'));
const SignupPage = React.lazy(() => import('../pages/SignupPage'));
const PressurePage = React.lazy(() => import('../pages/FlowUnit/PressureUnitPage'));

const LiquidFlowUnit = React.lazy(() => import('../pages/FlowUnit/LiquidFlowUnit'));

// eslint-disable-next-line max-lines-per-function
const GasFlowUnit = React.lazy(() => import('../pages/FlowUnit/GasFlowUnit'));

interface IRoute {
  path: string;
  element: JSX.Element;
  screenName?: string;
}

export const ROUTE_APP: IRoute[] = [
  {
    path: APP_PATH.LOGIN,
    element: <LoginPage />,
  },
  {
    path: APP_PATH.SIGNUP,
    element: <SignupPage />,
  },
  {
    path: `${APP_PATH.FLOW_CALCULATION}${APP_PATH.LIQUID}`,
    element: <FlowLiquidCalculation />,
    screenName: 'FLOW_CALCULATION_LIQUID',
  },
  {
    path: `${APP_PATH.FLOW_CALCULATION}${APP_PATH.GAS}`,
    element: <FlowGasCalculation />,
    screenName: 'FLOW_CALCULATION_GAS',
  },
  {
    path: `${APP_PATH.FLOW_CALCULATION}${APP_PATH.STEAM}`,
    element: <FlowSteamCalculation />,
    screenName: 'FLOW_CALCULATION_STEAM',
  },
  {
    path: `${APP_PATH.DIFF_PRESSURE_CALCULATION}${APP_PATH.LIQUID}`,
    element: <PressureLiquidCalculation />,
    screenName: 'DIFFERENTIAL_PRESSURE_CALCULATION_LIQUID',
  },
  {
    path: `${APP_PATH.DIFF_PRESSURE_CALCULATION}${APP_PATH.GAS}`,
    element: <PressureGasCalculation />,
    screenName: 'DIFFERENTIAL_PRESSURE_CALCULATION_GAS',
  },
  {
    path: `${APP_PATH.DIFF_PRESSURE_CALCULATION}${APP_PATH.STEAM}`,
    element: <PressureSteamCalculation />,
    screenName: 'DIFFERENTIAL_PRESSURE_CALCULATION_STEAM',
  },
  {
    path: APP_PATH.TEMPERATURE_UNIT,
    element: <TemperatureUnitPage />,
    screenName: 'TEMPERATURE_UNIT',
  },
  {
    path: APP_PATH.PRESSURE_UNIT,
    element: <PressurePage />,
    screenName: 'PRESSURE_UNIT',
  },
  {
    path: APP_PATH.GAS_UNIT,
    element: <GasFlowUnit />,
    screenName: 'GAS_UNIT',
  },
  {
    path: APP_PATH.LIQUID_UNIT,
    element: <LiquidFlowUnit />,
    screenName: 'LIQUID_UNIT',
  },
  {
    path: `${APP_PATH.LIQUID_VISCOSITY_UNIT}`,
    element: <LiquidViscosityUnit />,
    screenName: 'LIQUID_VISCOSITY_UNIT',
  },
  {
    path: `${APP_PATH.FLOW_VELOCITY}${APP_PATH.LIQUID}`,
    element: <VelocityLiquid />,
    screenName: 'FLOW_VELOCITY_LIQUID',
  },
  {
    path: `${APP_PATH.GAS_CV_CALCULATION}`,
    element: <GasCvCalculation />,
    screenName: 'GAS_CV_CALCULATION',
  },
  {
    path: `${APP_PATH.LIQUID_CV_CALCULATION}`,
    element: <LiquidCVCalculate />,
    screenName: 'LIQUID_CV_CALCULATION',
  },
  { path: `${APP_PATH.FLOW_RATE}`, element: <FlowRate />, screenName: 'FLOW_RATE_LIQUID' },
  {
    path: `${APP_PATH.STEAM_CV_CALCULATION}`,
    element: <SteamCvCalculation />,
    screenName: 'STEAM_CV_CALCULATION',
  },
  {
    path: `${APP_PATH.FLOW_RATE_GAS}`,
    element: <Gases />,
    screenName: 'FLOW_RATE_GASES',
  },
  {
    path: `${APP_PATH.STEAM_VELOCITY_TO_FLOW_RATE}`,
    element: <SteamVelocityToFlowRate />,
    screenName: 'STEAM_VELOCITY_TO_FLOW_RATE',
  },
  {
    path: `${APP_PATH.GAS_VELOCITY_TO_FLOW_RATE}`,
    element: <GasVelocityToFlowRate />,
    screenName: 'GAS_VELOCITY_TO_FLOW_RATE',
  },
  {
    path: `${APP_PATH.FLOW_RATE_STEAM}`,
    element: <Steam />,
    screenName: 'FLOW_RATE_STEAM',
  },
];

export default function Routes(): JSX.Element {
  return (
    <Suspense fallback={<div></div>}>
      <BrowserRouter>
        <GoToTop />
        <Switch>
          <Route path="/" element={<Home />}></Route>
          <Route element={<Layout />}>
            {/* {ROUTE_APP.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<Suspense fallback={<Loading />}>{route.element}</Suspense>}
              />
            ))} */}

            {langMapHelper((langData) => {
              if (!routeCondition(langData)) {
                return null;
              }

              return (
                <Route path={langData.path} key={`${langData.path}`}>
                  {ROUTE_APP.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      element={
                        <Suspense fallback={<Loading />}>
                          <DocumentTitle screenName={route.screenName}>{route.element}</DocumentTitle>
                        </Suspense>
                      }
                    />
                  ))}
                </Route>
              );
            })}
          </Route>
          {langMapHelper((langData) => {
            if (!routeCondition(langData)) {
              return null;
            }

            return (
              <Route path={langData.path} key={`${langData.path}`}>
                <Route
                  path={APP_PATH.LINEAR_INTERPOLATION}
                  element={
                    <DocumentTitle screenName={'LINEAR_INTERPOLATION'}>
                      <LinearInterpolation />
                    </DocumentTitle>
                  }
                ></Route>
                ;
              </Route>
            );
          })}
          <Route path="*" element={<Home />}></Route>
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}
