/**
 * Calculate steam flow rate page
 *
 * Created: 2022/17/12
 * __author__: DienPD1
 * __copyright__:Copyright KITZ Inc. 2022 All Rights Reserved
 * __version__: 1.0
 */
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'antd';
import PageContainer from '../../layouts/PageContainer';
import '../../styles/pages/FlowLiquidCalculation.scss';
import { useTranslation } from 'react-i18next';
import PageHeading from '../../components/Heading/PageHeading';
import {
  FLUID_STATES,
  MASS_FLOW_STEAM_TABLE_UNITS,
  PRESSURE_CLASSES,
  PRESSURE_UNITS,
  TEMPERATURE_UNITS,
} from './constants';
import {
  IFlowSteamCalculationReq,
  ISaturatedTemperatureReq,
  ISuperHeatedTemperatureReq,
} from '../../interfaces/flowCalculation';
import {
  getCalculateDifferentPressure,
  getCalculatePressureRatio,
  getFlowSteamCalculateResult,
  getCalculateSaturatedTemperature,
  getCalculateSuperHeatedTemperature,
} from '../../services/flowCalculation';
import { CommonUnit, P_STRING } from '../../constants/units';
import {
  displayPressureRatioResponse,
  isNumeric,
  isValidateFieldsNotEmpty,
  displayToFixed2Response,
  displayResponseData,
  minusString,
  isCannotCalcPressure,
  EMPTY_STRING,
} from '../../helpers/utils';
import UnitTable from '../../components/UnitTable/UnitTable';
import { useCustomForm } from '../../hooks/useCustomForm';
import { getDefaultRule, getNegativeNumberRule } from '../../constants/rules';
import { getMessageByErrorCode } from '../../helpers/common';
import { CustomInput } from '../../components/CustomInput/CustomInput';
import { BaseFormInputType, IBaseFormOptions } from '../../components/BaseForm/Interface';
import { BaseForm } from '../../components/BaseForm';
import { AxiosResponseData, ErrorType, SingleData, TableData } from '../../services/http-client';
import { PressureUnitsID } from '../../constants/unitsID';

/**
 * Calculate steam flow rate UI
 *
 * Created: 2022/17/12
 *  @author DienPD1
 */
// eslint-disable-next-line max-lines-per-function
export default function FlowSteamCalculation(): JSX.Element {
  const { t } = useTranslation();
  const [inputForm] = useCustomForm(Form.useForm());
  const defaultValidateFields = ['cvValue', 'p1', 'p2'];
  const advancedValidateFields = ['cvValue', 'p1', 'p2', 't1'];
  const validateFields = useRef<string[]>(defaultValidateFields);
  const beValidateErrorCodes = [1505, 1506, 1507, 1510, 1511, 1512, 2001];
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [judgementMessage, setJudgementMessage] = useState<string>('');
  const [p1, onP1Change] = useState<string>('');
  const [p2, onP2Change] = useState<string>('');
  const [t1, onT1Change] = useState<string>('');
  const [temperatureUnit, setTemperatureUnit] = useState<string>(TEMPERATURE_UNITS[0].value);
  const [pressureUnit, onPressureUnitSelect] = useState<string>(PRESSURE_UNITS[0].value);
  const [pressureClass, onPressureClassSelect] = useState<string>(PRESSURE_CLASSES[0].value);
  const [deltaP, setDifferentPressure] = useState<string>('');
  const [deltaPUnit, setDeltaPUnit] = useState<string>('');
  const [p2Unit, setP2Unit] = useState<string>('');
  const [fluidState, setFluidState] = useState<string>(FLUID_STATES[0].value);
  const [gasMasses, setGasMasses] = useState<CommonUnit[]>(MASS_FLOW_STEAM_TABLE_UNITS);
  const [saturationDegree, setSaturationDegree] = useState<string>();
  const [superHeatDegree, setSuperHeatedDegree] = useState<string>();
  const pressureRatio = useRef('');
  const [currentTempUnit, setTempUnit] = useState<string>(`${TEMPERATURE_UNITS[0].unit}`);
  const isSaturationError = useRef<boolean>(false);

  /**
   * Handle form values change
   *
   * Created: 2022/17/12
   *  @author DienPD1
   */
  // eslint-disable-next-line max-lines-per-function
  const onFormValuesChange = (): void => {
    const isFormValid = isValidateFieldsNotEmpty(inputForm, validateFields.current);
    const isPressFieldsValid = isValidateFieldsNotEmpty(inputForm, ['p1', 'p2']);
    !isPressFieldsValid && (pressureRatio.current = '');
    inputForm
      .validateFields(validateFields.current)
      .then(() => {
        setErrorMessage('');
        setJudgementMessage('');
        if (inputForm.getFormEmtyOrError(validateFields.current).isOneFieldEmpty) {
          resetTable();
          return;
        }
        isFormValid && getResult();
      })
      .catch(() => {
        setErrorMessage(inputForm.customError[0]?.msg);
        if (isCannotCalcPressure('p1', inputForm.customError) || isCannotCalcPressure('p2', inputForm.customError)) {
          setSaturationDegree(minusString);
          setSuperHeatedDegree(minusString);
          pressureRatio.current = EMPTY_STRING;
        }
        setJudgementMessage(`${t('CM_0178')}`);
        resetTable();
      });
  };

  /**
   * Reset table data
   *
   * Created: 2022/9/2
   *  @author DienPD1
   */
  const resetTable = (): void => {
    setGasMasses(MASS_FLOW_STEAM_TABLE_UNITS);
  };

  /**
   * Get data form input fields
   *
   * Created: 2022/17/12
   *  @author DienPD1
   */
  const getInputData = (): IFlowSteamCalculationReq => {
    let data: IFlowSteamCalculationReq = {
      Cv: Number(inputForm.getFieldValue('cvValue')),
      P1: Number(inputForm.getFieldValue('p1')),
      P2: Number(inputForm.getFieldValue('p2')),
      P_unit_in: `${inputForm.getFieldValue('pressureClass')}${inputForm.getFieldValue(
        'pressureUnit',
      )}`.toLocaleUpperCase(),
      S: inputForm.getFieldValue('fluidState'),
    };
    if (inputForm.getFieldValue('fluidState') === FLUID_STATES[1].value) {
      data = {
        ...data,
        ...{
          T1: Number(inputForm.getFieldValue('t1')),
          T_unit_in: inputForm.getFieldValue('temperatureUnit'),
        },
      };
    }
    return data;
  };

  /**
   * Call api get calculated saturated temperature at P1
   *
   * Created: 2022/17/12
   *  @author DienPD1
   */
  const getSaturatedTemperatureAtP1 = async (): Promise<void> => {
    try {
      const pData: ISaturatedTemperatureReq = {
        P: Number(inputForm.getFieldValue('p1')),
        P_unit_in: `${inputForm.getFieldValue('pressureClass')}${inputForm.getFieldValue(
          'pressureUnit',
        )}`.toLocaleUpperCase(),
      };
      const response = await getCalculateSaturatedTemperature(pData);
      const data = response.data as AxiosResponseData;
      if (data.customError && data.customError.field && data.customError.field[0] === P_STRING) {
        isSaturationError.current = true;
      }
      if (
        !data.customError &&
        data.data &&
        inputForm.getFieldValue('temperatureUnit') &&
        data.data?.[inputForm.getFieldValue('temperatureUnit') as keyof SingleData]
      ) {
        isSaturationError.current = false;
        setSaturationDegree(data.data[inputForm.getFieldValue('temperatureUnit') as keyof SingleData].toString());
      } else {
        setSaturationDegree(undefined);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Call api get calculated super heated temperature at P1
   *
   * Created: 2022/17/12
   *  @author DienPD1
   */
  const getSuperHeatedTemperature = async (): Promise<void> => {
    try {
      const inputData: ISuperHeatedTemperatureReq = {
        P1: Number(inputForm.getFieldValue('p1')),
        P_unit_in: `${inputForm.getFieldValue('pressureClass')}${inputForm.getFieldValue(
          'pressureUnit',
        )}`.toLocaleUpperCase(),
        T1: Number(inputForm.getFieldValue('t1')),
        T_unit_in: inputForm.getFieldValue('temperatureUnit'),
      };
      const response = await getCalculateSuperHeatedTemperature(inputData);
      const data = response.data as AxiosResponseData;
      if (!data.customError && data.data?.value) {
        setSuperHeatedDegree(data.data.value.toString());
      } else {
        setSuperHeatedDegree(undefined);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Call api get calculated gas flow rate
   *
   * Created: 2022/17/12
   *  @author DienPD1
   */
  // eslint-disable-next-line max-lines-per-function, complexity
  const getResult = async (): Promise<void> => {
    try {
      const response = await getFlowSteamCalculateResult(getInputData());
      const data = response.data as AxiosResponseData;
      if (data.customError) {
        const customError = data.customError;
        const errCode = customError.code[0];
        if (customError.type === ErrorType.Validate) {
          const fieldName = customError.field?.[0];
          if (errCode && beValidateErrorCodes.includes(errCode)) {
            setErrorMessage(getMessageByErrorCode(errCode, fieldName));
            setJudgementMessage(`${t('CM_0178')}`);
          } else {
            setJudgementMessage(`${t('CM_0040')}`);
          }
        } else {
          setJudgementMessage(`${t(errCode.toString())}`);
        }
        resetTable();

        return;
      }
      setJudgementMessageByRatio();
      setResultToTable(data);
    } catch (error) {
      console.log(error);
    }
  };

  const setJudgementMessageByRatio = (): void => {
    if (Number(pressureRatio.current) < 0.5) {
      setJudgementMessage(`${t('CM_0040')}`);
    } else {
      setJudgementMessage(`${t('CM_0196')}`);
    }
  };

  /**
   * Set calculated results to table
   *
   * Created: 2022/17/12
   *  @author DienPD1
   */
  const setResultToTable = (data: AxiosResponseData): void => {
    const tableData = data.data as TableData;
    const massUnits = gasMasses.map((item) => {
      if (tableData[item.value] || tableData[item.value] === 0) {
        return { ...item, result: tableData[item.value] };
      }
      return item;
    });
    setGasMasses(massUnits);
  };

  /**
   * Call api get differential pressure and pressure ratio
   *
   * Created: 2022/17/12
   *  @author DienPD1
   */
  const calculatePressure = async (p1: number, p2: number): Promise<void> => {
    try {
      const [differentPressureRes, pressureRatioRes] = await Promise.all([
        getCalculateDifferentPressure({ P1: p1, P2: p2 }),
        getCalculatePressureRatio({
          P1: p1,
          P2: p2,
          P_unit_in: `${inputForm.getFieldValue('pressureClass')}${inputForm.getFieldValue(
            'pressureUnit',
          )}`.toLocaleUpperCase(),
        }),
      ]);
      const diffPressureData = differentPressureRes.data as AxiosResponseData;
      if (!diffPressureData.customError && (diffPressureData.data?.value || diffPressureData.data?.value === 0)) {
        setDifferentPressure(`${displayResponseData(diffPressureData.data?.value)}`);
      } else {
        setDifferentPressure('');
      }
      const pressureRatioData = pressureRatioRes.data as AxiosResponseData;
      if (!pressureRatioData.customError && pressureRatioData.data?.value) {
        pressureRatio.current = `${pressureRatioData.data?.value}`;
      } else {
        pressureRatio.current = '';
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isNumeric(p1) && isNumeric(p2)) {
      calculatePressure(Number(p1), Number(p2));
    } else {
      setDifferentPressure('');
      pressureRatio.current = '';
    }
    if (inputForm.getFieldError(['p1']).length === 1) {
      onP1Change('CM_0205');
    }
  }, [p1, p2, pressureClass, pressureUnit]);

  useEffect(() => {
    const selectedUnit = PRESSURE_UNITS.find((item) => item.value === pressureUnit);
    const selectedClass = PRESSURE_CLASSES.find((item) => item.value === pressureClass);
    if (selectedUnit && selectedClass) {
      if (
        ([PressureUnitsID.mmHgTorr, PressureUnitsID.mmH2OmmAq, PressureUnitsID.psilbfin2] as string[]).includes(
          selectedUnit.value,
        )
      ) {
        const splitUnit = selectedUnit.unit.split(' or ');
        setP2Unit(`${splitUnit[0]}(${selectedClass.unit}) or ${splitUnit[1]}(${selectedClass.unit})`);
      } else {
        setP2Unit(`${selectedUnit.unit}(${selectedClass.unit})`);
      }
      setDeltaPUnit(`${selectedUnit.unit}`);
    }
  }, [pressureUnit, pressureClass]);

  useEffect(() => {
    if (isNumeric(p1)) {
      getSaturatedTemperatureAtP1();
    } else {
      setSaturationDegree(undefined);
    }
  }, [temperatureUnit, p1, pressureUnit, pressureClass, p2]);

  useEffect(() => {
    if (isNumeric(p1) && isNumeric(t1)) {
      getSuperHeatedTemperature();
    } else {
      setSuperHeatedDegree(undefined);
    }
  }, [t1, temperatureUnit, p1, pressureUnit, pressureClass]);

  useEffect(() => {
    if (fluidState === FLUID_STATES[0].value) {
      validateFields.current = defaultValidateFields;
      setSuperHeatedDegree(undefined);
      onFormValuesChange();
    } else {
      validateFields.current = advancedValidateFields;
      setGasMasses(MASS_FLOW_STEAM_TABLE_UNITS);
    }
  }, [fluidState]);

  const showErrorMessage = (): boolean => {
    if (!inputForm.getFieldValue('p1')) {
      return false;
    }
    const isP1Error = inputForm.getFieldError(['p1']).length === 1 || isSaturationError.current;
    if (isP1Error) {
      if (isCannotCalcPressure('p1', inputForm.customError)) {
        return true;
      }
    }
    return isP1Error;
  };

  const displaySuperheatDegree = (): string => {
    if (showErrorMessage() && fluidState === FLUID_STATES[1].value && inputForm.getFieldValue('t1')) {
      return `${t('CM_0205')}`;
    } else if (fluidState === FLUID_STATES[0].value || !inputForm.getFieldValue('t1')) {
      return EMPTY_STRING;
    }
    return displayToFixed2Response(superHeatDegree);
  };

  const baseFormOpt: IBaseFormOptions = {
    form: inputForm,
    onFormValuesChange: onFormValuesChange,
    fields: [
      {
        label: t('CM_0011'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'cvValue',
            rules: getNegativeNumberRule(t('CM_0011'), undefined, undefined, undefined, true),
            placeholder: t('CM_0129'),
          },
        ],
      },
      {
        label: t('CM_0003'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'p1',
            rules: getDefaultRule(t(`${t('CM_0194')}`), undefined, true),
            onChange: (e): void => onP1Change(e.target.value),
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            name: 'pressureUnit',
            value: pressureUnit,
            options: PRESSURE_UNITS.map((item) => ({ key: item.unit, value: item.value, label: item.unit })),
            onChange: (value: string): void => onPressureUnitSelect(value),
          },
          {
            type: BaseFormInputType.Select,
            name: 'pressureClass',
            value: pressureClass,
            options: PRESSURE_CLASSES.map((item) => ({ key: item.unit, value: item.value, label: t(item.name) })),
            onChange: (value: string): void => onPressureClassSelect(value),
          },
        ],
      },
      {
        label: t('CM_0004'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'p2',
            rules: getDefaultRule(t(`${t('CM_0195')}`), undefined, true),
            onChange: (e): void => onP2Change(e.target.value),
            placeholder: t('CM_0129'),
            unit: p2Unit,
          },
        ],
      },
      {
        label: t('CM_0005'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            isResultField: true,
            disabled: true,
            name: 'delta',
            value: deltaP,
            unit: deltaPUnit,
            className: 'input-result',
            isSpecialFormat: true,
          },
        ],
      },
      {
        label: t('CM_0138'),
        inputs: [
          {
            type: BaseFormInputType.RadioGroup,
            name: 'fluidState',
            options: FLUID_STATES.map((item) => ({ key: item.unit, value: item.value, label: t(item.name) })),
            value: fluidState,
            note: <>{t('CM_0031')}</>,
            onChange: (e): void => {
              if (e.target.value === FLUID_STATES[0].value) {
                inputForm.setFieldValue('t1', EMPTY_STRING);
                setSuperHeatedDegree(EMPTY_STRING);
              }
              setFluidState(e.target.value);
            },
          },
        ],
      },
      {
        label: t('CM_0018'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 't1',
            rules: getDefaultRule(t('CM_0159'), undefined, true),
            placeholder: t('CM_0129'),
            disabled: fluidState === FLUID_STATES[0].value,
            onChange: (e) => onT1Change(e.target.value),
          },
          {
            type: BaseFormInputType.Select,
            name: 'temperatureUnit',
            value: TEMPERATURE_UNITS[0].value,
            options: TEMPERATURE_UNITS.map((item) => ({
              key: item.unit,
              value: item.value,
              label: `${t(item.name)}`,
            })),
            onChange: (e): void => {
              setTempUnit(`${TEMPERATURE_UNITS.filter((item) => item.value === e)[0].unit}`);
              setTemperatureUnit(e);
            },
          },
        ],
      },
      {
        label: t('CM_0032'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'saturationDegree',
            className: 'input-result',
            disabled: true,
            isResultField: true,
            value: showErrorMessage() ? `${t('CM_0205')}` : displayToFixed2Response(saturationDegree),
            unit: currentTempUnit,
            isSpecialFormat: true,
          },
        ],
      },
      {
        label: t('CM_0033'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'superheatDegree',
            className: 'input-result',
            disabled: true,
            isResultField: true,
            value: displaySuperheatDegree(),
            unit: currentTempUnit,
            isSpecialFormat: true,
          },
        ],
      },
    ],
  };

  const children = (
    <div className="flow-calculation-container fl-steam-cal steam">
      <PageHeading borderColor="pear">{t('CM_0060')}</PageHeading>
      <BaseForm options={baseFormOpt}></BaseForm>
      <div className="result-container">
        <div className="result">
          <div className="title">{t('CM_0010')}</div>
          <div className="error-message">{errorMessage}</div>
        </div>
        <div className="flex flex-wrap">
          <div className="judgement">
            <div className="title">{t('CM_0037')}</div>
            <Row gutter={16}>
              <Col>
                <CustomInput
                  className="input-result"
                  isResultField
                  value={judgementMessage}
                  disabled={true}
                  placeholder=""
                />
              </Col>
            </Row>
          </div>
          <div className="ratio">
            <div className="title">{t('CM_0012')}</div>
            <Row gutter={16}>
              <Col>
                <CustomInput
                  isResultField
                  className={`col-md text-right input-result ${
                    isNumeric(pressureRatio.current) &&
                    Number(displayPressureRatioResponse(pressureRatio.current)) >= 0.5
                      ? 'highlight-input'
                      : ''
                  }`}
                  value={displayPressureRatioResponse(pressureRatio.current)}
                  disabled={true}
                  placeholder=""
                  isSpecialFormat
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="result-table">
          <UnitTable dataTable1={gasMasses} titleTable1={t('CM_0036') || ''}></UnitTable>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <PageContainer>{children}</PageContainer>
    </>
  );
}
