/**
 * Apis are used for different pressure calculation
 *
 * Created: 2022/15/12
 * __author__: DienPD1
 * __copyright__:
 * __version__: 1.0
 */
import { AxiosResponse } from 'axios';
import req from './http-client';
import { API_PATHS } from '../constants/apiPaths';
import {
  IDifferentialGasCalculation,
  IDifferentialLiquidCalculation,
  IDifferentialSteamCalculation,
} from '../interfaces/differentialPressureCalculation';
import { ISaturatedTemperatureReq, ISuperHeatedTemperatureReq } from '../interfaces/flowCalculation';

export interface IResponseData {
  [x: string]: number;
}

/**
 * Api get different pressure
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
export function getCalculateDifferentPressure(inputData: {
  P1: number;
  P2: number;
}): Promise<AxiosResponse<{ data: IResponseData }>> {
  return req.post(API_PATHS.CALC_DIFF_PRESS, inputData);
}

/**
 * Api get pressure ratio
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
export function getCalculatePressureRatio(inputData: {
  P1: number;
  P2: number;
}): Promise<AxiosResponse<{ data: IResponseData }>> {
  return req.post(API_PATHS.CALC_PRESS_RATIO, inputData);
}

/**
 * Api get saturated temperature calculated
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
export function getCalculateSaturatedTemperature(
  inputData: ISaturatedTemperatureReq,
): Promise<AxiosResponse<{ data: IResponseData }>> {
  return req.post(API_PATHS.CALC_SATURATION_TEMPERATURE, inputData);
}

/**
 * Api get super heated temperature calculated
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
export function getCalculateSuperHeatedTemperature(
  inputData: ISuperHeatedTemperatureReq,
): Promise<AxiosResponse<{ data: IResponseData }>> {
  return req.post(API_PATHS.CALC_SUPER_HEAT, inputData);
}

/**
 * Api get differential pressure liquid calculated
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
export function getDifferentialPressureLiquidCalculateResult(
  inputData: IDifferentialLiquidCalculation,
): Promise<AxiosResponse<{ data: IResponseData }>> {
  return req.post(API_PATHS.CALC_DIFF_PRESS_LIQUID, inputData);
}

/**
 * Api get differential pressure gas calculated
 *
 * Created: 2022/15/12
 *  @author DienPD1
 */
export function getDifferentialPressureGasCalculateResult(
  inputData: IDifferentialGasCalculation,
): Promise<AxiosResponse<{ data: IResponseData }>> {
  return req.post(API_PATHS.CALC_DIFF_PRESS_GAS, inputData);
}

/**
 * Api get differential pressure steam calculated
 *
 * Created: 2022/25/12
 *  @author DienPD1
 */
export function getDifferentialPressureSteamCalculateResult(
  inputData: IDifferentialSteamCalculation,
): Promise<AxiosResponse<{ data: IResponseData }>> {
  return req.post(API_PATHS.CALC_DIFF_PRESS_STEAM, inputData);
}
