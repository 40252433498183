/* eslint-disable @typescript-eslint/no-explicit-any */
import { API_PATHS } from '../constants/apiPaths';
import apiClient from './http-client';

interface DataPostSteam {
  P1: number;
  P_unit_in: string;
  W: number;
  W_unit_in: string;
  D: number;
  S: string;
}

interface dataPostSatTemp {
  P: number | undefined;
  P_unit_in: string;
}

interface dataPostSupperHeat {
  P1: number | undefined;
  P_unit_in: string;
  T1: number | undefined;
  T_unit_in: string;
}

interface dataPostVelFromFlowRateLiq {
  D: number;
  Q: number;
  Q_unit_in: string;
  Gf?: number;
}

export async function setDataSteam(dataPost: DataPostSteam): Promise<any> {
  try {
    const data = await apiClient.post(API_PATHS.CALC_VEL_FROM_FR_STEAM, dataPost);
    return data.data;
  } catch (error) {
    console.log(error);
  }
}

export async function setResultSuperheated(dataPost: dataPostSatTemp): Promise<any> {
  try {
    const data = await apiClient.post(API_PATHS.CALC_SATURATED_TEMP, dataPost);
    return data.data;
  } catch (error) {
    console.log(error);
  }
}

export async function setdataPostSupperHeat(dataPost: dataPostSupperHeat): Promise<any> {
  try {
    const data = await apiClient.post(API_PATHS.CALC_SUPER_HEAT, dataPost);
    return data.data;
  } catch (error) {
    console.log(error);
  }
}

export async function calcVelFromFlowRateLiq(dataPost: dataPostVelFromFlowRateLiq): Promise<any> {
  try {
    const data = await apiClient.post(API_PATHS.CALC_VEL_FROM_FLOW_RATE_LIQ, dataPost);
    return data.data;
  } catch (error) {
    console.log(error);
  }
}
