import { t } from 'i18next';
import React from 'react';

import backLogo from '../assets/locales/picture/logout.png';
import { DEFAULT_LANGUAGE } from '../helpers/lang';
import i18n from '../i18n';
import '../styles/layout/header.scss';

export default function Header(): JSX.Element {
  const getHomeLink = (): string => {
    if (i18n.language === DEFAULT_LANGUAGE) {
      return 'https://www.kitz.co.jp/calculator/';
    }
    return 'https://www.kitz.com/en/calculator/';
  };

  return (
    <div className="kitz-header-wrap ">
      <a href={getHomeLink()}>
        <div
          className="kitz-logo-wrap"
          onContextMenu={(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => e.preventDefault()}
        >
          <img className="kitz-logo nav_menu-logo" src="/../icon/header_logo.png" alt="" />
        </div>
      </a>
      <div className="kitz-back">
        <a href={getHomeLink()}>
          <div className="kitz-back back-to-home">
            <img src={backLogo}></img>
            <span>{t('CM_0204')}</span>
          </div>
        </a>
      </div>
    </div>
  );
}
