/**
 * Gas Cv Calculation
 *
 * Created: 2022/05/12
 * __author__: VuongLK
 * __copyright__: Copyright KITZ Corporation. 2022 All Rights Reserved
 * __version__: 1.1.0
 */

import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  FLOW_RATE_GAS_UNITS,
  GREAT_PRESSURE,
  P1,
  PRESSURE_CLASSES,
  PRESSURE_UNITS,
  TEMPERATURE_UNITS,
} from '../../constants/units';
import './style.scss';
import PageHeading from '../../components/Heading/PageHeading';
import { getDefaultRule, getNegativeNumberRule } from '../../constants/rules';
import { STATUS_CODE } from '../../constants/statusCode';
import ResultCV from '../../components/ResultCV/ResultCV';
import { useCustomForm } from '../../hooks/useCustomForm';
import { useRef, useState } from 'react';
import { getSelectUnit } from '../../helpers/common';
import { displayResponseData } from '../../helpers/utils';
import { API_PATHS } from '../../constants/apiPaths';
import { getResultAPI } from '../../services/http-client';
import PageContainer from '../../layouts/PageContainer';
import { BaseForm } from '../../components/BaseForm';
import { BaseFormInputType, IBaseFormOptions } from '../../components/BaseForm/Interface';
import {
  FlowRateGasUnitsID,
  PressureClassesID,
  PressureUnitsID,
  TemperatureUnitsID,
  VelocityUnitsID,
} from '../../constants/unitsID';

// eslint-disable-next-line max-lines-per-function
function GasCvCalculation(): JSX.Element {
  const { t } = useTranslation();
  const [inputForm] = useCustomForm(Form.useForm());
  const validateFields = ['v', 'p1', 'p2', 'gg', 't1'];

  const [pressureUnit, setPressureUnit] = useState('01');
  const [classesUnit, setClassesUnit] = useState('G');
  const [deltaP, setDeltaP] = useState<string>('');
  const [error, setError] = useState('');
  const [errorCV, setErrorCV] = useState('');
  const [errorOfDownstreamPipe, setErrorOfDownstreamPipe] = useState('');

  const downstreamPipe = useRef('');
  const [valueCV, setValueCV] = useState<string>('');
  const [pressureRatio, setPressureRatio] = useState<string>('');
  const [resDownstreamPipe, setResDownstreamPipe] = useState({ ft_s: '', m_s: '' });

  // eslint-disable-next-line max-lines-per-function, complexity
  async function getResult(): Promise<void> {
    const dataPressRatio = ['p1', 'p2'];

    if (dataPressRatio.every((field) => inputForm.getFieldValue(field) && !inputForm.getFieldError(field)[0])) {
      const dataPostDiffPressure = {
        P1: Number(inputForm.getFieldValue('p1')),
        P2: Number(inputForm.getFieldValue('p2')),
      };
      const dataPost = {
        ...dataPostDiffPressure,
        P_unit_in: `${inputForm.getFieldValue('classesUnit')}${inputForm.getFieldValue('pressureUnit')}`,
      };
      const diffPressureData = await getResultAPI(dataPostDiffPressure, API_PATHS.CALC_DIFF_PRESS);
      diffPressureData?.data &&
        (diffPressureData.data.value || diffPressureData.data.value === 0) &&
        setDeltaP(displayResponseData(diffPressureData.data.value));

      const data = await getResultAPI(dataPost, API_PATHS.CALC_PRESS_RATIO);

      if (data && data?.code && data.code === STATUS_CODE.SUCCESS) {
        data?.data && setPressureRatio(data?.data?.value.toString());
      } else {
        setPressureRatio('');
      }
    } else {
      setDeltaP('');
      setPressureRatio('');
    }

    if (inputForm.getFieldValue('v') && !inputForm.getFieldError('v')[0] && downstreamPipe.current) {
      const dataPost = {
        D: Number(downstreamPipe.current),
        V: Number(inputForm.getFieldValue('v')),
        V_unit_in: inputForm.getFieldValue('vUnit'),
        P1:
          inputForm.getFieldValue('p1') && !inputForm.getFieldError('p1')[0]
            ? Number(inputForm.getFieldValue('p1'))
            : undefined,
        P2:
          inputForm.getFieldValue('p2') && !inputForm.getFieldError('p2')[0]
            ? Number(inputForm.getFieldValue('p2'))
            : undefined,
        P_unit_in: `${inputForm.getFieldValue('classesUnit')}${inputForm.getFieldValue('pressureUnit')}`,
        T1:
          inputForm.getFieldValue('t1') && !inputForm.getFieldError('t1')[0]
            ? Number(inputForm.getFieldValue('t1'))
            : undefined,
        T_unit_in: inputForm.getFieldValue('t1Unit'),
        Gg:
          inputForm.getFieldValue('gg') && !inputForm.getFieldError('gg')[0]
            ? Number(inputForm.getFieldValue('gg'))
            : undefined,
        defaultP2: true,
      };

      const data = await getResultAPI(dataPost, API_PATHS.CALC_VEL_FROM_FLOW_RATE_GAS);

      if (data && data?.code && data.code === STATUS_CODE.SUCCESS) {
        data?.data &&
          setResDownstreamPipe({
            ft_s: data?.data[VelocityUnitsID.fts].toString(),
            m_s: data?.data[VelocityUnitsID.ms].toString(),
          });
        setErrorOfDownstreamPipe('');
      } else {
        setResDownstreamPipe({ ft_s: `${t('CM_0178')}`, m_s: `${t('CM_0178')}` });
      }
    } else {
      if (downstreamPipe.current) {
        setErrorOfDownstreamPipe('');
        setResDownstreamPipe({ ft_s: '', m_s: '' });
      }
    }

    const fieldsCvGasSimple = ['v', 'p1', 'p2', 't1', 'gg'];

    if (fieldsCvGasSimple.every((field) => inputForm.getFieldValue(field))) {
      if (fieldsCvGasSimple.every((field) => !inputForm.getFieldError(field)[0])) {
        const dataPost = {
          V: Number(inputForm.getFieldValue('v')),
          V_unit_in: inputForm.getFieldValue('vUnit'),
          P1: Number(inputForm.getFieldValue('p1')),
          P2: Number(inputForm.getFieldValue('p2')),
          P_unit_in: `${inputForm.getFieldValue('classesUnit')}${inputForm.getFieldValue('pressureUnit')}`,
          T1: Number(inputForm.getFieldValue('t1')),
          T_unit_in: inputForm.getFieldValue('t1Unit'),
          Gg: Number(inputForm.getFieldValue('gg')),
        };

        const data = await getResultAPI(dataPost, API_PATHS.CALC_CV_GAS_SIMPLE);

        if (data && data?.code && data.code === STATUS_CODE.SUCCESS) {
          data.data && setValueCV(`${data.data.value}`);
          setError('');
        } else {
          if (data && data.customError?.code[0]) {
            const customError = data.customError;
            const code = data.customError.code[0];

            if (code === GREAT_PRESSURE) {
              const key = customError.field[0];
              key === P1
                ? setError(t('CM_0193', { fieldName: `${t('CM_0194')}` }).toString())
                : setError(t('CM_0193', { fieldName: `${t('CM_0195')}` }).toString());
              setValueCV(t('CM_0178').toString());
              setErrorCV(t('CM_0178').toString());
              return;
            }

            code && setError(t(code.toString()).toString());
          }

          setValueCV(t('CM_0178').toString());
          setErrorCV(t('CM_0178').toString());
        }
      } else {
        setValueCV(t('CM_0178').toString());
        setErrorCV(t('CM_0178').toString());
      }
    } else {
      setValueCV('');
      setErrorCV('');
    }
  }

  const onFormValuesChange = (): void => {
    getResult();
    inputForm
      .validateFields(validateFields)
      .then(() => {
        setError('');
      })
      .catch(() => {
        if (inputForm.customError[0]?.msg) {
          setError(inputForm.customError[0]?.msg);
        } else {
          setError('');
          setErrorOfDownstreamPipe('');
        }
      });
  };

  const baseFormOpt: IBaseFormOptions = {
    form: inputForm,
    onFormValuesChange,
    className: '',
    fields: [
      {
        label: t('CM_0017'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'v',
            rules: getNegativeNumberRule(t('CM_0160'), undefined, undefined, undefined, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            name: 'vUnit',
            options: FLOW_RATE_GAS_UNITS.map((item) => ({
              key: item.value,
              value: item.value,
              label: `${t(item.name)}`,
            })),
            value: FlowRateGasUnitsID.Nm3h,
            note: <div style={{ whiteSpace: 'pre-line' }}>{t('CM_0053')}</div>,
          },
        ],
      },
      {
        label: t('CM_0003'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'p1',
            rules: getDefaultRule(t(`${t('CM_0194')}`), undefined, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            value: PressureUnitsID.Mpa,
            name: 'pressureUnit',
            options: PRESSURE_UNITS.map((item) => ({ key: item.unit, value: item.value, label: item.unit })),
            onChange: (value: string): void => setPressureUnit(value),
          },
          {
            type: BaseFormInputType.Select,
            value: PressureClassesID.Gauge,
            name: 'classesUnit',
            options: PRESSURE_CLASSES.map((item) => ({ key: item.unit, value: item.value, label: t(item.name) })),
            onChange: (value: string): void => setClassesUnit(value),
          },
        ],
      },
      {
        label: t('CM_0004'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'p2',
            rules: getDefaultRule(t(`${t('CM_0195')}`), undefined, true),
            placeholder: t('CM_0129'),
            unit: getSelectUnit(pressureUnit, classesUnit) && getSelectUnit(pressureUnit, classesUnit)[0],
          },
        ],
      },
      {
        label: t('CM_0005'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'delta',
            unit: getSelectUnit(pressureUnit, classesUnit) && getSelectUnit(pressureUnit, classesUnit)[1],
            value: deltaP,
            isResultField: true,
            disabled: true,
            isSpecialFormat: true,
          },
        ],
      },
      {
        label: t('CM_0018'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 't1',
            rules: getDefaultRule(t('CM_0159'), undefined, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
          },
          {
            type: BaseFormInputType.Select,
            name: 't1Unit',
            value: TemperatureUnitsID.Celsius,
            options: TEMPERATURE_UNITS.map((item) => ({
              key: item.unit,
              value: item.value,
              label: `${t(item.label)}`,
            })),
          },
        ],
      },
      {
        label: t('CM_0019'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'gg',
            rules: getNegativeNumberRule(t('CM_0190'), undefined, undefined, undefined, true),
            autoComplete: false,
            placeholder: t('CM_0129'),
            unit: `(${t('CM_0020')})`,
          },
        ],
      },
    ],
  };

  return (
    <PageContainer>
      <>
        <div className="cv gas">
          <PageHeading borderColor="jade">{t('CM_0016')}</PageHeading>

          <BaseForm options={baseFormOpt}></BaseForm>
        </div>
        <ResultCV
          errorCV={errorCV}
          error={error}
          valueCV={valueCV}
          pressureRatio={pressureRatio}
          resDownstreamPipe={resDownstreamPipe}
          targetOfFlow={'CM_0026'}
          errorOfDownstreamPipe={errorOfDownstreamPipe}
          setErrorOfDownstreamPipe={setErrorOfDownstreamPipe}
          getResult={getResult}
          downstreamPipe={downstreamPipe}
          setResDownstreamPipe={setResDownstreamPipe}
          setErrorCV={setErrorCV}
          CVtype={'gas'}
        />
      </>
    </PageContainer>
  );
}

export default GasCvCalculation;
