/**
 * Constants are used for flow rate calculation
 *
 * Created: 2022/15/12
 * __author__: DienPD1
 * __copyright__:Copyright KITZ Inc. 2022 All Rights Reserved
 * __version__: 1.0
 */

import { CommonUnit } from '../../constants/units';
import {
  FlowRateGasUnitsID,
  FlowRateLiquidUnitsID,
  FlowRateSteamUnitsID,
  FluidStatesID,
  PressureClassesID,
  PressureUnitsID,
  TemperatureUnitsID,
} from '../../constants/unitsID';
import { ISelectOption } from '../../interfaces/selectOption';
import { ITableUnit } from '../../interfaces/tableUnit';

export const PRESSURE_CLASSES: ISelectOption[] = [
  { value: PressureClassesID.Gauge, unit: 'G', name: 'CM_0006' },
  { value: PressureClassesID.Absolute, unit: 'a', name: 'CM_0007' },
];

export const PRESSURE_UNITS: ISelectOption[] = [
  { value: PressureUnitsID.Mpa, name: 'CM_0062', unit: 'MPa' },
  { value: PressureUnitsID.kPa, name: 'CM_0063', unit: 'kPa' },
  { value: PressureUnitsID.Pa, name: 'CM_0064', unit: 'Pa' },
  { value: PressureUnitsID.bar, name: 'CM_0065', unit: 'bar' },
  { value: PressureUnitsID.kgfcm2, name: 'CM_0066', unit: 'kgf/cm<sup>2</sup>' },
  { value: PressureUnitsID.mmHgTorr, name: 'CM_0067', unit: 'mmHg or Torr' },
  { value: PressureUnitsID.mmH2OmmAq, name: 'CM_0068', unit: 'mmH<sub>2</sub>O or mmAq' },
  { value: PressureUnitsID.psilbfin2, name: 'CM_0069', unit: 'psi or lbf/in<sup>2</sup>' },
  { value: PressureUnitsID.inH2O, name: 'CM_0070', unit: 'inH<sub>2</sub>O' },
  { value: PressureUnitsID.ftH2O, name: 'CM_0071', unit: 'ftH<sub>2</sub>O' },
];

export const TEMPERATURE_UNITS: ISelectOption[] = [
  { value: TemperatureUnitsID.Celsius, name: 'CM_0142', unit: '°C' },
  { value: TemperatureUnitsID.Kevil, name: 'CM_0143', unit: 'K' },
  { value: TemperatureUnitsID.Fahrenheit, name: 'CM_0144', unit: '°F' },
  { value: TemperatureUnitsID.Rankine, name: 'CM_0145', unit: '°R' },
];

export const FLUID_STATES: ISelectOption[] = [
  { value: FluidStatesID.Saturated, name: 'CM_0029', unit: 'saturated' },
  { value: FluidStatesID.Superheated, name: 'CM_0030', unit: 'superheated' },
];

export const VOLUME_FLOW_LIQUID_TABLE_UNITS: ITableUnit[] = [
  { unit: 'm<sup>3</sup>/h', name: 'CM_0044', value: FlowRateLiquidUnitsID.m3h },
  { unit: 'm<sup>3</sup>/min', name: 'CM_0045', value: FlowRateLiquidUnitsID.m3min },
  { unit: 'L/h', name: 'CM_0046', value: FlowRateLiquidUnitsID.Lh },
  { unit: 'L/min', name: 'CM_0047', value: FlowRateLiquidUnitsID.Lmin },
  { unit: 'US gal/min', name: 'CM_0048', value: FlowRateLiquidUnitsID.Usgalmin },
];

export const MASS_FLOW_LIQUID_TABLE_UNITS: ITableUnit[] = [
  { unit: 'kg/h', name: 'CM_0049', value: FlowRateLiquidUnitsID.kgh },
  { unit: 't/h', name: 'CM_0050', value: FlowRateLiquidUnitsID.th },
  { unit: 'lb/h', name: 'CM_0051', value: FlowRateLiquidUnitsID.lbh },
];

export const VOLUME_FLOW_GAS_TABLE_UNITS: CommonUnit[] = [
  { nameCol2: 'CM_0023', unit: 'Nm<sup>3</sup>/h', name: 'CM_0054', value: FlowRateGasUnitsID.Nm3h },
  { nameCol2: 'CM_0023', unit: 'Nm<sup>3</sup>/min', name: 'CM_0055', value: FlowRateGasUnitsID.Nm3min },
  { nameCol2: 'CM_0023', unit: 'NL/h', name: 'CM_0056', value: FlowRateGasUnitsID.NLh },
  { nameCol2: 'CM_0023', unit: 'NL/min', name: 'CM_0057', value: FlowRateGasUnitsID.NLmin },
  { nameCol2: 'CM_0024', unit: 'm<sup>3</sup>/h', name: 'CM_0044', value: FlowRateGasUnitsID.m3h },
  { nameCol2: 'CM_0024', unit: 'm<sup>3</sup>/min', name: 'CM_0045', value: FlowRateGasUnitsID.m3min },
  { nameCol2: 'CM_0024', unit: 'L/h', name: 'CM_0046', value: FlowRateGasUnitsID.Lh },
  { nameCol2: 'CM_0024', unit: 'L/min', name: 'CM_0047', value: FlowRateGasUnitsID.Lmin },
  { nameCol2: 'CM_0025', unit: 'Sm<sup>3</sup>/h', name: 'CM_0058', value: FlowRateGasUnitsID.Sm3h },
  { nameCol2: 'CM_0025', unit: 'SCFH', name: 'CM_0192', value: FlowRateGasUnitsID.SCFH },
];

export const MASS_FLOW_GAS_TABLE_UNITS: ITableUnit[] = [
  { unit: 'kg/h', name: 'CM_0049', value: FlowRateGasUnitsID.kgh },
  { unit: 't/h', name: 'CM_0050', value: FlowRateGasUnitsID.th },
  { unit: 'lb/h', name: 'CM_0051', value: FlowRateGasUnitsID.lbh },
];

export const MASS_FLOW_STEAM_TABLE_UNITS: ITableUnit[] = [
  { unit: 'kg/h', name: 'CM_0049', value: FlowRateSteamUnitsID.kgh },
  { unit: 't/h', name: 'CM_0050', value: FlowRateSteamUnitsID.th },
  { unit: 'lb/h', name: 'CM_0051', value: FlowRateSteamUnitsID.lbh },
];
