/* eslint-disable @typescript-eslint/no-explicit-any */
import { NamePath, ValidateFields } from 'rc-field-form/lib/interface';
import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import { FormInstance } from 'antd';
import { errorType } from '../constants/rules';
import { EMPTY_STRING } from '../helpers/utils';

enum ERROR_TYPE {
  TYPE = 'TypeError',
}

export interface CustomError {
  inputName?: string;
  priority: number;
  msg: string;
  type?: ERROR_TYPE;
  validateType?: errorType;
}

export interface CustomFormInstance<Values = any> extends FormInstance<Values> {
  customError: [CustomError];
  getFormEmtyOrError: (fieldName: string[]) => IFormInputStatus;
  clearSelectedInputFormValue: (fieldName: string[]) => void;
}

interface IFormInputStatus {
  isOneFieldEmpty: boolean;
  isAllFieldsEmpty: boolean;
}

const handleAfterValidation = (e: any, customForm: CustomFormInstance<any>): void => {
  const err = cloneDeep(e);
  const maxIndex = err.errorFields
    .map((errField: any) => {
      const e = JSON.parse(errField.errors[0]);
      return e.index;
    })
    .sort((a: string, b: string) => Number(b) - Number(a))[0];
  customForm.customError = err.errorFields.map((errField: any) => {
    const e = JSON.parse(errField.errors[0]);
    return {
      msg: e.msg,
      priority: e.priority * (maxIndex ? maxIndex : 1) + (e.index ? e.index : 0),
      validateType: e.validateType,
      inputName: errField.name[0] ? errField.name[0] : EMPTY_STRING,
    } as CustomError;
  });
  customForm.customError.sort((err1: any, err2: any) => {
    return err2.priority - err1.priority;
  });
};

// eslint-disable-next-line max-lines-per-function
export const useCustomForm = (form: [FormInstance<any>]): [CustomFormInstance<any>] => {
  // eslint-disable-next-line max-lines-per-function
  useEffect(() => {
    form.forEach((f) => {
      const customForm = f as CustomFormInstance<any>;
      // store validateFields function of super
      const superValidateFields = f.validateFields;

      // override validateFields function
      customForm.validateFields = (nameList?: NamePath[]): Promise<ValidateFields<any>> => {
        return new Promise((resolve, reject) => {
          superValidateFields(nameList)
            .then((d) => {
              resolve(d);
            })
            .catch((e) => {
              // handle e after validation
              handleAfterValidation(e, customForm);
              reject(e);
            });
        });
      };
      customForm.getFormEmtyOrError = (fieldName: string[]): IFormInputStatus => {
        const emptyInput = fieldName.filter((item) => {
          const emptyItem = customForm.getFieldsValue()[item];
          return !emptyItem || !emptyItem.trim();
        });

        return {
          isOneFieldEmpty: emptyInput.length > 0,
          isAllFieldsEmpty: emptyInput.length === fieldName.length,
        };
      };

      customForm.clearSelectedInputFormValue = (fieldName: string[]): void => {
        fieldName.forEach((item) => customForm.setFieldValue(item, EMPTY_STRING));
      };
    });
  }, []);

  return form as [CustomFormInstance<any>];
};
