/* eslint-disable max-lines-per-function */
/**
 * Temperature Conversion Unit Page
 *
 * Created: 2022/14/12
 * __author__: DucND16
 * __copyright__:Copyright KITZ Inc. 2022 All Rights Reserved
 * __version__: 1.0
 */

import { Form } from 'antd';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '../../components/BaseForm';
import { BaseFormInputType, IBaseFormOptions } from '../../components/BaseForm/Interface';
import PageHeading from '../../components/Heading/PageHeading';
import UnitTable from '../../components/UnitTable/UnitTable';
import { STATUS_CODE } from '../../constants/statusCode';
import { ERROR_CODE } from '../../constants/units';
import { debounceFormValueChange } from '../../helpers/debounce';
import {
  convertOverLimitResponseResult,
  isResponseResultOverLimit,
  minusString,
  setThousandSeparatorForNumber,
} from '../../helpers/utils';
import i18n from '../../i18n';
import PageContainer from '../../layouts/PageContainer';
import { ITableData, TEMPERATURE_UNITS } from '../../pages/FlowUnit/constants';
import { getBeforeConvertRule } from '../../pages/FlowUnit/validateRules';
import { getTemperatureUnitConversion } from '../../services/temperature';
import '../../styles/pages/TermperatureUnit.scss';
import { isInteger } from 'lodash';

const absoluteValueLimit = 0.01;

const formInputs = {
  textInput: 'textInput',
  selectInput: 'selectInput',
  selectConvertUnit: 'selectConvertUnit',
  textConvertUnit: 'textConvertUnit',
};
// eslint-disable-next-line max-lines-per-function
/**
 * Temperature Conversion Unit UI
 *
 * Created: 2022/14/12
 *  @author DucND16
 */
export default function TemperatureUnitPage(): JSX.Element {
  const { t } = useTranslation();
  const [textInput] = useState<string>();
  const [selectInputUnit, setSelectInputUnit] = useState<string>(TEMPERATURE_UNITS[0].value);
  const [selectConvertUnit, setSelectConvertUnit] = useState<string>(TEMPERATURE_UNITS[1].value);
  const [tableValue, setTableValue] = useState<ITableData[]>(TEMPERATURE_UNITS);
  const [errorMes, setErrorMes] = useState<string>('');
  const [form] = Form.useForm();
  const maxNumberToDisplayE = Math.pow(10, 11);

  /**
   * Call api
   *
   * Created: 2022/14/12
   *  @author DucND14
   */
  const fetchTemperatureUnit = async (): Promise<void> => {
    if (!form.getFieldValue(formInputs.textInput)) {
      return;
    }
    const tableRes = await getTemperatureUnitConversion(
      form.getFieldValue(formInputs.textInput),
      form.getFieldValue(formInputs.selectInput),
    );
    if (tableRes.data.code === STATUS_CODE.SUCCESS) {
      const tableData = tableRes.data.data;
      const defaultResSult = String(tableData[`${form.getFieldValue(formInputs.selectConvertUnit)}`]);
      let resSult = defaultResSult;

      if (
        form.getFieldValue(formInputs.selectInput) !== form.getFieldValue(formInputs.selectConvertUnit) &&
        Math.abs(Number(defaultResSult)) >= absoluteValueLimit
      ) {
        resSult = displaySpecialInputDisplay(defaultResSult) || '';
      } else {
        resSult = displaySpecialInputDisplay(defaultResSult, true) || '';
      }

      form.setFieldValue(formInputs.textConvertUnit, resSult);
      const tableInitData: ITableData[] = cloneDeep(TEMPERATURE_UNITS);
      const tableConvertData: ITableData[] = tableInitData.map((item) => {
        if (item.value) {
          return { ...item, result: tableData[item.value] };
        }
        return item;
      });
      setTableValue(tableConvertData);
    } else {
      setErrorMes(`${t(tableRes.data.errors?.validate?.T?.[ERROR_CODE] + '')}`);
    }
  };

  useEffect(() => {
    if (errorMes) {
      setTableValue(TEMPERATURE_UNITS);
      form.setFieldValue(formInputs.textConvertUnit, `${t('CM_0178')}`);
    }
  }, [errorMes]);

  /**
   * Handle form values change
   *
   * Created: 2022/14/12
   *  @author DucND16
   */
  const onFormValuesChange = (): void => {
    form
      .validateFields()
      .then(() => {
        setErrorMes('');
        if (!form.getFieldValue(formInputs.textInput)) {
          setTableValue(TEMPERATURE_UNITS);
          form.setFieldValue(formInputs.textConvertUnit, '');
          return;
        }
        fetchTemperatureUnit();
      })
      .catch((err) => setErrorMes(err.errorFields[0]?.errors[0]));
  };

  const displaySpecialInputDisplay = (value: string, isSpecial?: boolean): string => {
    const valueNumberType = Number(value);
    if (!valueNumberType) {
      return value;
    }
    const isNegative = valueNumberType < 0;
    //温度単位 (変更案) B79
    if (Math.abs(valueNumberType) >= 0.01) {
      if (!isSpecial) {
        let result = '';
        if (isInteger(valueNumberType)) {
          result = Math.abs(valueNumberType).toLocaleString('en', {
            minimumFractionDigits: 2,
          });
        } else {
          result = Number(Math.round(Math.abs(valueNumberType) * 100) / 100).toLocaleString('en', {
            minimumFractionDigits: 2,
          });
        }
        return isNegative ? minusString + result : result;
      } else {
        return isResponseResultOverLimit(Number(value))
          ? convertOverLimitResponseResult(Number(value))
          : setThousandSeparatorForNumber(value);
      }
    }
    return isResponseResultOverLimit(Number(value)) ? convertOverLimitResponseResult(Number(value)) : value;
  };

  const baseFormOpt: IBaseFormOptions = {
    form: form,
    title: t('CM_0159'),
    className: i18n.language,
    onFormValuesChange: onFormValuesChange,
    fields: [
      {
        label: t('CM_0083'),
        className: '',
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: formInputs.textInput,
            value: textInput,
            placeholder: t('CM_0129'),
            rules: getBeforeConvertRule(),
            isIgnoreLimitConvert: true,
            limitMaxValue: maxNumberToDisplayE,
          },
          {
            type: BaseFormInputType.Select,
            name: formInputs.selectInput,
            value: selectInputUnit,
            onChange: setSelectInputUnit,
            options: TEMPERATURE_UNITS.map((item) => ({
              value: item.value,
              label: t(item.label),
              key: item.name,
            })),
          },
        ],
      },
      {
        label: t('CM_0084'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: formInputs.textConvertUnit,
            isResultField: true,
            className: 'input-result',
            // isIgnoreLimitConvert: true,
            // limitMaxValue: maxNumberToDisplayE,
            isSpecialFormat: true,
          },
          {
            type: BaseFormInputType.Select,
            name: formInputs.selectConvertUnit,
            value: selectConvertUnit,
            onChange: setSelectConvertUnit,
            options: TEMPERATURE_UNITS.map((item) => ({
              value: item.value,
              label: t(item.label),
              key: item.name,
            })),
          },
        ],
      },
    ],
  };

  const children = (
    <>
      <div className="temperature-unit">
        <PageHeading>{t('CM_0090')}</PageHeading>
        <BaseForm options={baseFormOpt}></BaseForm>
        <div className="result-container">
          <div className="result">
            <label className="title">{t('CM_0085')}</label>
            {errorMes && <span className="error-message">{errorMes}</span>}
          </div>
          <div className="result-table ">{<UnitTable dataTable1={tableValue} />}</div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <PageContainer>{children}</PageContainer>
    </>
  );
}
