import { Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { getNegativeNumberRule, limitMinToDisplayExpo } from '../../constants/rules';
import rounding, {
  isNumeric,
  roundingCVDeltaP,
  displayResponseData,
  EMPTY_STRING,
  roundingString,
} from '../../helpers/utils';
import './style.scss';
import { BaseForm } from '../BaseForm';
import { BaseFormInputType, IBaseFormOptions } from '../BaseForm/Interface';
import { useCustomForm } from '../../hooks/useCustomForm';
import { Dispatch, MutableRefObject, SetStateAction, useEffect } from 'react';
import { CustomInput } from '../CustomInput/CustomInput';
import { HIGH_LIGHT } from '../../constants/units';

interface ResultCVProps {
  error: string;
  errorCV: string;
  valueCV: string;
  pressureRatio: string;
  resDownstreamPipe: {
    ft_s: string;
    m_s: string;
  };
  targetOfFlow?: string;
  errorOfDownstreamPipe?: string;
  setErrorOfDownstreamPipe: Dispatch<SetStateAction<string>>;
  getResult(): Promise<void>;
  downstreamPipe: MutableRefObject<string>;
  setResDownstreamPipe: Dispatch<
    SetStateAction<{
      ft_s: string;
      m_s: string;
    }>
  >;
  setErrorCV: Dispatch<SetStateAction<string>>;
  CVtype: string;
}

// eslint-disable-next-line max-lines-per-function
function ResultCV({
  errorCV,
  error,
  valueCV,
  pressureRatio,
  resDownstreamPipe,
  targetOfFlow,
  errorOfDownstreamPipe,
  setErrorOfDownstreamPipe,
  getResult,
  downstreamPipe,
  setResDownstreamPipe,
  setErrorCV,
  CVtype,
}: ResultCVProps): JSX.Element {
  const { t } = useTranslation();
  const [inputForm] = useCustomForm(Form.useForm());

  const typeCV = {
    gas: ['CM_0196', 'CM_0040'],
    liquid: ['CM_0178', 'CM_0040'],
  };

  useEffect(() => {
    if (isNumeric(valueCV) && isNumeric(pressureRatio)) {
      if (Number(pressureRatio) >= HIGH_LIGHT) {
        setErrorCV(`${t(typeCV[CVtype as keyof typeof typeCV][0])}`);
      } else {
        setErrorCV(`${t(typeCV[CVtype as keyof typeof typeCV][1])}`);
      }
    }
  }, [valueCV, pressureRatio]);

  const onFormValuesChange = (): void => {
    inputForm
      .validateFields(['downstreamPipe'])
      .then(() => {
        setErrorOfDownstreamPipe('');
        downstreamPipe.current = inputForm.getFieldValue('downstreamPipe');
        setResDownstreamPipe({ ft_s: '', m_s: '' });

        inputForm.getFieldValue('downstreamPipe') && getResult();
      })
      .catch(() => {
        if (inputForm.customError[0]?.msg) {
          setErrorOfDownstreamPipe(inputForm.customError[0]?.msg);
        }
        downstreamPipe.current = '';
        setResDownstreamPipe({ ft_s: '', m_s: '' });
      });
  };

  const baseFormOpt: IBaseFormOptions = {
    form: inputForm,
    onFormValuesChange,
    fields: [
      {
        label: t('CM_0013'),
        inputs: [
          {
            type: BaseFormInputType.Text,
            name: 'downstreamPipe',
            rules: getNegativeNumberRule(t('CM_0191'), '', false, 1, true),
            autoComplete: false,
            unit: 'mm',
            placeholder: t('CM_0129'),
          },
        ],
      },
    ],
  };

  const displayResult = (value: string): string => {
    if (isNumeric(value) || (Number(value) === 0 && value)) {
      return rounding(Number(value)).toString();
    }
    return value;
  };

  const displayCvResult = (value: string): string => {
    if (value) {
      if (Number(value) || Number(value) == 0) {
        return displayResponseData(Number(roundingString(Number(valueCV))));
      }
      return value;
    }
    return EMPTY_STRING;
  };

  return (
    <div className="result-cv">
      <div className="mb-24">
        <Row>
          <Col>
            <h4 className="font-bold result">{t('CM_0010').toString()}</h4>
          </Col>
          <Col>
            <div className="error">{error}</div>
          </Col>
        </Row>
      </div>

      <div className="grid-container grid-container-m mt-6">
        <div className="title-cv flex items-center">{t('CM_0011')}</div>
        <div className="inner-diameter-input">
          <CustomInput
            disabled
            className="label-value"
            value={
              // t('CM_0178') === valueCV
              //   ? valueCV
              //   : Number(valueCV)
              //   ? displayResponseData(Number(roundingString(Number(valueCV))))
              //   : EMPTY_STRING
              displayCvResult(valueCV)
            }
            isResultField
            // isSpecialFormat
            bordered={false}
            isRoundingString
            limitMinValue={limitMinToDisplayExpo}
            isIgnoreLimitConvert
          />
        </div>
        <div className=" ratio justify-end">{t('CM_0012')}</div>
        <div className="inner-diameter-input inner-diameter">
          <CustomInput
            disabled
            className={`label-value ${
              isNumeric(pressureRatio) && Number(roundingCVDeltaP(Number(pressureRatio))) >= HIGH_LIGHT
                ? 'highlight-input'
                : EMPTY_STRING
            }`}
            value={
              pressureRatio
                ? Number(pressureRatio)
                  ? roundingCVDeltaP(Number(pressureRatio))
                  : pressureRatio
                : EMPTY_STRING
            }
            isResultField
            isSpecialFormat
            bordered={false}
          />
        </div>
        <div></div>
      </div>

      {errorCV && <div className="err-diameter mt-6">{errorCV}</div>}

      <Row align="middle" className="mt-6">
        <BaseForm options={baseFormOpt}></BaseForm>
      </Row>

      {errorOfDownstreamPipe && <div className="err-diameter err mt-6">{errorOfDownstreamPipe}</div>}

      <div className="grid-container grid-container-fast mt-6">
        <div className="title-cv flex items-center">{t('CM_0014')}</div>
        <div className="inner-diameter-input">
          <CustomInput
            disabled
            limitMinValue={limitMinToDisplayExpo}
            className={`label-value`}
            value={displayResult(resDownstreamPipe?.m_s)}
            isResultField
            bordered={false}
            isRoundingString
          />
        </div>
        <div className="ratio">m/s</div>
        <div className="inner-diameter-input mm-12 area">
          <CustomInput
            disabled
            limitMinValue={limitMinToDisplayExpo}
            className={`label-value`}
            value={displayResult(resDownstreamPipe?.ft_s)}
            isResultField
            bordered={false}
            isRoundingString
          />
        </div>
        <div className="ratio mm-12">ft/s</div>
      </div>

      <div className="target-of-flow">{targetOfFlow && t(targetOfFlow)}</div>
    </div>
  );
}

export default ResultCV;
