import {
  FlowRateLiquidUnitsID,
  PressureUnitsID,
  TemperatureUnitsID,
  ViscosityUnitsID,
} from './../../constants/unitsID';
/**
 * Constants are used for flow rate calculation
 *
 * Created: 2022/15/12
 * __author__: KhaiLQ7
 * __copyright__:Copyright KITZ Inc. 2022 All Rights Reserved
 * __version__: 1.0
 */

import { FlowRateGasUnitsID } from '../../constants/unitsID';
import { ISelectOption } from '../../interfaces/selectOption';
import { ITableUnit } from '../../interfaces/tableUnit';

export enum PressureUnitTable {
  Absolute = 'Absolute',
  Gauge = 'Gauge',
}
export interface IPressureUnit extends ITableUnit {
  tab?: PressureUnitTable;
}

export interface IGasFlowUnit extends ITableUnit {
  label: string;
  type?: UnitType;
  tab?: GasFlowUnitTab;
  nameCol2?: string;
}

export interface ITableData extends ITableUnit {
  label: string;
}
export enum GasFlowUnitTab {
  MASS = 'MASS',
  VOLUME = 'VOLUME',
}

export enum UnitType {
  NORMAL = 'NORMAL',
  ACTUAL = 'ACTUAL',
  STANDARD = 'STANDARD',
}

export enum LiquidFlowUnitTab {
  VOLUME_FLOW = 'volume-flow',
  MASS_FLOW = 'mass-flow-rate',
}
export interface ILiquidFlowUnit extends ITableUnit {
  type?: string;
  label: string;
  tab?: LiquidFlowUnitTab;
}

export enum ViscosityLiquidUnitTab {
  viscosity = 'VISCOSTY',
  kinematicViscosity = 'KINEMATIC_VISCOSTY',
}
export interface IViscosityLiquidUnit extends ITableUnit {
  label: string;
  tab?: ViscosityLiquidUnitTab;
}

export const PRESSURE_UNITS_TABLE: IPressureUnit[] = [
  { value: 'G01', unit: 'MPa(G)', name: 'CM_0062', tab: PressureUnitTable.Gauge },
  { value: 'G02', unit: 'kPa(G)', name: 'CM_0063', tab: PressureUnitTable.Gauge },
  { value: 'G03', unit: 'Pa(G)', name: 'CM_0064', tab: PressureUnitTable.Gauge },
  { value: 'G04', unit: 'bar(G)', name: 'CM_0065', tab: PressureUnitTable.Gauge },
  { value: 'G05', unit: 'kgf/cm<sup>2</sup>(G)', name: 'CM_0066', tab: PressureUnitTable.Gauge },
  {
    value: 'G06',

    unit: 'mmHg(G) or Torr(G)',
    name: 'CM_0067',
    tab: PressureUnitTable.Gauge,
  },
  {
    value: 'G07',

    unit: 'mmH<sub>2</sub>O(G) or mmAq(G)',
    name: 'CM_0068',
    tab: PressureUnitTable.Gauge,
  },
  {
    value: 'G08',

    unit: 'psi(G) or lbf/in<sup>2</sup>(G)',
    name: 'CM_0069',
    tab: PressureUnitTable.Gauge,
  },
  { value: 'G09', unit: 'inH<sub>2</sub>O(G)', name: 'CM_0070', tab: PressureUnitTable.Gauge },
  { value: 'G10', unit: 'ftH<sub>2</sub>O(G)', name: 'CM_0071', tab: PressureUnitTable.Gauge },

  { value: 'A01', unit: 'MPa(a)', name: 'CM_0062', tab: PressureUnitTable.Absolute },
  { value: 'A02', unit: 'kPa(a)', name: 'CM_0063', tab: PressureUnitTable.Absolute },
  { value: 'A03', unit: 'Pa(a)', name: 'CM_0064', tab: PressureUnitTable.Absolute },
  { value: 'A04', unit: 'bar(a)', name: 'CM_0065', tab: PressureUnitTable.Absolute },
  { value: 'A05', unit: 'kgf/cm<sup>2</sup>(a)', name: 'CM_0066', tab: PressureUnitTable.Absolute },
  {
    value: 'A06',

    unit: 'mmHg(a) or Torr(a)',
    name: 'CM_0067',
    tab: PressureUnitTable.Absolute,
  },
  {
    value: 'A07',

    unit: 'mmH<sub>2</sub>O(a) or mmAq(a)',
    name: 'CM_0068',
    tab: PressureUnitTable.Absolute,
  },
  {
    value: 'A08',

    unit: 'psi(a) or lbf/in<sup>2</sup>(a)',
    name: 'CM_0069',
    tab: PressureUnitTable.Absolute,
  },
  { value: 'A09', unit: 'inH<sub>2</sub>O(a)', name: 'CM_0070', tab: PressureUnitTable.Absolute },
  { value: 'A10', unit: 'ftH<sub>2</sub>O(a)', name: 'CM_0071', tab: PressureUnitTable.Absolute },
];

export const GAS_FLOW_UNIT_LIST: IGasFlowUnit[] = [
  {
    value: FlowRateGasUnitsID.Nm3h,
    label: 'CM_0146',
    unit: 'Nm<sup>3</sup>/h',
    name: 'CM_0054',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.NORMAL,
    nameCol2: 'CM_0023',
  },
  {
    value: FlowRateGasUnitsID.Nm3min,
    label: 'CM_0147',
    unit: 'Nm<sup>3</sup>/min',
    name: 'CM_0055',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.NORMAL,
    nameCol2: 'CM_0023',
  },
  {
    value: FlowRateGasUnitsID.NLh,
    label: 'CM_0148',
    unit: 'NL/h',
    name: 'CM_0056',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.NORMAL,
    nameCol2: 'CM_0023',
  },
  {
    value: FlowRateGasUnitsID.NLmin,
    label: 'CM_0149',
    unit: 'NL/min',
    name: 'CM_0057',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.NORMAL,
    nameCol2: 'CM_0023',
  },
  {
    value: FlowRateGasUnitsID.m3h,
    label: 'CM_0150',
    unit: 'm<sup>3</sup>/h',
    name: 'CM_0044',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.ACTUAL,
    nameCol2: 'CM_0024',
  },
  {
    value: FlowRateGasUnitsID.m3min,
    label: 'CM_0151',
    unit: 'm<sup>3</sup>/min',
    name: 'CM_0045',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.ACTUAL,
    nameCol2: 'CM_0024',
  },
  {
    value: FlowRateGasUnitsID.Lh,
    label: 'CM_0152',
    unit: 'L/h',
    name: 'CM_0046',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.ACTUAL,
    nameCol2: 'CM_0024',
  },
  {
    value: FlowRateGasUnitsID.Lmin,
    label: 'CM_0153',
    unit: 'L/min',
    name: 'CM_0047',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.ACTUAL,
    nameCol2: 'CM_0024',
  },

  {
    value: FlowRateGasUnitsID.Sm3h,
    label: 'CM_0154',
    unit: 'Sm<sup>3</sup>/h',
    name: 'CM_0058',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.STANDARD,
    nameCol2: 'CM_0025',
  },
  {
    value: FlowRateGasUnitsID.SCFH,
    label: 'CM_0155',
    unit: 'SCFH',
    name: 'CM_0192',
    tab: GasFlowUnitTab.VOLUME,
    type: UnitType.STANDARD,
    nameCol2: 'CM_0025',
  },
  {
    unit: 'kg/h',
    name: 'CM_0049',
    value: FlowRateGasUnitsID.kgh,
    label: 'CM_0184',
    tab: GasFlowUnitTab.MASS,
  },
  {
    unit: 't/h',
    name: 'CM_0050',
    value: FlowRateGasUnitsID.th,
    label: 'CM_0185',
    tab: GasFlowUnitTab.MASS,
  },
  {
    unit: 'lb/h',
    name: 'CM_0051',
    value: FlowRateGasUnitsID.lbh,
    label: 'CM_0186',
    tab: GasFlowUnitTab.MASS,
  },
];

export const PRESSURE_UNITS: ISelectOption[] = [
  { value: PressureUnitsID.Mpa, unit: 'MPa', name: 'CM_0062' },
  { value: PressureUnitsID.kPa, unit: 'kPa', name: 'CM_0063' },
  { value: PressureUnitsID.Pa, unit: 'Pa', name: 'CM_0064' },
  { value: PressureUnitsID.bar, unit: 'bar', name: 'CM_0065' },
  { value: PressureUnitsID.kgfcm2, unit: 'kgf/cm<sup>2</sup>', name: 'CM_0066' },
  {
    value: PressureUnitsID.mmHgTorr,

    unit: 'mmHg or Torr',
    name: 'CM_0067',
  },
  {
    value: PressureUnitsID.mmH2OmmAq,

    unit: 'mmH<sub>2</sub>O or mmAq',
    name: 'CM_0068',
  },
  {
    value: PressureUnitsID.psilbfin2,

    unit: 'psi or lbf/in<sup>2</sup>',
    name: 'CM_0069',
  },
  { value: PressureUnitsID.inH2O, unit: 'inH<sub>2</sub>O', name: 'CM_0070' },
  { value: PressureUnitsID.ftH2O, unit: 'ftH<sub>2</sub>O', name: 'CM_0071' },
];

export const PRESSURE_CLASSES: ISelectOption[] = [
  { value: 'G', unit: '(G) ゲージ圧力', name: 'CM_0006' },
  { value: 'A', unit: '(a) 絶対圧力', name: 'CM_0007' },
];

export const TEMPERATURE_UNITS: ITableData[] = [
  {
    value: TemperatureUnitsID.Celsius,
    name: 'CM_0091',
    unit: '°C',
    label: 'CM_0142',
  },
  {
    value: TemperatureUnitsID.Kevil,
    name: 'CM_0092',
    unit: 'K',
    label: 'CM_0143',
  },
  {
    value: TemperatureUnitsID.Fahrenheit,
    name: 'CM_0093',
    unit: '°F',
    label: 'CM_0144',
  },
  {
    value: TemperatureUnitsID.Rankine,
    name: 'CM_0094',
    unit: '°R',
    label: 'CM_0145',
  },
];

export const LIQUID_FLOW_UNITS_LIST: ILiquidFlowUnit[] = [
  {
    unit: 'm<sup>3</sup>/h',
    name: 'CM_0044',
    value: FlowRateLiquidUnitsID.m3h,
    label: 'CM_0150',
    tab: LiquidFlowUnitTab.VOLUME_FLOW,
    type: 'CM_0021',
  },
  {
    unit: 'm<sup>3</sup>/min',
    name: 'CM_0045',
    value: FlowRateLiquidUnitsID.m3min,
    label: 'CM_0151',
    tab: LiquidFlowUnitTab.VOLUME_FLOW,
    type: 'CM_0021',
  },
  {
    unit: 'L/h',
    name: 'CM_0046',
    value: FlowRateLiquidUnitsID.Lh,
    label: 'CM_0152',
    tab: LiquidFlowUnitTab.VOLUME_FLOW,
    type: 'CM_0021',
  },
  {
    unit: 'L/min',
    name: 'CM_0047',
    value: FlowRateLiquidUnitsID.Lmin,
    label: 'CM_0153',
    tab: LiquidFlowUnitTab.VOLUME_FLOW,
    type: 'CM_0021',
  },
  {
    unit: 'US gal/min',
    name: 'CM_0048',
    value: FlowRateLiquidUnitsID.Usgalmin,
    label: 'CM_0187',
    tab: LiquidFlowUnitTab.VOLUME_FLOW,
    type: 'CM_0021',
  },
  {
    unit: 'kg/h',
    name: 'CM_0049',
    value: FlowRateLiquidUnitsID.kgh,
    label: 'CM_0184',
    tab: LiquidFlowUnitTab.MASS_FLOW,
    type: 'CM_0022',
  },
  {
    unit: 't/h',
    name: 'CM_0050',
    value: FlowRateLiquidUnitsID.th,
    label: 'CM_0185',
    tab: LiquidFlowUnitTab.MASS_FLOW,
    type: 'CM_0022',
  },
  {
    unit: 'lb/h',
    name: 'CM_0051',
    value: FlowRateLiquidUnitsID.lbh,
    label: 'CM_0186',
    tab: LiquidFlowUnitTab.MASS_FLOW,
    type: 'CM_0022',
  },
];

export const VISCOSITY_LIQUID_UNITS: IViscosityLiquidUnit[] = [
  {
    value: ViscosityUnitsID.PasNsm2,
    label: 'CM_0107',
    unit: 'Pa・s or N・s/m<sup>2</sup>',
    name: `CM_0109`,
    tab: ViscosityLiquidUnitTab.viscosity,
  },
  {
    value: ViscosityUnitsID.mPascP,
    label: 'CM_0107',
    unit: 'mPa・s or cP',
    name: `CM_0110`,
    tab: ViscosityLiquidUnitTab.viscosity,
  },
  {
    value: ViscosityUnitsID.P,
    label: 'CM_0107',
    unit: 'P',
    name: `CM_0111`,
    tab: ViscosityLiquidUnitTab.viscosity,
  },
  {
    value: ViscosityUnitsID.kgfsm2,
    label: 'CM_0107',
    unit: 'kgf・s/m<sup>2</sup>',
    name: `CM_0112`,
    tab: ViscosityLiquidUnitTab.viscosity,
  },
  {
    value: ViscosityUnitsID.lbfsin2,
    label: 'CM_0107',
    unit: 'lbf・s/in<sup>2</sup>',
    name: `CM_0113`,
    tab: ViscosityLiquidUnitTab.viscosity,
  },
  {
    value: ViscosityUnitsID.lbfsft2,
    label: 'CM_0107',
    unit: 'lbf・s/ft<sup>2</sup>',
    name: `CM_0114`,
    tab: ViscosityLiquidUnitTab.viscosity,
  },
  {
    value: ViscosityUnitsID.m2s,
    label: 'CM_0108',
    unit: 'm<sup>2</sup>/s',
    name: `CM_0115`,
    tab: ViscosityLiquidUnitTab.kinematicViscosity,
  },
  {
    value: ViscosityUnitsID.mm2scSt,
    label: 'CM_0108',
    unit: 'mm<sup>2</sup>/s or cSt',
    name: `CM_0116`,
    tab: ViscosityLiquidUnitTab.kinematicViscosity,
  },
  {
    value: ViscosityUnitsID.St,
    label: 'CM_0108',
    unit: 'St',
    name: `CM_0117`,
    tab: ViscosityLiquidUnitTab.kinematicViscosity,
  },
  {
    value: ViscosityUnitsID.ft2s,
    label: 'CM_0108',
    unit: 'ft<sup>2</sup>/s',
    name: `CM_0118`,
    tab: ViscosityLiquidUnitTab.kinematicViscosity,
  },
];
